import PurchaseProvider from 'Event/Marketplace/PurchaseProvider'
import {ThankYouPageContext} from 'Event/Marketplace/ThankYouPageContext'
import CountriesProvider, {State} from 'Event/Marketplace/lib/CountriesProvider'
import {fillThankYouPageTemplate} from 'Event/Marketplace/lib/fill-thank-you-page-template'
import {useCountries} from 'lib/event-api/countries/use-countries'
import {useMarketplaceAccount} from 'lib/marketplace-api/accounts/use-marketplace-account'
import {useEventMarketplaceClient} from 'lib/marketplace-api/use-event-marketplace-client'
import {client} from 'lib/ui/api-client'
import {api} from 'lib/url'
import TemplateEditor from 'organization/Event/TemplateEditor'
import ThankYouPageConfigContent from 'organization/Marketplace/ThankYouPageConfig/ThankYouPageConfigContent'
import ThankYouPageConfigLoader from 'organization/Marketplace/ThankYouPageConfig/ThankYouPageConfigLoader'
import ThankYouPageTemplateUpdateProvider from 'organization/Marketplace/ThankYouPageConfig/ThankYouPageTemplateUpdateProvider'
import useAutoCreateThankYouPage from 'organization/Marketplace/ThankYouPageConfig/use-auto-create-thank-you-page'
import ConfigPageProvider from 'organization/Marketplace/config/ConfigPageProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useCallback} from 'react'
import {Redirect} from 'react-router'

export default function ThankYouPageConfig() {
  const {data: account} = useMarketplaceAccount()
  const {thankYouPage} = useAutoCreateThankYouPage()
  const {routes} = useOrganization()

  const {client: marketplaceClient} = useEventMarketplaceClient()

  const {data: countries = []} = useCountries()

  const fetchStates = useCallback((countryId: number) => {
    return client.get<State[]>(api(`/countries/${countryId}/states`))
  }, [])

  if (account === null) {
    return <Redirect to={routes.settings} />
  }

  if (!account || !thankYouPage || !marketplaceClient) {
    return <ThankYouPageConfigLoader />
  }

  return (
    <CountriesProvider countries={countries} fetchStates={fetchStates}>
      <ConfigPageProvider>
        <PurchaseProvider>
          <ThankYouPageContext.Provider
            value={{
              loginUrl: null,
            }}
          >
            <ThankYouPageTemplateUpdateProvider
              thankYouPage={thankYouPage}
              client={marketplaceClient}
            >
              {(template) => (
                <TemplateEditor template={template}>
                  {(template) => (
                    <ThankYouPageConfigContent
                      template={fillThankYouPageTemplate(template)}
                      client={marketplaceClient}
                      thankYouPage={thankYouPage}
                    />
                  )}
                </TemplateEditor>
              )}
            </ThankYouPageTemplateUpdateProvider>
          </ThankYouPageContext.Provider>
        </PurchaseProvider>
      </ConfigPageProvider>
    </CountriesProvider>
  )
}
