import {obieApi} from 'lib/obie/url'
import {useOrganizationObieClient} from 'lib/obie/use-organization-obie-client'
import {useOrganization} from 'organization/OrganizationProvider'
import {useQuery} from 'react-query'

interface UseAssistantFilesParams {
  id: number
}

export interface AssistantFile {
  id: number
  name: string
  assistant_id: number
}

export function useAssistantFiles(params: UseAssistantFilesParams) {
  const {id} = params
  const {organization} = useOrganization()
  const {client} = useOrganizationObieClient()

  return useQuery({
    queryKey: ['organizations', organization.id, 'assistants', id, 'files'],
    queryFn: () =>
      client?.get<AssistantFile[]>(
        obieApi(`/organizations/${organization.id}/assistants/${id}/files`),
      ),
  })
}
