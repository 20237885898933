import {Template} from 'Event/template'
import {createBlank, DEFAULTS, PANELS, Panels} from 'Event/template/Panels'
import {GridSpacing} from '@material-ui/core/Grid'
import {CARDS} from 'Event/template/Cards'
import {
  navButtonWithSize,
  emojisFromSidebar,
  navButtonsFromSidebar,
  baseProps,
  localization,
} from 'Event/template/converter'
import {Resource} from 'Event/template/Panels/Dashboard/Resources/ResourceList'

export function convert(template: Template): Panels {
  return {
    name: DEFAULTS.name,
    version: DEFAULTS.version,
    ...baseProps(template),
    mainNav: mainNav(template),
    menu: menu(template),
    imageWaterfall: imageWaterfall(template),
    speakers: speakers(template),
    sponsors: sponsors(template),
    emojiList: emojiList(template),
    leftPanel: leftPanel(template),
    step1Icon: step1Icon(template),
    step2Icon: step2Icon(template),
    step3Icon: step3Icon(template),
    background: background(template),
    rightPanel: rightPanel(template),
    step1Label: step1Label(template),
    step2Label: step2Label(template),
    step3Label: step3Label(template),
    accentColor: accentColor(template),
    leaderboard: leaderboard(template),
    offlinePage: offlinePage(template),
    welcomeText: welcomeText(template),
    checkInTitle: checkInTitle(template),
    resourceList: resourceList(template),
    homeMenuTitle: homeMenuTitle(template),
    menuIconColor: menuIconColor(template),
    stepIconColor: stepIconColor(template),
    ticketRibbons: ticketRibbons(template),
    stepLabelColor: stepLabelColor(template),
    setPasswordForm: setPasswordForm(template),
    checkInLeftPanel: checkInLeftPanel(template),
    checkInRightPanel: checkInRightPanel(template),
    stepInactiveColor: stepInactiveColor(template),
    mobileLogo: mobileLogo(template),
    dashboardBackground: dashboardBackground(template),
    languageSelectorButton: template.languageSelectorButton,
    localization: localization(template, createBlank()),
  }
}

function imageWaterfall(template: Template): Panels['imageWaterfall'] {
  if ('menuTitle' in template.imageWaterfall) {
    return template.imageWaterfall
  }

  return {
    ...DEFAULTS.imageWaterfall,
    ...template.imageWaterfall,
  }
}

function accentColor(template: Template): Panels['accentColor'] {
  if ('header' in template) {
    return template.header.backgroundColor
  }

  return template.accentColor
}

function menu(template: Template): Panels['menu'] {
  return {
    ...DEFAULTS.menu,
    ...template.menu,
  }
}

function mainNav(template: Template): Panels['mainNav'] {
  if (template.name === CARDS) {
    return {
      buttons: {
        ...navButtonWithSize(template.mainNav.buttons),
        ...navButtonsFromSidebar(template),
      },
    }
  }

  return {
    ...template.mainNav,
    ...navButtonsFromSidebar(template),
  }
}

function ticketRibbons(template: Template): Panels['ticketRibbons'] {
  if ('ticketRibbons' in template) {
    return template.ticketRibbons
  }

  return DEFAULTS.ticketRibbons
}

function welcomeText(template: Template): Panels['welcomeText'] {
  if ('welcomeMessage' in template) {
    return template.welcomeMessage.text ?? DEFAULTS.welcomeText
  }

  if ('hero' in template && 'body' in template.hero) {
    return template.hero.body
  }

  return DEFAULTS.welcomeText
}

function emojiList(template: Template): Panels['emojiList'] {
  if ('sidebarItems' in template) {
    return {
      emojis: emojisFromSidebar(template),
      emojiWidth: DEFAULTS.emojiList.emojiWidth,
    }
  }

  return template.emojiList
}

function leftPanel(template: Template): Panels['leftPanel'] {
  if ('sidebar' in template) {
    return {
      ...DEFAULTS.leftPanel,
      barBackgroundColor: template.sidebar.background,
      barTextColor: template.sidebar.textColor,
      menuTextColor: template.sidebar.textColor,
      backgroundColor: template.sidebar.background,
    }
  }

  if ('leftPanel' in template) {
    return {
      ...template.leftPanel,
      barBackgroundColor: DEFAULTS.leftPanel.barBackgroundColor,
      barTextColor: DEFAULTS.leftPanel.barTextColor,
    }
  }

  return DEFAULTS.leftPanel
}

function rightPanel(template: Template): Panels['rightPanel'] {
  if ('rightPanel' in template) {
    return {
      ...DEFAULTS.rightPanel,
      ...template.rightPanel,
    }
  }

  return DEFAULTS.rightPanel
}

function resourceList(template: Template): Panels['resourceList'] {
  if ('sidebarItems' in template) {
    return {
      ...DEFAULTS.resourceList,
      resources: resourcesFromSidebar(template),
    }
  }

  if ('resourceList' in template) {
    return template.resourceList
  }

  return DEFAULTS.resourceList
}

function background(template: Template): Panels['background'] {
  if ('background' in template) {
    return template.background
  }

  return DEFAULTS.background
}

function checkInRightPanel(template: Template): Panels['checkInRightPanel'] {
  if ('checkInRightPanel' in template) {
    return template.checkInRightPanel
  }

  return DEFAULTS.checkInRightPanel
}

function checkInTitle(template: Template): Panels['checkInTitle'] {
  if ('checkIn' in template) {
    return template.checkIn.title
  }

  return DEFAULTS.checkInTitle
}

function checkInLeftPanel(template: Template): Panels['checkInLeftPanel'] {
  if ('sidebar' in template) {
    return {
      ...DEFAULTS.checkInLeftPanel,
      textColor: template.sidebar.textColor,
      backgroundColor: template.sidebar.background,
    }
  }

  if ('checkInLeftPanel' in template) {
    return {
      ...DEFAULTS.checkInLeftPanel,
      ...template.checkInLeftPanel,
    }
  }

  return DEFAULTS.checkInLeftPanel
}

function stepLabelColor(template: Template): Panels['stepLabelColor'] {
  if ('checkIn' in template) {
    return template.checkIn.stepLabelColor
  }

  if ('progressBar' in template) {
    return template.progressBar.textColor
  }

  return template.stepLabelColor
}

function stepIconColor(template: Template): Panels['stepIconColor'] {
  if ('checkIn' in template) {
    return template.checkIn.stepIconColor
  }

  if ('stepIconColor' in template) {
    return template.stepIconColor
  }

  return DEFAULTS.stepIconColor
}

function stepInactiveColor(template: Template): Panels['stepInactiveColor'] {
  if ('checkIn' in template) {
    return template.checkIn.inActiveColor
  }

  if ('stepInactiveColor' in template) {
    return template.stepInactiveColor
  }

  return DEFAULTS.stepInactiveColor
}

function step1Label(template: Template): Panels['step1Label'] {
  if ('checkIn' in template) {
    return template.checkIn.step1Label
  }

  if ('progressBar' in template) {
    return template.progressBar.step1Text
  }

  return template.step1Label
}

function step1Icon(template: Template): Panels['step1Icon'] {
  if ('checkIn' in template) {
    return template.checkIn.step1Icon
  }

  if ('progressBar' in template) {
    return DEFAULTS.step1Icon
  }

  return template.step1Icon
}

function step2Label(template: Template): Panels['step2Label'] {
  if ('checkIn' in template) {
    return template.checkIn.step2Label
  }

  if ('progressBar' in template) {
    return template.progressBar.step2Text
  }

  return template.step2Label
}

function step2Icon(template: Template): Panels['step2Icon'] {
  if ('checkIn' in template) {
    return template.checkIn.step2Icon
  }

  if ('progressBar' in template) {
    return DEFAULTS.step2Icon
  }

  return template.step2Icon
}

function step3Label(template: Template): Panels['step3Label'] {
  if ('checkIn' in template) {
    return template.checkIn.step3Label
  }

  if ('progressBar' in template) {
    return template.progressBar.step3Text
  }

  return template.step3Label
}

function step3Icon(template: Template): Panels['step3Icon'] {
  if ('checkIn' in template) {
    return template.checkIn.step3Icon
  }

  if ('progressBar' in template) {
    return DEFAULTS.step3Icon
  }

  return template.step3Icon
}

function homeMenuTitle(template: Template): Panels['homeMenuTitle'] {
  if ('homeMenuTitle' in template) {
    return template.homeMenuTitle
  }

  return DEFAULTS.homeMenuTitle
}

function leaderboard(template: Template): Panels['leaderboard'] {
  return {
    ...template.leaderboard,
    title: template.leaderboard?.title ?? DEFAULTS.leaderboard.title,
    description:
      template.leaderboard?.description ?? DEFAULTS.leaderboard.description,
  }
}

function setPasswordForm(template: Template): Panels['setPasswordForm'] {
  return {
    ...DEFAULTS.setPasswordForm,
    ...template.setPasswordForm,
  }
}

function sponsors(template: Template): Panels['sponsors'] {
  return template.sponsors
}

function speakers(template: Template): Panels['speakers'] {
  const spacing =
    'spacing' in template.speakers
      ? template.speakers.spacing
      : DEFAULTS.speakers.spacing
  return {
    ...template.speakers,
    items: template.speakers?.items ?? DEFAULTS.speakers.items,
    spacing: spacing as GridSpacing,
  }
}

function offlinePage(template: Template): Panels['offlinePage'] {
  return {
    ...DEFAULTS.offlinePage,
    ...template.offlinePage,
  }
}

function mobileLogo(template: Template): Panels['mobileLogo'] {
  if ('mobileLogo' in template) {
    return template.mobileLogo
  }

  return template.logo
}

function dashboardBackground(
  template: Template,
): Panels['dashboardBackground'] {
  if ('dashboardBackground' in template) {
    return {
      ...DEFAULTS.dashboardBackground,
      ...template.dashboardBackground,
    }
  }

  if ('pageBackground' in template) {
    return {
      ...DEFAULTS.dashboardBackground,
      ...template.pageBackground,
    }
  }

  return DEFAULTS.dashboardBackground
}

function menuIconColor(template: Template): Panels['menuIconColor'] {
  if ('menu' in template && template.name !== PANELS) {
    return template.menu.iconColor
  }

  return template.menuIconColor
}

function resourcesFromSidebar(template: Template): Resource[] {
  const resources: Resource[] = []

  if ('sidebarItems' in template) {
    for (const key in template.sidebarItems) {
      const item = template.sidebarItems[key]

      if (item.type === 'Resource List') {
        for (const id in item.resources) {
          const resource = item.resources[id]
          resources.push({
            description: null,
            ...resource,
          })
        }
      }
    }
  }

  return []
}
