import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import {useQuery} from 'react-query'

export function useOrganizationObieToken() {
  const {client, organization} = useOrganization()

  return useQuery({
    queryKey: ['organizations', organization.id, 'obie', 'token'],
    queryFn: () =>
      client
        .get<{token: string}>(
          api(`/organizations/${organization.id}/obie/token`),
        )
        .then(({token}) => token),
  })
}
