import React, {useEffect, useState, useCallback} from 'react'
import {useDeleteFile} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/ResourceList/ResourceUpload'
import {Resource} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/ResourceList/ResourceItem'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  RemoveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import {ResourceListProps} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/ResourceList'
import {useAutoUpdateSidebarItem} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Settings from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/ResourceList/ResourceItemConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import {generateHashId} from 'lib/crypto/hash'
import {
  useAddTranslation,
  useLanguages,
  useRemoveTranslations,
} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {get as getAtPath} from 'lodash'
import {useTemplate} from 'Event/TemplateProvider'
import {useUserTranslations} from 'Event/LanguageProvider/translations'

export default function ResourceItemConfig(
  props: ComponentConfigProps & {
    resource: Resource
    id?: string
    list: ResourceListProps
    listId: string
  },
) {
  const {resource, id, showing, onClose, listId} = props
  const {list} = props
  const deleteFile = useDeleteFile()
  const {handleSubmit, control, watch, formState, setValue} = useForm()
  const [rules, setRules] = useState(resource.rules)
  const [isUrl, setIsUrl] = useState(resource.isUrl)
  const translate = useUserTranslations({disableDefaultFallback: true})

  const [filePath, setFilePath] = useState(translate(resource.filePath))
  const addTranslation = useAddTranslation()
  const removeTranslation = useRemoveTranslations()
  const saveTemplate = useSaveTemplate()
  const template = useTemplate()
  const languages = useLanguages()

  useEffect(() => {
    if (showing) {
      return
    }

    setRules(resource.rules)
    setIsUrl(resource.isUrl)
    setFilePath(resource.filePath)
  }, [resource, showing, translate])

  const update = (id: string, data: Resource) => {
    const name = replaceAtPath(data, 'name', `{{${id}_name}}`)
    const filePath = replaceAtPath(data, 'filePath', `{{${id}_filePath}}`)
    const url = replaceAtPath(data, 'url', `{{${id}_url}}`)

    saveTemplate({
      sidebarItems: {
        [listId]: {
          resources: {
            [id]: {
              ...data,
              filePath: `{{${id}_filePath}}`,
            },
          },
        },
      },
      localization: addTranslation({
        [`${id}_name`]: name ?? '',
        [`${id}_url`]: url ?? '',
        [`${id}_filePath`]: filePath ?? '',
      }),
    })
  }

  const insert = (newResource: Resource) => {
    const position = Object.keys(list.resources).length + 1
    generateHashId([
      'cards',
      'resource',
      Date.now().toString(),
      Math.random().toString(),
      position.toString(),
    ]).then((id) => {
      const componentId = `resource_${id}`
      saveTemplate({
        sidebarItems: {
          [listId]: {
            resources: {
              [componentId]: {
                ...newResource,
                position,
                name: `{{${componentId}_name}}`,
                filePath: `{{${componentId}_filePath}}`,
                url: `{{${componentId}_url}}`,
              },
            },
          },
        },
        localization: addTranslation({
          [`${componentId}_name`]: newResource.name ?? '',
          [`${componentId}_filePath`]: newResource.filePath ?? '',
          [`${componentId}_url`]: newResource.url ?? '',
        }),
      })
    })
  }

  const save = (
    formData: Pick<
      Resource,
      'name' | 'url' | 'isVisible' | 'icon' | 'actionId'
    >,
  ) => {
    const data: Resource = {
      rules,
      isUrl,
      filePath,
      ...formData,
    }

    if (id !== undefined) {
      update(id, data)
      onClose()
      return
    }

    insert(data)
    onClose()
  }

  useAutoUpdateSidebarItem({
    item: id
      ? {
          resources: {
            [id]: watch(),
          },
        }
      : {},
    when: showing && Boolean(id),
  })

  const remove = useCallback(() => {
    if (id === undefined) {
      throw new Error('Missing resource item index')
    }

    for (const language of languages) {
      const filePath = getAtPath(
        template,
        `localization.translations.${language.name}.${id}_filePath`,
      )

      if (filePath) {
        deleteFile(filePath).catch((e) => {
          // Log error, but prevent it from crashing
          // app
          console.error(e)
        })
      }
    }

    onClose()
    saveTemplate({
      sidebarItems: {
        [listId]: {
          resources: {
            [id]: REMOVE,
          },
        },
      },
      localization: removeTranslation([
        `${id}_name`,
        `${id}_filePath`,
        `${id}_url`,
      ]),
    })
  }, [
    id,
    onClose,
    deleteFile,
    saveTemplate,
    listId,
    languages,
    template,
    removeTranslation,
  ])

  return (
    <ComponentConfig
      title="Resource"
      onClose={onClose}
      showing={showing}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings
          control={control}
          resource={resource}
          isUrl={isUrl}
          setIsUrl={setIsUrl}
          filePath={translate(filePath)}
          setFilePath={setFilePath}
        />
      </SettingsPanel>
      <RulesPanel rules={rules} setRules={setRules}>
        <ScheduleInput
          setValue={setValue}
          control={control}
          values={resource}
        />
      </RulesPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          aria-label="remove resource"
          onClick={remove}
          showing={Boolean(id)}
        />
      </Footer>
    </ComponentConfig>
  )
}
