import {createNavButtonWithSize} from 'Event/Dashboard/components/NavButton/create'
import {NavButtonWithSize} from './../../Dashboard/components/NavButton/index'
import {Sidebar} from 'Event/template/Cards/Dashboard/Sidebar/SidebarContainer'
import {HashMap} from 'lib/list'
import {Column} from 'lib/ui/layout'
import {BackgroundPosition} from 'lib/ui/BackgroundPositionSelector'
import {useTemplate} from 'Event/TemplateProvider'
import {BaseTemplate, Details} from 'Event/template'
import {BlogPost} from 'Event/Dashboard/components/BlogPosts'
import {DeepRequired} from 'lib/type-utils'
import {SidebarItemProps} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem'
import {InputStyles} from 'Event/Question'
import {CountDownTimerSettings} from 'Event/Dashboard/components/CountDownTimer'
import {WelcomeMessage} from 'Event/template/Cards/Dashboard/WelcomeMessage/WelcomeMessageConfig'
import {Hero} from 'Event/template/Cards/Dashboard/Hero/HeroConfig'
import {CardsNavButtonProps} from 'Event/template/Cards/Dashboard/CardsNavButton'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {InputStyleProps} from 'Event/auth/TextField'
import {Font} from 'lib/FontSelect'
import {createBaseTemplate} from 'Event/template/base'
import {ENGLISH} from 'Event/LanguageProvider/language'
export {convert} from 'Event/template/Cards/converter'

/**
 * Template Name
 * --------------------------------------------------
 * Must be unique, and match the template directory
 * for dynamic imports to work.
 */
export const CARDS = 'Cards'

/**
 * Template Details (Required)
 * --------------------------------------------------
 * App-level details shared by every template instance.
 */
export const details: Details = {
  label: 'Cards',
  previewUrl:
    'https://cards-demo.obv.io/login?token=81040a04c5bfd047cc397135ca458d65',
}

export function useCardsTemplate() {
  const template = useTemplate()

  if (template.name !== CARDS) {
    throw new Error('useCards called with wrong template')
  }

  return template
}

export function useCardsUpdate() {
  return useSaveTemplate<Cards>()
}

export type CustomBackgrounds = {
  backgroundImage1: string | null
  backgroundImage2: string | null
  backgroundImage3: string | null
  backgroundImage4: string | null
  backgroundImage5: string | null
  backgroundImage1Position: BackgroundPosition
  backgroundImage2Position: BackgroundPosition
  backgroundImage3Position: BackgroundPosition
  backgroundImage4Position: BackgroundPosition
  backgroundImage5Position: BackgroundPosition
}

export type Cards = BaseTemplate &
  CustomBackgrounds & {
    name: typeof CARDS
    accentColor: string
    title: string
    homeMenuTitle: string
    mainNav: {
      buttons: HashMap<CardsNavButtonProps>
      buttonHeight?: number
      width?: number
      borderRadius?: number
      scrollDownText?: string
      scrollDownArrowColor?: string
    }
    welcomeMessage: WelcomeMessage
    hero: Hero
    sidebar: Sidebar
    sidebarItems: HashMap<SidebarItemProps>
    blogPosts: HashMap<BlogPost>
    textColor?: string
    linkColor?: string
    linkUnderline?: boolean
    points_unit: string
    background: {
      color: string
      opacity: number
    }
    contentPanel: {
      backgroundColor: string
      backgroundOpacity: number
    }
    pageLinks: {
      dividerColor: string
      textColor: string
    }
    postStyles: {
      titleTextColor?: string | null
      titleFontSize?: number
      dateTextColor?: string
      dateFontSize?: number
      contentFontSize?: number
      contentTextColor?: string | null
      spacing?: number
      titleFont: Font | null
    }
    postFormStyles?: QuestionFormStyle
    header: CardsHeader
    dashboardBackground?: {
      color: string
      opacity: number
    }
    footer: {
      image: string | null
      background: string
      textColor: string | null
      termsLink: string | null
      privacyLink: string | null
      copyrightText: string | null
      imageSize?: number
    }
    backgroundPosition: 'fixed' | 'bottom'
    login: {
      additionalDescription: {
        text: string
        color: string | null
        fontSize: number
      }
      welcome: {
        text: string
        color: string | null
        fontSize: number
        font: Font | null
      }
      passwordReset: {
        forgotPasswordTitle: string
      }
    }
    setPasswordForm?: {
      title?: string
      description?: string
      passwordLabel?: string
      confirmPasswordLabel?: string
      button: NavButtonWithSize
      input?: InputStyleProps
    }
    leaderboard?: {
      title?: string
      description?: string
      menuTitle?: string
      isVisible?: boolean
    }
    sponsors?: {
      imageSize?: Column
      spacing?: number
      hasDivider?: boolean
      menuTitle?: string
    }
    speakers?: {
      spacing?: number
      menuTitle?: string
    }
    faq?: {
      menuTitle?: string
    }
    zoomBackgrounds?: {
      menuTitle?: string
    }
    checkIn: {
      title: string
      titleColor: string
      stepLabelColor: string
      stepIconColor: string
      inActiveColor: string
      step1Label: string
      step1Icon: string
      step2Label: string
      step2Icon: string
      step3Label: string
      step3Icon: string
      contentPanel: {
        backgroundColor: string
        backgroundOpacity: number
        textColor: string | null
      }
    }
    countDownTimers?: HashMap<CountDownTimerSettings>
    imageWaterfall: BaseTemplate['imageWaterfall'] & {
      menuTitle?: string
    }
  }

export type CardsHeader = {
  backgroundColor: string
  backgroundOpacity: number
  isEnabled?: boolean
  logoSize: number
}

export type QuestionFormStyle = {
  width?: number
  position?: string
  buttonSize?: number
  buttonRadius?: number
  buttonColor?: string
  buttonBackgroundColor?: string
  buttonHoverBackgroundColor?: string
  buttonPosition?: string
  buttonFontSize?: number
  inputStyles?: InputStyles
}

/**
 * Create a Template instance with no preset data (required)
 */
export const createBlank = (): DeepRequired<Cards> => ({
  ...createBaseTemplate(),
  version: 1,
  name: CARDS,
  accentColor: '#B8FFF7',
  title: '',
  homeMenuTitle: '{{home_menu_title}}',
  mainNav: {
    buttons: {},
    buttonHeight: 170,
    width: 80,
    borderRadius: 50,
    scrollDownText: '{{scroll_down}}',
    scrollDownArrowColor: '#FFFFFF',
  },
  points_unit: '{{points_unit}}',
  background: {
    color: '#FFFFFF',
    opacity: 0,
  },
  contentPanel: {
    backgroundColor: '#FFFFFF',
    backgroundOpacity: 100,
  },
  pageLinks: {
    dividerColor: '#07BAB5',
    textColor: '#000000',
  },
  welcomeMessage: {
    text: '{{welcome_text}}',
    fontSize: 24,
    textColor: null,
    font: null,
  },
  hero: {
    text: null,
    image: null,
    imageSize: 50,
    fontSize: 17,
    textColor: null,
  },
  sidebar: {
    backgroundImage: null,
    background: '#ffffff',
    textColor: null,
    borderRadius: 5,
    borderWidth: 3,
    borderColor: '#da2727',
    paddingTop: 48,
    isVisible: true,
    separatorColor: '#FFFFFF',
    separatorStyle: 'solid',
    separatorThickness: 1,
    headBackgroundColor: '#da2727',
    headBackgroundBorder: 15,
    headTextColor: '#ffffff',
    itemSpacing: 32,
  },
  sidebarItems: {},
  textColor: '#000000',
  linkColor: '#000000',
  linkUnderline: true,
  header: {
    backgroundColor: '#FFFFFF',
    backgroundOpacity: 100,
    logoSize: 100,
    isEnabled: true,
  },
  footer: {
    image: null,
    background: '#000000',
    textColor: '#FFFFFF',
    termsLink: null,
    privacyLink: null,
    copyrightText: null,
    imageSize: 100,
  },
  backgroundPosition: 'fixed',
  login: {
    ...createBaseTemplate().login,
    additionalDescription: {
      text: '{{forget_password}}',
      color: null,
      fontSize: 18,
    },
    welcome: {
      text: '{{welcome}}',
      color: null,
      fontSize: 24,
      font: null,
    },
    passwordReset: {
      ...createBaseTemplate().login.passwordReset,
      forgotPasswordTitle: '{{password_help}}',
    },
  },
  sponsors: {
    ...createBaseTemplate().sponsors,
    imageSize: 4,
    menuTitle: '{{sponsors_menu_title}}',
    spacing: 0,
    hasDivider: true,
  },
  speakers: {
    ...createBaseTemplate().speakers,
    menuTitle: '{{speakers_menu_title}}',
    spacing: 0,
  },
  zoomBackgrounds: {
    ...createBaseTemplate().zoomBackgrounds,
    menuTitle: '{{backgrounds}}',
  },
  postStyles: {
    titleTextColor: null,
    titleFontSize: 30,
    dateTextColor: '#adadad',
    dateFontSize: 14,
    contentTextColor: null,
    contentFontSize: 17,
    spacing: 32,
    titleFont: null,
  },
  postFormStyles: {
    width: 100,
    position: 'center',
    buttonSize: 100,
    buttonRadius: 0,
    buttonColor: 'pink',
    buttonBackgroundColor: 'blue',
    buttonHoverBackgroundColor: 'blue',
    buttonPosition: 'center',
    buttonFontSize: 27,
    buttonBorderWidth: 0,
    buttonBorderColor: 'black',
    inputStyles: {
      labelColor: '#7d7a7a',
      borderColor: '#7d7a7a',
      backgroundColor: '#7d7a7a',
      backgroundOpacity: 0,
      textColor: '#7d7a7a',
      helperTextColor: '#7d7a7a',
    },
  },
  dashboardBackground: {
    color: '#FFFFFF',
    opacity: 0,
  },
  setPasswordForm: {
    title: '{{set_password_title}}',
    description: '',
    passwordLabel: '{{set_password_form_password_label}}',
    confirmPasswordLabel: '{{set_password_form_confirm_password_label}}',
    button: createNavButtonWithSize({text: 'Submit'}),
    input: {
      labelColor: '#3490DC',
      textColor: '#000000',
      borderColor: '#3490DC',
      borderWidth: 2,
      backgroundColor: '#FFFFFF',
      borderRadius: 56,
    },
  },
  leaderboard: {
    title: '{{leaderboard_title}}',
    description: '{{leaderboard_description}}',
    menuTitle: '{{leaderboard_menu_title}}',
    isVisible: false,
  },
  faq: {
    title: '{{faq_title}}',
    description: '{{faq_description}}',
    menuTitle: '{{faq_menu_title}}',
    isEnabled: false,
    items: {},
  },
  checkIn: {
    title: '{{check_in_title}}',
    titleColor: '#000000',
    stepLabelColor: '#07BAB5',
    stepIconColor: '#07BAB5',
    inActiveColor: '#C7C7C7',
    step1Label: '{{check_in_step1_label}}',
    step1Icon: 'far fa-lock',
    step2Label: '{{check_in_step2_label}}',
    step2Icon: 'far fa-clipboard',
    step3Label: '{{check_in_step3_label}}',
    step3Icon: 'far fa-desktop',
    contentPanel: {
      backgroundColor: '#C7C7C7',
      textColor: null,
      backgroundOpacity: 0,
    },
  },
  countDownTimers: {},
  imageWaterfall: {
    ...createBaseTemplate().imageWaterfall,
    menuTitle: '{{image_waterfall_menu_title}}',
  },
  backgroundImage1: null,
  backgroundImage2: null,
  backgroundImage3: null,
  backgroundImage4: null,
  backgroundImage5: null,
  backgroundImage1Position: 'center center',
  backgroundImage2Position: 'center center',
  backgroundImage3Position: 'center center',
  backgroundImage4Position: 'center center',
  backgroundImage5Position: 'center center',

  localization: {
    languages: [{name: ENGLISH, rules: []}],
    translations: {
      English: {
        home_menu_title: 'Main',
        scroll_down: 'SCROLL DOWN FOR MORE',
        points_unit: 'Points',
        welcome_text: 'WELCOME TO YOUR DASHBOARD',
        welcome: 'Welcome',
        forget_password: 'First time here or did you forget your password?',
        password_help: 'Password Help',
        sponsors_menu_title: 'Sponsors',
        speakers_menu_title: 'Our Speakers',
        backgrounds: 'Backgrounds',
        set_password_title: 'Please set a password to continue',
        set_password_form_password_label: 'Password',
        set_password_form_confirm_password_label: 'Confirm Password',
        leaderboard_title: 'Leaderboard',
        leaderboard_description:
          '<p>{{first name}}, you have earned {{leaderboard_points}} {{points_unit}}, and you are currently {{leaderboard_position}}. Great Job!</p><p><i>The list below is the top 200 point earners! If you don’t see your name listed, there’s still time!</i></p><p><br>&nbsp;</p>',
        leaderboard_menu_title: 'Points',
        faq_title: 'FAQ',
        faq_description: '',
        faq_menu_title: 'Faqs',
        check_in_title: 'Check In:',
        check_in_step1_label: 'Step 1',
        check_in_step2_label: 'Step 2',
        check_in_step3_label: 'Step 3',
        image_waterfall_menu_title: 'Image Waterfall',
      },
    },
    isAdvancedMode: false,
    defaultLanguage: ENGLISH,
    translationsEnabled: false,
  },
})

export const DEFAULTS = {...createBlank(), localization: null}

export const sampleEventURL = 'https://v1.obv.io/events/cards-demo'
