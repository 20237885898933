import styled from 'styled-components'
import React, {useState} from 'react'
import {Rule, Source} from 'Event/attendee-rules'
import {onUnknownChangeHandler} from 'lib/dom'
import Select from 'lib/ui/Select'
import FormControl from '@material-ui/core/FormControl'
import Option from 'lib/ui/Select/Option'
import {Label} from 'lib/ui/typography'
import SourceConfig from 'Event/attendee-rules/RuleConfig/RuleList/RuleForm/SourceConfig'
import Button from 'lib/ui/Button'
import Visible from 'lib/ui/layout/Visible'
import {Form} from 'organization/Event/FormsProvider'
import {useGuestVariables} from 'Event'

const ALL_SOURCES: Source[] = [
  'Tags',
  'Group',
  'Nested Rule',
  'Form Response',
  'Time',
]

export default function RuleForm(props: {
  className?: string
  close: () => void
  onCreate: (rule: Rule) => void
  rule: Rule | null
  onDelete: () => void
  description?: string
  sources?: Source[]
  form?: Form
}) {
  const initialSource = props.rule ? props.rule.source : null
  const initialRule = props.rule || null
  const {sources = ALL_SOURCES} = props
  const [source, setSource] = useState<null | Rule['source']>(initialSource)
  const [rule, setRule] = useState<null | Rule>(initialRule)
  const [controlsVisible, setControlsVisible] = useState(true)
  const toggleControlVisibility = () => setControlsVisible(!controlsVisible)
  const showDelete = Boolean(props.rule)
  const v = useGuestVariables()

  const save = () => {
    if (!rule) {
      return
    }

    props.onCreate(rule)
  }

  return (
    <div className={props.className}>
      <Visible when={controlsVisible}>
        <>
          <DeleteRuleButton visible={showDelete} onClick={props.onDelete} />
          <StyledLabel>
            {props.description ? props.description : 'Show component when'}
          </StyledLabel>
          <FormControl fullWidth>
            <Select
              value={source || ''}
              fullWidth
              onChange={onUnknownChangeHandler(setSource)}
              aria-label="pick rule source"
            >
              {Object.values(sources).map((source, index) => (
                <Option key={index} value={source}>
                  {v(source)}
                </Option>
              ))}
            </Select>
          </FormControl>
        </>
      </Visible>
      <SourceConfig
        source={source}
        onSet={setRule}
        rule={rule}
        onToggleNestedRule={toggleControlVisibility}
        form={props.form}
      />
      <Visible when={controlsVisible}>
        <Row>
          <StyledButton
            onClick={props.close}
            fullWidth
            variant="outlined"
            color="default"
          >
            Cancel
          </StyledButton>
          <StyledButton
            color="primary"
            variant="contained"
            fullWidth
            disabled={!rule}
            onClick={save}
            aria-label="save rule"
          >
            Save
          </StyledButton>
        </Row>
      </Visible>
    </div>
  )
}

function DeleteRuleButton(props: {visible: boolean; onClick: () => void}) {
  if (!props.visible) {
    return null
  }

  return (
    <StyledDeleteButton
      onClick={props.onClick}
      variant="outlined"
      color="danger"
      fullWidth
      aria-label="remove rule"
    >
      Delete
    </StyledDeleteButton>
  )
}

const StyledDeleteButton = styled(Button)`
  margin-bottom: ${(props) => props.theme.spacing[3]}!important;
`

const StyledLabel = styled(Label)`
  margin-bottom: ${(props) => props.theme.spacing[4]}!important;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${(props) => props.theme.spacing[4]}!important;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: block;
  }
`

const StyledButton = styled(Button)`
  margin: ${(props) => props.theme.spacing[2]}!important;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: ${(props) => `${props.theme.spacing[1]} 0`}!important;
  }
`
