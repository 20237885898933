import {onChangeStringHandler} from 'lib/dom'
import React, {useRef, useEffect} from 'react'
import styled from 'styled-components'

interface MessageInputProps {
  value: string
  onChange: (value: string) => void
  onSubmit: () => void
}

export default function MessageInput(props: MessageInputProps) {
  const {value, onChange, onSubmit} = props
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  useAutoResizeTextArea(textAreaRef)

  return (
    <ChatTextAreaContainer>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <ChatTextArea
          ref={textAreaRef}
          placeholder="Type message here..."
          rows={1}
          value={value}
          onChange={onChangeStringHandler(onChange)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (e.metaKey) {
                // CMD+Enter - Insert new line
                const cursorPosition = e.currentTarget.selectionStart
                const newValue =
                  value.slice(0, cursorPosition) +
                  '\n' +
                  value.slice(cursorPosition)
                onChange(newValue)
                // Prevent default to handle the new line manually
                e.preventDefault()
              } else if (!e.shiftKey) {
                // Enter without any modifier - Submit
                e.preventDefault()
                onSubmit()
                onChange('')
              }
            }
          }}
        />
      </form>
    </ChatTextAreaContainer>
  )
}

const useAutoResizeTextArea = (
  textAreaRef: React.RefObject<HTMLTextAreaElement>,
) => {
  useEffect(() => {
    const textArea = textAreaRef.current
    if (!textArea) return

    const adjustHeight = () => {
      textArea.style.height = 'auto'
      textArea.style.height = `${Math.min(textArea.scrollHeight + 1, 200)}px` // 200px is the max height
    }

    textArea.addEventListener('input', adjustHeight)
    adjustHeight() // Initial adjustment

    return () => textArea.removeEventListener('input', adjustHeight)
  }, [textAreaRef])
}

const ChatTextAreaContainer = styled.div`
  width: 100%;
  margin: 20px auto 40px;
  background: #f6f9fb;
  border-radius: 16px;
  max-height: 200px;
  box-sizing: content-box;
  max-width: 760px;
  padding: 14px 20px;
`

const ChatTextArea = styled.textarea`
  background: #f6f9fb;
  width: 100%;
  transition: height 0.1s ease-out;
  overflow-y: auto;
  outline: none;
  border: none;
  resize: none;
`
