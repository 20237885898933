import React from 'react'
import {orderedIdsByPosition} from 'Event/Marketplace/lib/list'
import {
  ThankYouPage,
  ThankYouPageTemplate,
} from 'Event/Marketplace/thank-you-page'
import PreviewButton from 'organization/Marketplace/ThankYouPageConfig/PreviewButton'
import {useUpdateTemplate} from 'organization/Marketplace/ThankYouPageConfig/ThankYouPageTemplateUpdateProvider'
import ThankYouPageButtonConfig from 'organization/Marketplace/config/BlockConfig/ThankYouPageButtonConfig'
import ConfigPage from 'organization/Marketplace/config/ConfigPage'
import ThankYouPageTemplateSelector from 'organization/Marketplace/config/TemplateSelector/ThankYouPageTemplateSelector'
import {Client} from 'lib/ui/api-client'
import {useUpdateThankYouPagePage} from 'lib/marketplace-api/thank-you-page/use-update-thank-you-page'

interface ContentProps {
  thankYouPage: ThankYouPage
  template: ThankYouPageTemplate
  client: Client
}

export default function ThankYouPageConfigContent(props: ContentProps) {
  const {template, client, thankYouPage} = props
  const updateTemplate = useUpdateTemplate()

  const {sections} = template

  const sectionIds = orderedIdsByPosition(sections)
  const hasSections = sectionIds.length > 0

  const updateThankYouPage = useUpdateThankYouPagePage({client, thankYouPage})

  const setEnabled = (enabled: boolean) => {
    updateThankYouPage.mutateAsync({is_enabled: enabled})
  }

  if (!hasSections) {
    return <ThankYouPageTemplateSelector updateTemplate={updateTemplate} />
  }

  return (
    <ConfigPage
      template={template}
      updateTemplate={updateTemplate}
      availableBlocks={[
        'Title',
        'Text',
        'Image',
        'Video',
        'Button',
        'BulletedList',
        'NumberedList',
        'FaqList',
        'CountdownTimer',
        'Icon',
        'Separator',
        'Grid',
        'Spacer',
      ]}
      rightToolbarItems={
        <>
          <PreviewButton />
        </>
      }
      enabled={thankYouPage.is_enabled}
      setEnabled={setEnabled}
      disableEnableToggle={updateThankYouPage.isLoading}
      ButtonConfig={ThankYouPageButtonConfig}
    />
  )
}
