import {obieApi} from 'lib/obie/url'
import {useOrganizationObieClient} from 'lib/obie/use-organization-obie-client'
import {useOrganization} from 'organization/OrganizationProvider'
import {useMutation, useQueryClient} from 'react-query'

export function useDeleteAssistant() {
  const {client} = useOrganizationObieClient()
  const qc = useQueryClient()

  const {organization} = useOrganization()

  return useMutation({
    mutationFn: (data: {id: number}) => {
      const {id} = data
      return client!.delete(
        obieApi(`/organizations/${organization.id}/assistants/${id}`),
      )
    },
    onSuccess: () => {
      qc.invalidateQueries(['organizations', organization.id, 'assistants'])
    },
  })
}
