import {ObvioEvent} from 'Event'
import {useEvent} from 'Event/EventProvider'
import {
  Language,
  ENGLISH,
  createLanguage,
} from 'Event/LanguageProvider/language'
import React, {useCallback, useEffect, useState} from 'react'
import {hasMatch} from 'Event/attendee-rules/matcher'
import {useAttendeeProfile} from 'Event/attendee-rules/AttendeeProfileProvider'
import {useSubmissions} from 'Event/SubmissionsProvider'
import {getLocalStorageItem} from 'lib/localStorage'
import {useTemplate} from 'Event/TemplateProvider'
import {REMOVE} from 'lib/JsonUpdateProvider'

interface LanguageContextProps {
  current: Language['name'] | null
  loading: boolean
  languages: Language[]
  set: (value: Language) => void
  translationsEnabled: boolean
  isAdvancedMode: boolean
  defaultLanguage: Language['name']
}

export const LanguageContext = React.createContext<
  LanguageContextProps | undefined
>(undefined)

export default function EventLanguageProvider(props: {
  children: React.ReactElement
}) {
  const [current, setCurrent] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)
  const template = useTemplate()
  const languages = useLanguages()
  const tokenKey = useLanguageTokenKey()
  const saved = getLocalStorageItem(tokenKey)

  const translationsEnabled =
    template.localization?.translationsEnabled || false
  const isAdvancedMode = template.localization?.isAdvancedMode || false

  const defaultLanguage = template.localization?.defaultLanguage || ENGLISH

  /**
   * Load a saved language
   */

  useEffect(() => {
    if (saved) {
      setCurrent(saved)
    }

    setLoading(false)
  }, [saved])

  const set = useCallback(
    (language: Language) => {
      window.localStorage.setItem(tokenKey, language.name)
      setCurrent(language.name)
    },
    [tokenKey],
  )

  return (
    <LanguageContext.Provider
      value={{
        current,
        loading,
        set,
        languages,
        translationsEnabled,
        isAdvancedMode,
        defaultLanguage,
      }}
    >
      {props.children}
    </LanguageContext.Provider>
  )
}

export function AttendeeLanguageProvider(props: {
  children: React.ReactElement
}) {
  return (
    <EventLanguageProvider>
      <PreSelectAttendeeLanguage>{props.children}</PreSelectAttendeeLanguage>
    </EventLanguageProvider>
  )
}

/**
 * Matches an Attendee's language using their groups/tags.
 *
 * @param props
 * @returns
 */
function PreSelectAttendeeLanguage(props: {children: JSX.Element}) {
  const {set} = useLanguage()
  const {groups, tags} = useAttendeeProfile()
  const {answers} = useSubmissions()

  const {event} = useEvent()
  const {forms} = event
  const languages = useLanguages()
  const tokenKey = useLanguageTokenKey()
  const saved = getLocalStorageItem(tokenKey)

  /**
   * Match a language based on rules. If no language has been saved (selected), then
   * we'll check to see if the attendee matches any Language rules. If they
   * do we'll select that language as a default.
   */
  useEffect(() => {
    if (saved) {
      return
    }

    const matchedLanguage = languages.find((l) =>
      hasMatch({groups, tags, answers, forms}, l.rules),
    )
    if (!matchedLanguage) {
      return
    }

    set(matchedLanguage)
  }, [groups, tags, languages, set, saved, answers, forms])

  return props.children
}

export function OrganizationLanguageProvider(props: {
  children: React.ReactElement
}) {
  const template = useTemplate()
  const [current, setCurrent] = useState<string>(
    template.localization?.defaultLanguage || ENGLISH,
  )

  const languages = useLanguages()
  const translationsEnabled =
    template.localization?.translationsEnabled || false
  const isAdvancedMode = template.localization?.isAdvancedMode || false
  const defaultLanguage = template.localization?.defaultLanguage || ENGLISH

  return (
    <LanguageContext.Provider
      value={{
        current,
        set: (language) => {
          setCurrent(language.name)
        },
        loading: false,
        languages,
        translationsEnabled,
        isAdvancedMode,
        defaultLanguage,
      }}
    >
      {props.children}
    </LanguageContext.Provider>
  )
}

/**
 * Language token key used to store the attendee's preferred
 * language in local storage.
 */

function useLanguageTokenKey() {
  const {event} = useEvent()
  return languageTokenKey(event)
}

export function languageTokenKey(event: ObvioEvent) {
  return `__obvio__${event.id}__language__`
}

export function useLanguage() {
  const context = React.useContext(LanguageContext)
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider')
  }

  return context
}

export function useLanguages() {
  const template = useTemplate()

  const definedLanguages = template.localization?.languages
  const defaultLanguages = [createLanguage(ENGLISH)]

  if (!definedLanguages) {
    return defaultLanguages
  }

  const isEmpty = definedLanguages.length === 0
  if (isEmpty) {
    return defaultLanguages
  }

  return definedLanguages
}

export function useTranslationLanguage() {
  const language = useLanguage()

  return (
    language.current ||
    language.defaultLanguage ||
    language.languages[0].name ||
    ENGLISH
  )
}

export function useAddTranslation() {
  const targetLanguage = useTranslationLanguage()

  return (translations: Record<string, string>) => ({
    translations: {
      [targetLanguage]: translations,
    },
  })
}

export function useRemoveTranslations() {
  const languages = useLanguages()

  return useCallback(
    (keys: string[]) => {
      const translations: Record<string, Record<string, string>> = {}

      for (const language of languages) {
        for (const key of keys) {
          translations[language.name] = {
            ...(translations[language.name] ?? {}),
            [key]: REMOVE,
          }
        }
      }

      return {
        translations,
      }
    },
    [languages],
  )
}

interface UseRemoveTranslationKeysParams {
  targetLanguage?: string
}

/**
 * Removes all translations that start with the given prefix
 * @param prefix
 */
export function useRemoveTranslationKeys(
  params: UseRemoveTranslationKeysParams = {},
) {
  const {targetLanguage} = params
  const allLanguages = useLanguages()
  const template = useTemplate()

  const languages = targetLanguage
    ? allLanguages.filter((language) => language.name === targetLanguage)
    : allLanguages

  return (prefix: string) => {
    const translations = template.localization?.translations

    if (!translations) {
      return
    }

    const keysToRemove: Record<string, Record<string, string>> = {}

    for (const language of languages) {
      const translationsForLanguage = translations[language.name] ?? {}

      const languageKeys: Record<string, string> = {}

      for (const key of Object.keys(translationsForLanguage)) {
        if (key.startsWith(prefix)) {
          languageKeys[key] = REMOVE
        }

        keysToRemove[language.name] = languageKeys
      }
    }

    const hasKeys = Object.values(keysToRemove).some(
      (language) => Object.keys(language).length > 0,
    )
    if (!hasKeys) {
      return null
    }

    return {
      translations: keysToRemove,
    }
  }
}
