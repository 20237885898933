import React from 'react'
import {Controller} from 'react-hook-form'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import TextField from 'lib/ui/TextField'
import FontSelect from 'lib/FontSelect'
import {Label} from 'lib/ui/typography'
import Slider from 'lib/ui/Slider'
import ColorPicker from 'lib/ui/ColorPicker'
import {useUpdateTemplate} from 'organization/Event/Certificates/CertificateEditor'
import {REMOVE} from 'lib/JsonUpdateProvider'
import {TextBlock} from 'lib/event-api/certificates/types'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Typography from '@material-ui/core/Typography'

export default function TextBlockConfig(props: TextBlock) {
  const {id, text, font, color, fontSize} = props

  const updateTemplate = useUpdateTemplate()

  const {
    form: {control, register},
  } = useConfig()

  const handleRemove = () => {
    updateTemplate({
      blocks: {
        [id]: REMOVE,
      },
    })
  }

  const save = (data: any) => {
    updateTemplate(data)
  }

  return (
    <Config title="Text" onSave={save} onRemove={handleRemove}>
      <SettingsPanel>
        <TextField
          name={`blocks.${id}.text`}
          defaultValue={text || ''}
          label="Text"
          inputProps={{
            ref: register,
            'aria-label': 'additional text',
          }}
        />
        <Alert severity="info">
          <AlertTitle>Variables</AlertTitle>
          <div>
            <Typography variant="caption">
              {`{{first_name}} - Attendee’s first name`}
            </Typography>
          </div>
          <div>
            <Typography variant="caption">
              {`{{last_name}} - Attendee’s last name`}
            </Typography>
          </div>
          <div>
            <Typography variant="caption">
              {`{{email}} - Attendee’s email`}
            </Typography>
          </div>
          <div>
            <Typography variant="caption">
              {`{{phone_number}} - Attendee’s phone number`}
            </Typography>
          </div>
          <div>
            <Typography variant="caption">
              {`You can also use groups as variables to insert an attendee’s group value on a certificate.`}
            </Typography>
          </div>
        </Alert>
      </SettingsPanel>
      <StylingPanel>
        <Label>Font</Label>
        <Controller
          control={control}
          name={`blocks.${id}.font`}
          defaultValue={font}
          render={({value, onChange}) => (
            <FontSelect value={value} onChange={onChange} />
          )}
        />
        <Controller
          name={`blocks.${id}.fontSize`}
          defaultValue={fontSize}
          control={control}
          render={({value, onChange}) => (
            <Slider
              min={1}
              max={100}
              onChange={onChange}
              valueLabelDisplay="off"
              value={value}
              label="Font Size"
            />
          )}
        />
        <Controller
          name={`blocks.${id}.color`}
          control={control}
          defaultValue={color}
          render={({value, onChange}) => (
            <ColorPicker label="Color" color={value} onPick={onChange} />
          )}
        />
      </StylingPanel>
    </Config>
  )
}
