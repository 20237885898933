import {GridSpacing} from '@material-ui/core/Grid'
import {EmojiList} from 'Event/template/Panels/Dashboard/EmojiList'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {ResourceListSettings} from 'Event/template/Panels/Dashboard/Resources/ResourceList'
import {useTemplate} from 'Event/TemplateProvider'
import {BaseTemplate, Details} from 'Event/template'
import {HashMap} from 'lib/list'
import {Column} from 'lib/ui/layout'
import {DeepRequired} from 'lib/type-utils'
import {Font} from 'lib/FontSelect'
import {TicketRibbon} from 'Event/template/Panels/Dashboard/TicketRibbonList/Ribbon'
import {CountDownTimerSettings} from 'Event/Dashboard/components/CountDownTimer'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {InputStyleProps} from 'Event/auth/TextField'
import {createBaseTemplate} from 'Event/template/base'
import {createNavButtonWithSize} from 'Event/Dashboard/components/NavButton/create'
import {AgendaListSettings} from 'Event/template/Panels/Dashboard/AgendaList'
import {ENGLISH} from 'Event/LanguageProvider/language'
export {convert} from 'Event/template/Panels/converter'

/**
 * Template Name (Required)
 * --------------------------------------------------
 * Must be unique, and match the template directory
 * for dynamic imports to work.
 */
export const PANELS = 'Panels'

/**
 * Template Details (Required)
 * --------------------------------------------------
 * App-level details shared by every template instance.
 */
export const details: Details = {
  label: 'Panels',
  previewUrl:
    'https://panels-demo.obv.io/login?token=c6548b507524b44770ff55e7345c8153',
}

export function usePanelsTemplate() {
  const template = useTemplate()

  if (template.name !== PANELS) {
    throw new Error('usePanels called with wrong template')
  }

  return template
}

export function usePanelsUpdate() {
  return useSaveTemplate<Panels>()
}

/**
 * Whether the template has other tabs/sections to navigate to. Useful
 * to check for cases where we want to hide something when there is
 * only one tab. ie. home button.
 *
 * @returns
 */
export function useHasMultipleTabs() {
  const {
    resourceList: {isVisible: showingResources},
    leaderboard: {isVisible: showingPoints},
    speakers: {isEnabled: showingSpeakers},
    sponsors: {isEnabled: showingSponsors},
    imageWaterfall: {isVisible: showingImageWaterfall},
    faq: {isEnabled: showingFaqs},
    agendaList: {isVisible: showingAgenda},
  } = usePanelsTemplate()

  return (
    showingSpeakers ||
    showingSponsors ||
    showingResources ||
    showingPoints ||
    showingImageWaterfall ||
    showingFaqs ||
    showingAgenda
  )
}

export type Panels = BaseTemplate & {
  name: typeof PANELS
  accentColor: string
  menu: BaseTemplate['menu'] & {
    height: number
  }
  backgroundPosition?: 'fixed' | 'bottom'
  mainNav: {
    buttons: HashMap<NavButtonWithSize>
  }
  ticketRibbons: TicketRibbon[]
  welcomeText: string
  welcomeTextFont?: Font | null
  welcomeTextFontSize?: number
  welcomeTextColor?: string | null
  homeMenuTitle?: string
  emojiList: EmojiList
  leftPanel: {
    barBackgroundColor: string
    barTextColor: string | null
    menuTextColor?: string | null
    arrowColor?: string | null
    backgroundColor: string
    backgroundOpacity: number
  }
  rightPanel: {
    barBackgroundColor: string
    barTextColor: string
    tabUnderlineColor: string
    textColor: string | null
    backgroundColor: string
    backgroundOpacity: number
  }
  resourceList: ResourceListSettings
  agendaList?: AgendaListSettings
  menuIconColor: string
  imageWaterfall: BaseTemplate['imageWaterfall'] & {
    menuTitle?: string
  }
  background?: {
    color?: string
    opacity?: number
  }
  checkInRightPanel: {
    backgroundColor: string
    backgroundOpacity: number
    textColor: string | null
  }
  checkInTitle: string
  checkInLeftPanel: {
    backgroundColor: string
    backgroundOpacity: number
    textColor: string | null
    inactiveTextColor: string
  }
  stepLabelColor: string
  stepIconColor: string
  stepInactiveColor: string
  step1Label: string
  step1Icon?: string
  step2Label: string
  step2Icon?: string
  step3Label: string
  step3Icon?: string
  textColor?: string
  linkColor?: string
  linkUnderline?: boolean
  leaderboard: {
    title: string
    description: string
    menuTitle?: string
    isVisible?: boolean
  }
  faq?: {
    menuTitle?: string
  }
  setPasswordForm: {
    title: string
    description?: string
    passwordLabel?: string
    confirmPasswordLabel?: string
    button?: NavButtonWithSize
    input?: InputStyleProps
  }
  sponsors?: {
    perRow?: number
    menuTitle?: string
    cardBackgroundColor?: string
    cardBackgroundOpacity?: number
  }
  speakers?: {
    imageSize?: Column
    spacing?: GridSpacing
    menuTitle?: string
  }
  countDownTimers?: HashMap<CountDownTimerSettings>
  mobileLogo: string | null
  dashboardBackground: {
    image: string | null
  }
}

/**
 * Create a Template instance with no preset data (required)
 */
export const createBlank = (): DeepRequired<Panels> => ({
  ...createBaseTemplate(),
  version: 1,
  name: PANELS,
  accentColor: '#B8FFF7',
  menu: {
    backgroundColor: '#54CFD6',
    iconColor: '#FFFFFF',
    textColor: '#FFFFFF',
    height: 65,
  },
  backgroundPosition: 'fixed',
  mainNav: {
    buttons: {},
  },
  ticketRibbons: [],
  welcomeText: '{{dashboard_home_welcome_text}}',
  welcomeTextFont: null,
  welcomeTextFontSize: 50,
  welcomeTextColor: null,
  homeMenuTitle: '{{dashboard_home_home_menu_title}}',
  emojiList: {
    emojis: [],
    emojiWidth: null,
    rules: [],
    showingFrom: null,
    showingUntil: null,
  },
  imageWaterfall: {
    ...createBaseTemplate().imageWaterfall,
    title: '{{image_waterfall_title}}',
    menuTitle: '{{image_waterfall_menu_title}}',
    description: '{{image_waterfall_description}}',
    uploadFormTitle: '{{image_waterfall_update_form_title}}',
    uploadButtonText: '{{image_waterfall_update_button_text}}',
    uploadFormDescription: '{{image_waterfall_update_form_description}}',
  },
  leftPanel: {
    barBackgroundColor: '#07BAB5',
    barTextColor: '#ffffff',
    menuTextColor: '#000000',
    arrowColor: '#000000',
    backgroundColor: '#FFFFFF',
    backgroundOpacity: 0,
  },
  rightPanel: {
    barBackgroundColor: '#07BAB5',
    barTextColor: '#ffffff',
    tabUnderlineColor: '#B8FFF7',
    textColor: null,
    backgroundColor: '#FFFFFF',
    backgroundOpacity: 0,
  },
  resourceList: {
    title: '{{resource_list_title}}',
    resources: [],
    menuTitle: '{{resource_list_menu_title}}',
    isVisible: true,
    cardBackgroundColor: '#FFFFFF',
    cardBackgroundOpacity: 100,
    color: null,
    linkColor: 'blue',
  },
  menuIconColor: '#000000',
  background: {
    color: '#FFFFFF',
    opacity: 0,
  },
  checkInRightPanel: {
    backgroundColor: '#07BAB5',
    backgroundOpacity: 100,
    textColor: null,
  },
  checkInTitle: '{{check_in_title}}',
  checkInLeftPanel: {
    backgroundColor: '#FFFFFF',
    backgroundOpacity: 0,
    textColor: '#000000',
    inactiveTextColor: '#C7C7C7',
  },
  stepLabelColor: '#07BAB5',
  stepIconColor: '#07BAB5',
  stepInactiveColor: '#C7C7C7',
  step1Label: 'Step 1',
  step1Icon: 'far fa-lock',
  step2Label: 'Step 2',
  step2Icon: 'far fa-clipboard',
  step3Label: 'Step 3',
  step3Icon: 'far fa-desktop',
  textColor: '#000000',
  linkColor: '#000000',
  linkUnderline: true,
  leaderboard: {
    title: '{{leaderboard_title}}',
    description: '{{leaderboard_description}}',
    menuTitle: '{{leaderboard_menu_title}}',
    isVisible: true,
  },
  faq: {
    title: '{{faq_title}}',
    description: '{{faq_description}}',
    menuTitle: '{{faq_menu_title}}',
    isEnabled: true,
    items: {},
  },
  setPasswordForm: {
    title: '{{set_password_title}}',
    description: '{{set_password_form_description}}',
    passwordLabel: '{{set_password_form_password_label}}',
    confirmPasswordLabel: '{{set_password_form_confirm_password_label}}',
    button: createNavButtonWithSize({text: 'Submit'}),
    input: {
      labelColor: '#3490DC',
      textColor: '#000000',
      borderColor: '#3490DC',
      borderWidth: 2,
      backgroundColor: '#FFFFFF',
      borderRadius: 56,
    },
  },
  sponsors: {
    ...createBaseTemplate().sponsors,
    perRow: 2,
    menuTitle: 'Sponsors',
    cardBackgroundColor: '#565656',
    cardBackgroundOpacity: 100,
  },
  speakers: {
    ...createBaseTemplate().speakers,
    spacing: 0,
    menuTitle: '{{speakers_menu_title}}',
  },
  countDownTimers: {},
  mobileLogo: null,
  dashboardBackground: {
    image: null,
  },
  agendaList: {
    title: '{{agenda_list_page_title}}',
    menuTitle: '{{agenda_list_menu_title}}',
    items: {},
    isVisible: false,
    footer: '',
    footerFontStyles: [],
    description: '{{agenda_list_description}}',
    descriptionFontStyles: [],
    color: '',
    font: null,
    separator: {
      color: '#000000',
    },
  },
  points_unit: '{{points_unit_text}}',
  localization: {
    languages: [{name: ENGLISH, rules: []}],
    translations: {
      English: {
        dashboard_home_welcome_text: 'WELCOME!',
        dashboard_home_home_menu_title: 'Home',
        image_waterfall_title: 'Image Waterfall',
        image_waterfall_menu_title: 'Image Waterfall',
        image_waterfall_description: '',
        uploadFormTitle: '',
        uploadButtonText: 'Upload',
        uploadFormDescription: '',
        resource_list_title: 'Resources',
        resource_list_menu_title: 'Resources',
        leaderboard_title: 'Leaderboard',
        leaderboard_menu_title: 'Points',
        leaderboard_description:
          '<p>{{first name}}, you have earned {{leaderboard_points}} {{points_unit}}, and you are currently {{leaderboard_position}}. Great Job!</p><p><i>The list below is the top 200 point earners! If you don’t see your name listed, there’s still time!</i></p><p><br>&nbsp;</p>',
        faq_title: 'FAQ',
        faq_description: '',
        faq_menu_title: 'Faqs',
        set_password_title: "You're almost ready",
        set_password_form_description:
          "First, you'll need to create a password below:",
        set_password_form_password_label: 'Enter your password',
        set_password_form_confirm_password_label: 'Re-Enter Your Password',
        speakers_menu_title: 'Speakers',
        agenda_list_page_title: 'Agendas',
        agenda_list_menu_title: 'Agendas',
        agenda_list_description: '',
        check_in_title: '',
        points_unit_text: 'Points',
      },
    },
    isAdvancedMode: false,
    defaultLanguage: ENGLISH,
    translationsEnabled: false,
  },
})

export const DEFAULTS = {...createBlank(), localization: null}

export const sampleEventURL = 'https://v1.obv.io/events/panels-demo'
