import React, {useState, useCallback} from 'react'
import {api} from 'lib/url'
import CircularProgress from '@material-ui/core/CircularProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import {spacing} from 'lib/ui/theme'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import {useEvent} from 'Event/EventProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import UploadedResource, {
  RESOURCE_FILE_TYPES,
} from 'Event/Dashboard/components/resource/UploadedResource'
import UploadDropzone from 'lib/ui/form/UploadDropzone'

interface ResourceUploadData {
  file: string
}

export default function ResourceUpload(props: {
  filePath: string
  isUrl?: boolean
  onChange: (filePath: string) => void
}) {
  const {filePath, onChange, isUrl} = props
  const {client} = useOrganization()
  const {event} = useEvent()
  const [isUploading, setIsUploading] = useState(false)
  const [error, setError] = useState(null)
  const deleteFile = useDeleteFile()

  const hasExistingFile = Boolean(filePath)

  const clearError = () => setError(null)

  const upload = (file: File) => {
    setIsUploading(true)
    clearError()
    const formData = new FormData()
    formData.set('file', file)
    const url = api(`/events/${event.id}/resources`)

    client
      .post<ResourceUploadData>(url, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((upload) => {
        onChange(upload.file)
      })
      .catch((e) => {
        setError(e.message)
      })
      .finally(() => {
        setIsUploading(false)
      })
  }

  const handleUpload = async (acceptedFile: File) => {
    if (hasExistingFile) {
      removeFile().then(() => {
        upload(acceptedFile)
      })
      return
    }

    upload(acceptedFile)
  }

  const removeFile = () => {
    setIsUploading(true)

    return deleteFile(filePath)
      .catch(() => {
        // ignore failing to delete file
      })
      .then(() => {
        onChange('')
      })
      .finally(() => {
        setIsUploading(false)
      })
  }

  const uploadOptions = {
    accept: RESOURCE_FILE_TYPES,
  }

  if (isUrl) {
    return null
  }

  return (
    <>
      <Container>
        <UploadDropzone
          options={uploadOptions}
          onDrop={handleUpload}
          aria-label="resource upload"
        />
        <LoadingOverlay visible={isUploading} />
      </Container>
      <UploadedResource
        filePath={filePath}
        onRemove={removeFile}
        aria-label="remove resource file"
      />
      <Error>{error}</Error>
    </>
  )
}

function LoadingOverlay(props: {visible: boolean}) {
  if (!props.visible) {
    return null
  }

  return (
    <LoaderWrapper>
      <CircularProgress />
    </LoaderWrapper>
  )
}

export function useDeleteFile() {
  const {
    event: {id},
  } = useEvent()
  const {client} = useOrganization()

  return useCallback(
    (file: string) => {
      const url = api(`/events/${id}/resources/${file}`)
      return client.delete(url)
    },
    [client, id],
  )
}

function Error(props: {children: string | null}) {
  if (!props.children) {
    return null
  }

  return (
    <ErrorText color="error">
      We could not upload your file: {props.children}
    </ErrorText>
  )
}

const ErrorText = withStyles({
  root: {
    marginBottom: spacing[3],
  },
})(Typography)

const Container = styled.div`
  position: relative;
`

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`
