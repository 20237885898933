import {obieApi} from 'lib/obie/url'
import {useOrganizationObieClient} from 'lib/obie/use-organization-obie-client'
import {useOrganization} from 'organization/OrganizationProvider'
import {useMutation, useQueryClient} from 'react-query'

export function useUploadAssistantFile() {
  const {organization} = useOrganization()
  const qc = useQueryClient()
  const {client} = useOrganizationObieClient()

  return useMutation({
    mutationFn: (data: {file: File; assistant: {id: number}}) => {
      const {file, assistant} = data
      const formData = new FormData()
      formData.set('file', file)

      return client!.post(
        obieApi(
          `organizations/${organization.id}/assistants/${assistant.id}/files`,
        ),
        formData,
      )
    },
    onSuccess: (_, data) => {
      qc.invalidateQueries([
        'organizations',
        organization.id,
        'assistants',
        data.assistant.id,
      ])
    },
  })
}
