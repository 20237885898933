import React from 'react'
import {UpsellPageTemplate} from 'Event/Marketplace/upsell-page'
import {ErrorText} from 'organization/Marketplace/PurchasePageConfig/MissingRequiredBlocksAlert'

export default function MissingRequiredBlocksAlert(props: {
  template: UpsellPageTemplate
}) {
  const {template} = props
  const error = useError(template)

  if (!error) {
    return null
  }

  return <ErrorText color="error">{error}</ErrorText>
}

function useError(template: UpsellPageTemplate) {
  if (!template?.ticket_id) {
    return 'A ticket type is required to be selected under Page Settings.'
  }

  return null
}
