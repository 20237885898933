import React from 'react'
import styled from 'styled-components'
import Button from 'lib/ui/Button'
import Logo from 'organization/Obie/Logo'

export default function Header({onBack}: {onBack?: () => void}) {
  return (
    <Box>
      <Logo />
      {onBack && <BackButton onClick={onBack}>Back</BackButton>}
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  align-items: center;
`

const BackButton = styled(Button)`
  background: ${(props) => props.theme.colors.primary} !important;
  color: #ffffff;

  &:hover {
    opacity: 0.8;
  }
`
