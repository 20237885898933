/**
 * Feature Flags
 * ---------------------------------------------
 * Used to only show features to users that
 * have the required flags.
 */

export const MARKETPLACE = 'marketplace'
export const ROOM_LIMIT_MAX_ATTENDEES = 'rooms.limit_max_attendees'
export const BROADCASTS = 'broadcasts'
export const SMS = 'sms'
export const PROMOTER = 'promoter'
export const ZOOM_ATTENDANCE = 'zoomAttendance'
export const TOWNHALL = 'townhall'
export const SAGEHUB = 'sagehub'
export const OBIE = 'obie'
export const OBIE_ASSISTANTS = 'obie_assistants'
export const MARKETPLACE_UPSELL = 'marketplace_upsell'

export type FeatureFlag =
  | typeof MARKETPLACE
  | typeof ROOM_LIMIT_MAX_ATTENDEES
  | typeof BROADCASTS
  | typeof SMS
  | typeof PROMOTER
  | typeof ZOOM_ATTENDANCE
  | typeof TOWNHALL
  | typeof SAGEHUB
  | typeof OBIE
  | typeof OBIE_ASSISTANTS
  | typeof MARKETPLACE_UPSELL
