import AssistantsSidebar from 'organization/Obie/Assistants/AssistantsSidebar'
import MessageInput from 'organization/Obie/Assistants/MessageInput'
import ThreadMessages from 'organization/Obie/Assistants/ThreadMessages'
import React, {useState} from 'react'
import styled from 'styled-components'
import {useAssistantThreadMessages} from 'lib/obie/use-assistant-thread'
import {useParams} from 'react-router'
import {parseInt} from 'lodash'
import {obieApi} from 'lib/obie/url'
import {useOrganization} from 'organization/OrganizationProvider'
import {useOrganizationObieToken} from 'lib/obie/use-organization-obie-token'

export default function AssistantChat() {
  const params = useParams<{thread: string}>()
  const threadId = parseInt(params.thread)
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useAssistantThreadMessages({id: threadId})
  const {organization} = useOrganization()
  const {data: token} = useOrganizationObieToken()

  const sendMessage = () => {
    if (!token) {
      return
    }

    // Add user message to messages
    setMessages((prev) => [...prev, {content: message, type: 'user'}])

    fetch(
      obieApi(
        `/organizations/${organization.id}/assistant_threads/${threadId}`,
      ),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          Accept: 'text/event-stream',
        },
        body: JSON.stringify({message}),
      },
    )
      .then((response) => {
        if (!response.body) {
          return
        }
        const reader = response.body.getReader()
        const decoder = new TextDecoder()

        // Add an initial empty AI message that will be updated
        setMessages((prev) => [...prev, {content: '', type: 'assistant'}])

        function processStream({done, value}: any) {
          if (done) {
            return
          }

          const chunk = decoder.decode(value, {stream: true})

          // This is required to detect when a new line is being written because
          // we are splitting the stream into chunks by \n\n, which means that
          // if there are two empty items in the array, it means a new line is
          // being written since a split turns the item blank.
          // eg: ['\n', 's', '\n', 's'] -> ['', 's', '', 's']
          let lineBreakCount = 0

          chunk.split('\n\n').forEach((event) => {
            if (!event) {
              lineBreakCount++
            } else {
              lineBreakCount = 0
            }

            // Remove the 'data: ' prefix as required from the Server Sent Events protocol.
            // eg:
            // data: first line\n
            // data: second line\n\n
            const content = event.replace('data: ', '')

            // If there are two line breaks in a row, it means a new line is
            // being written.
            const newContent = lineBreakCount > 1 ? '\n' : content

            setMessages((messages) => {
              const updated = [...messages]
              const prevContent = updated[updated.length - 1].content
              updated[updated.length - 1].content = prevContent + newContent
              return updated
            })
          })

          // Continue reading the stream
          reader.read().then(processStream)
        }

        reader.read().then(processStream)
      })
      .catch((error) => {
        console.error('Stream error:', error)
      })
  }

  return (
    <Layout>
      <AssistantsSidebar />
      <Main>
        <Top>
          <ThreadMessages messages={messages} />
        </Top>
        <Bottom>
          <MessageInput
            value={message}
            onChange={setMessage}
            onSubmit={() => {
              sendMessage()
              setMessage('')
            }}
          />
        </Bottom>
      </Main>
    </Layout>
  )
}

const Layout = styled.div`
  display: flex;
  width: 100%;
`

const Main = styled.div`
  flex: 1;
  display: flex;
  background: #141d34;
  flex-direction: column;
  max-height: 100vh;
`

const Top = styled.div`
  flex: 1;
  overflow-y: auto;
`

const Bottom = styled.div`
  min-height: 116px;
  overflow-y: auto;
  background: #060f27;
  border-top: 1px solid #1e273d;
  display: flex;
  justify-content: center;
`
