import React, {useState} from 'react'
import {useTrackEventPage} from 'analytics'
import {Redirect} from 'react-router-dom'
import {eventRoutes} from 'Event/Routes'
import {useQueryParams} from 'lib/url'
import styled, {createGlobalStyle} from 'styled-components'
import {Question} from 'organization/Event/Form/FormModeration/Question'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {format, parseISO} from 'date-fns'
import {useFormSubmissionsByToken} from 'lib/event-api/forms/use-form-submissions'
import {useParams} from 'react-router-dom'
import {FormSubmissionStatus} from 'organization/Event/Form/FormModeration/Question'
import {ReactComponent as DeleteIcon} from 'assets/images/icons/delete-white.svg'
import Icon from 'lib/ui/Icon'
import {sortSubmissions} from 'organization/Event/Form/FormModeration/QATab/sort-submissions'
import {useMoveSubmission} from 'Event/FormStageView/use-move-submission'
import {Form} from 'organization/Event/FormsProvider'
import {useFindFormByAccessToken} from 'lib/event-api/forms/use-find-form-by-access-token'
import FormSocketProvider, {
  useListenForFormSubmissionSavedUpdates,
} from 'Event/FormStageView/FormSocketProvider'
import {useGuestVariables} from 'Event'

export default function FormStageView() {
  useTrackEventPage({
    page: 'Form Stage View',
  })

  const {token, show_controls} = useQueryParams()
  const {data: form} = useFindFormByAccessToken({token: token!})

  const showingControls = show_controls === 'true'

  if (!token) {
    return <Redirect to={eventRoutes.login} />
  }

  if (!form) {
    return null
  }

  return (
    <FormSocketProvider form={form}>
      <StageViewContent form={form} showingControls={showingControls} />
    </FormSocketProvider>
  )
}

function StageViewContent(props: {form: Form; showingControls?: boolean}) {
  const {form, showingControls} = props
  const moveSubmission = useMoveSubmission({form})
  const {status} = useParams<{status: FormSubmissionStatus}>()

  const {data: submissions} = useFormSubmissionsByToken({
    form,
    status: status,
  })

  const [zoom, setZoom] = useState<number>(1)

  const zoomIn = () => {
    setZoom((currentZoom) => currentZoom + 0.1)
  }

  const zoomOut = () => {
    setZoom((currentZoom) => currentZoom - 0.1)
  }

  const calculateZoom = (pixels: number) => pixels * zoom

  useListenForFormSubmissionSavedUpdates(form)

  if (!submissions) {
    return <FullPageLoader />
  }

  return (
    <>
      <GlobalStyles />
      <Page>
        {submissions.sort(sortSubmissions).map((submission, index) => {
          return (
            <Item aria-label="ondeck item" key={submission.id}>
              <ItemHead
                style={{
                  fontSize: `${calculateZoom(25)}px`,
                }}
              >
                <ItemHeadLeft>
                  {`${submission.attendee_first_name} ${submission.attendee_last_name} @`}
                  &nbsp;
                  <ItemDate
                    style={{
                      fontSize: `${calculateZoom(20)}px`,
                    }}
                  >
                    {format(
                      parseISO(submission.saved_at),
                      'MMMM dd, yyy @ h:mm a',
                    )}
                  </ItemDate>
                </ItemHeadLeft>
              </ItemHead>
              <Box>
                <QuestionsPreview
                  answers={submission.answers}
                  calculateZoom={calculateZoom}
                />
                <StyledActionContent show={showingControls}>
                  <DeleteIcon
                    style={{
                      cursor: 'pointer',
                      height: `${calculateZoom(30)}px`,
                    }}
                    onClick={() => {
                      moveSubmission({
                        fromStatus: status,
                        fromIndex: index,
                        toStatus: 'deleted',
                        toIndex: 0,
                      })
                    }}
                  />

                  <MoveToAnswered
                    className={'fa-solid fa-circle-check'}
                    iconSize={calculateZoom(30)}
                    color="#ffffff"
                    onClick={() => {
                      moveSubmission({
                        fromStatus: status,
                        fromIndex: index,
                        toStatus: 'answered',
                        toIndex: 0,
                      })
                    }}
                  />
                </StyledActionContent>
              </Box>
            </Item>
          )
        })}

        <ZoomButtons show={showingControls}>
          <ZoomButton onClick={zoomOut}>-</ZoomButton>
          <ZoomButton onClick={zoomIn}>+</ZoomButton>
        </ZoomButtons>
      </Page>
    </>
  )
}

function QuestionsPreview(props: {
  answers: Question[]
  calculateZoom: (pixels: number) => number
}) {
  const {answers, calculateZoom} = props
  const v = useGuestVariables()

  if (answers.length > 1) {
    return (
      <div>
        {answers.map((answer, index) => {
          if (!answer.answer) return null

          return (
            <div key={index}>
              <ItemQuestion
                style={{
                  fontSize: `${calculateZoom(30)}px`,
                }}
              >
                {v(answer.question)}
              </ItemQuestion>
              &nbsp;
              <ItemMessage
                style={{
                  fontSize: `${calculateZoom(30)}px`,
                }}
              >
                {answer.answer}
              </ItemMessage>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <ItemMessage
      style={{
        fontSize: `${calculateZoom(30)}px`,
      }}
    >
      {answers[0].answer}
    </ItemMessage>
  )
}

const ZoomButtons = styled.div<{show?: boolean}>`
  position: fixed;
  bottom: 15px;
  right: 15px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
`

const ZoomButton = styled.div`
  width: 40px;
  height: 30px;
  background: #676767;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-right: 1px solid #000000;

  &:last-child {
    border-right: 0px;
  }
`

const Page = styled.div`
  padding: ${(props) => props.theme.spacing[8]};
`

const Item = styled.div`
  border-bottom: 1px dashed #d6d6d6;
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

const ItemHead = styled.div`
  color: #ffffff;
  display: flex;
  justify-content: space-between;
`

const ItemHeadLeft = styled.div`
  align-items: center;
  display: flex;
  color: #d6d6d6;
`

const ItemDate = styled.span`
  font-style: italic;
`

const Box = styled.div`
  display: flex;
  justify-content: space-between;
`

const ItemQuestion = styled.span`
  color: #ffffff;
  font-weight: 600;
`
const ItemMessage = styled.span`
  font-weight: 400;
  color: #ffffff;
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

const MoveToAnswered = styled(Icon)`
  margin-left: ${(props) => props.theme.spacing[4]};
  cursor: pointer;
`

const StyledActionContent = styled.div<{show?: boolean}>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  position: relative;
  bottom: ${(props) => props.theme.spacing[3]};
  float: right;
  align-self: flex-end;
`

const GlobalStyles = createGlobalStyle`
body {
    background: #000000; 
}
`
