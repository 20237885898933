import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {useOrganization} from 'organization/OrganizationProvider'
import ObieLayout from 'organization/Obie/Layout'
import {Assistant} from '../../../lib/obie/use-assistant'
import {routesWithValue} from 'lib/url'
import AssistantChat from 'organization/Obie/Assistants/AssistantChat'
import AssistantsListPage from 'organization/Obie/Assistants/AssistantsListPage'
import CreateAssistantForm from 'organization/Obie/Assistants/CreateAssistantForm'
import AssistantSettings from 'organization/Obie/Assistants/AssistantSettings'

export default function AssistantsRoutes() {
  const {routes} = useOrganization()
  return (
    <ObieLayout hideSidebar>
      <Switch>
        <Route
          exact
          path={routes.obie.assistants[':assistant'].threads[':thread'].root}
        >
          <AssistantChat />
        </Route>
        <Route exact path={routes.obie.assistants.create}>
          <CreateAssistantForm />
        </Route>
        <Route exact path={routes.obie.assistants[':assistant'].settings}>
          <AssistantSettings />
        </Route>
        <Route>
          <AssistantsListPage />
        </Route>
      </Switch>
    </ObieLayout>
  )
}

export function useAssistantRoutes(assistant: Assistant) {
  const {routes: organizationRoutes} = useOrganization()

  return routesWithValue(
    ':assistant',
    String(assistant.id),
    organizationRoutes.obie.assistants[':assistant'],
  )
}
