import Button from 'lib/ui/Button'
import TextField from 'lib/ui/TextField'
import {useAssistantTemplates} from 'lib/obie/use-assistant-templates'
import {useCreateAssistant} from 'lib/obie/use-create-assistant'
import Logo from 'organization/Obie/Logo'
import {useOrganization} from 'organization/OrganizationProvider'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useHistory} from 'react-router-dom'
import {useSearchParam} from 'react-use'
import styled from 'styled-components'
import {RelativeLink} from 'lib/ui/link/RelativeLink'

export default function CreateAssistantForm() {
  const assistantTemplateId = useSearchParam('assistant_template_id')
  const history = useHistory()
  const {routes} = useOrganization()
  const {register, handleSubmit} = useForm()
  const createAssistant = useCreateAssistant()

  const {data: assistantTemplates} = useAssistantTemplates()

  const assistantTemplate = assistantTemplates?.find(
    (template) => template.id === parseInt(assistantTemplateId ?? ''),
  )

  if (!assistantTemplates) {
    return null
  }

  if (!assistantTemplate) {
    history.push(routes.obie.assistants.root)
    return null
  }

  const submit = (data: {name: string}) => {
    const {name} = data
    if (createAssistant.isLoading) {
      return
    }

    createAssistant.mutate({
      assistantTemplateId: assistantTemplate.id,
      name,
      instructions: assistantTemplate.base_instructions,
    })
  }

  return (
    <PageContainer>
      <BackLink to={routes.obie.assistants.root}>Back to Assistants</BackLink>
      <Header>
        <StyledLogo /> Assistants
      </Header>
      <div>
        <p>Create a new {assistantTemplate.default_name}</p>
        <form onSubmit={handleSubmit(submit)}>
          <StyledTextField
            name="name"
            fullWidth
            defaultValue={assistantTemplate.default_name}
            inputProps={{
              ref: register,
              'aria-label': 'name',
            }}
            required
            disabled={createAssistant.isLoading}
          />
          <StyledButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={createAssistant.isLoading}
          >
            Create
          </StyledButton>
        </form>
      </div>
    </PageContainer>
  )
}

const PageContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  color: #fff;
`

const Header = styled.h1`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 36px;
  line-height: 64px;
  color: #ffffff;
  gap: 1rem;
`

const StyledLogo = styled(Logo)`
  padding: 0;
`

const StyledTextField = styled(TextField)`
  color: #ffffff;

  input {
    background: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
`

const StyledButton = styled(Button)`
  margin-top: 1rem;
`

const BackLink = styled(RelativeLink)`
  color: #fff;
`
