import {useOrganization} from 'organization/OrganizationProvider'
import {useQuery} from 'react-query'
import {useParams} from 'react-router'
import {obieApi} from './url'
import {useOrganizationObieClient} from './use-organization-obie-client'

export interface Assistant {
  id: number
  name: string
  threads: AssistantThread[]
}

interface UseAssistantParams {
  id: number
}

export interface AssistantThread {
  id: number
  title?: string
  assistant_id: number
}

export function useAssistant(params: UseAssistantParams) {
  const {id} = params
  const {organization} = useOrganization()
  const {client} = useOrganizationObieClient()

  return useQuery({
    queryKey: ['organizations', organization.id, 'assistants', id],
    queryFn: () =>
      client!.get<Assistant>(
        obieApi(`/organizations/${organization.id}/assistants/${id}`),
      ),
  })
}

export function useRouteAssistant() {
  const {assistant: assistantId} = useParams<{assistant: string}>()
  return useAssistant({id: parseInt(assistantId)})
}
