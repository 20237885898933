import React from 'react'
import {AND, BaseRule} from 'Event/attendee-rules'
import {Option, QuestionBase} from 'organization/Event/QuestionsProvider'
import {Form, useForms} from 'organization/Event/FormsProvider'
import {OTHER} from 'Event/Question/Radio'
import {useGuestVariables} from 'Event'

export const FORM_RESPONSE = 'Form Response'
export const IS = 'is'
export const IS_NOT = 'is not'

export const CONTAIN = 'contains'
export const DOES_NOT_CONTAIN = 'does not contain'

export type FormResponseRule = BaseRule & {
  source: typeof FORM_RESPONSE
  type: typeof IS | typeof IS_NOT | typeof CONTAIN | typeof DOES_NOT_CONTAIN
  form_id: Form['id']
  question_id: QuestionBase['id']
  value: Option['value']
}

export default function FormResponseRuleLabel(props: {
  rule: FormResponseRule
  form?: Form
}) {
  const {rule} = props
  const {forms} = useForms()
  const form = props.form ?? forms.find((f) => f.id === rule.form_id)
  const question = form?.questions.find((q) => q.id === rule.question_id)
  const questionLabel = question ? question.label : 'MISSING QUESTION'
  const value = rule.value === OTHER ? "'other'" : rule.value
  const v = useGuestVariables()

  return (
    <div>
      Form Response for '{v(questionLabel)}' {rule.type} {v(value)}
    </div>
  )
}

export const createFormResponseRule = (
  type: FormResponseRule['type'],
  form_id: Form['id'],
  question_id: FormResponseRule['question_id'],
  value: FormResponseRule['value'],
): FormResponseRule => ({
  condition: AND,
  source: FORM_RESPONSE,
  type,
  form_id,
  question_id,
  value,
})
