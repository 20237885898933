import React from 'react'
import Text from 'Event/auth/ChangePassword/Description/Text'
import Configurable from 'organization/Event/Configurable'
import DescriptionConfig from 'Event/auth/ChangePassword/Description/DescriptionConfig'
import {useTemplate} from 'Event/TemplateProvider'
import {useAttendeeVariables} from 'Event'

export default function Description(props: {
  className?: string
  Component?: React.FC
}) {
  const Component = props.Component || Text

  const {changePassword} = useTemplate()
  const v = useAttendeeVariables()

  return (
    <Configurable>
      <DescriptionConfig />
      <Component className={props.className}>
        {v(changePassword.description.text)}
      </Component>
    </Configurable>
  )
}
