import React from 'react'
import Page from 'organization/Event/Page'
import Grid from '@material-ui/core/Grid'
import PageHeader from 'lib/ui/PageHeader'
import Title from 'lib/ui/PageHeader/Title'
import {Description} from 'lib/ui/typography'
import TemplateCard, {
  Action,
  BlankOption,
  CONVERT,
  ConvertOption,
  CREATE_BLANK,
  CREATE_SAMPLE,
  SampleOption,
  SelectButtonBase,
  useTemplateCard,
} from 'organization/Event/SelectTemplatePage/TemplateCard'
import {templates, Template, createBlank, convert} from 'Event/template'
import {useToggle} from 'lib/toggle'
import {useTemplate} from 'Event/TemplateProvider'
import {
  useDuplicateAssets,
  useFetchSampleEvent,
  useSetTemplate,
} from 'organization/Event/SelectTemplatePage/index'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import Button from 'lib/ui/Button'
import {EventBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {useHasOrganizationFeatureToggle} from 'organization/OrganizationFeatureToggle'
import {produce} from 'immer'

/**
 * List of translation field prefixes to copy from the current template to the new template.
 * ie. if a field starts with `form_`, it will be copied from the current template to
 * the new template.
 */
const translationFieldPrefixesToCopy = ['form_']

export default function ChangeTemplatePage() {
  const setTemplate = useSetTemplate()
  const {flag: processing, toggle: toggleProcessing} = useToggle()
  const currentTemplate = useTemplate()
  const createData = useCreateData(currentTemplate)

  const hasTownhallFlag = useHasOrganizationFeatureToggle('townhall')
  const names = Object.keys(templates).filter((name) => {
    if (name === 'townhall') {
      return hasTownhallFlag
    }

    return true
  }) as Template['name'][]

  const copyLocalization = (template: Template) => {
    const {localization: currentLocalization} = currentTemplate
    const {localization: newLocalization} = template

    if (!currentLocalization || !newLocalization) {
      return template
    }

    return produce(template, (draft) => {
      if (!draft.localization) {
        return
      }

      for (const language of currentLocalization.languages) {
        const translations = currentLocalization.translations?.[language.name]
        if (!translations) {
          continue
        }

        // Initialize language translations object if it doesn't exist
        if (!draft.localization.translations) {
          draft.localization.translations = {}
        }

        if (!draft.localization.translations[language.name]) {
          draft.localization.translations[language.name] = {}
        }

        // Copy form translations
        for (const [key, value] of Object.entries(translations)) {
          if (
            !translationFieldPrefixesToCopy.some((prefix) =>
              key.startsWith(prefix),
            )
          ) {
            continue
          }

          const languageTranslations =
            draft.localization.translations[language.name]
          if (languageTranslations) {
            languageTranslations[key] = value
          }
        }

        // Add language if not exists
        if (!draft.localization.languages) {
          draft.localization.languages = []
        }

        if (
          !draft.localization.languages.some((l) => l.name === language.name)
        ) {
          draft.localization.languages.push(language)
        }
      }
    })
  }

  const handleSelect = (template: Template['name'], action: Action) => {
    if (processing) {
      return
    }
    toggleProcessing()

    createData(template, action)
      .then(copyLocalization)
      .then(setTemplate)
      .finally(toggleProcessing)
  }

  if (processing) {
    return <FullPageLoader />
  }

  return (
    <EventBreadcrumbs page="Template">
      <Page>
        <PageHeader>
          <Grid container>
            <Grid item xs={12}>
              <Title text="Change Your Event's Template" />
            </Grid>
            <Grid item xs={12}>
              <Description>
                Once an event template has been changed, it cannot be reversed.
              </Description>
            </Grid>
          </Grid>
        </PageHeader>
        <Grid container spacing={2}>
          {names.map((name) => (
            <Grid item xs={12} md={4} key={name}>
              <TemplateCard
                template={name}
                disabled={processing}
                defaultAction={CONVERT}
                options={
                  <>
                    <BlankOption />
                    <SampleOption />
                    <ConvertOption />
                  </>
                }
                selectButton={
                  <SelectButton
                    onSelect={handleSelect}
                    isSelected={name === currentTemplate.name}
                    disabled={processing}
                  />
                }
              />
            </Grid>
          ))}
        </Grid>
      </Page>
    </EventBreadcrumbs>
  )
}

function useCreateData(current: Template) {
  const withCopiedAssets = useDuplicateAssets<Template>()
  const fetchSample = useFetchSampleEvent()

  return async (template: Template['name'], action: Action) => {
    switch (action) {
      case 'create_blank':
        return Promise.resolve(createBlank(template))
      case 'create_sample':
        const event = await fetchSample(template)
        return withCopiedAssets(event.template)
      case 'convert':
        return Promise.resolve(convert(current, template))
      default:
        throw new Error(`Unhandled template action: ${action}`)
    }
  }
}

function SelectButton(props: {
  disabled: boolean
  onSelect: (template: Template['name'], action: Action) => void
  isSelected: boolean
}) {
  const {action, template} = useTemplateCard()
  const {onSelect, disabled} = props
  const warningText = () => {
    switch (action) {
      case CREATE_BLANK:
        return 'You are about to change templates. Selecting a Blank Template will lose all existing data in your event. Are you SURE you wish to continue? This action can NOT be undone.'
      case CREATE_SAMPLE:
        return 'You are about to change templates. Selecting a Template With Sample Data will lose all existing data in your event. Are you SURE you wish to continue? This action can NOT be undone.'
      case CONVERT:
        return "You are about to change templates. While you should see everything that you have already created in the new template, because each template has some unique properties, you may lose some of the work you've already done. Are you sure you wish to continue?"
      default:
        throw new Error(`Missing change template warning for action: ${action}`)
    }
  }

  if (props.isSelected) {
    return (
      <Button
        variant="contained"
        color="success"
        fullWidth
        disabled={disabled}
        aria-label={template}
      >
        Selected
      </Button>
    )
  }

  return (
    <ConfirmDialog
      onConfirm={() => onSelect(template, action)}
      confirmLabel={'Change My Template'}
      aria-label="change template confirm"
      description={warningText()}
    >
      {(confirm) => (
        <SelectButtonBase
          disabled={disabled}
          template={template}
          aria-label={template}
          onClick={confirm}
        />
      )}
    </ConfirmDialog>
  )
}
