import Skeleton from '@material-ui/lab/Skeleton'
import Button from 'lib/ui/Button'
import {Assistant, useRouteAssistant} from 'lib/obie/use-assistant'
import {useAssistantFiles} from 'lib/obie/use-assistant-files'
import React, {useState, useRef} from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import {useUploadAssistantFile} from 'lib/obie/use-upload-assistant-file'
import {useDeleteAssistantFile} from 'lib/obie/use-delete-assistant-file'
// import {useDeleteAssistantFile} from 'organization/Obie/Assistants/use-delete-assistant-file'

export function AssistantSettingsFiles() {
  const {data: assistant} = useRouteAssistant()

  if (!assistant) {
    return <Loader />
  }

  return (
    <>
      <FileList assistant={assistant} />
      <AddFileButton assistant={assistant} />
    </>
  )
}

interface ContentProps {
  assistant: Assistant
}

function FileList(props: ContentProps) {
  const {assistant} = props
  const {data: files} = useAssistantFiles(assistant)
  const deleteFile = useDeleteAssistantFile()

  if (!files) {
    return <Loader />
  }

  if (files.length === 0) {
    return <div>No files</div>
  }

  return (
    <FileListContainer>
      {files.map((file) => (
        <FileRow key={file.id}>
          <FileName>{file.name}</FileName>
          <DeleteButton
            onClick={() => deleteFile.mutate(file)}
            variant="text"
            color="danger"
            disabled={deleteFile.isLoading}
          >
            ✕
          </DeleteButton>
        </FileRow>
      ))}
    </FileListContainer>
  )
}

function AddFileButton(props: ContentProps) {
  const {assistant} = props
  const [error, setError] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const uploadFile = useUploadAssistantFile()

  const ACCEPTED_FILE_TYPES = '.md,.pdf,.docx,.txt'
  const MAX_FILE_SIZE_BYTES = 10000000 // 10MB

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null
    if (!file) {
      return
    }

    if (file.size > MAX_FILE_SIZE_BYTES) {
      setError('File too large. Please select a file smaller than 10MB.')
      return
    }

    setError(null)

    uploadFile.mutate({file, assistant})
  }

  const handleClick = () => {
    inputRef.current?.click()
  }

  return (
    <>
      <StyledAddFileButton
        onClick={handleClick}
        type="button"
        variant="contained"
        color="primary"
        disabled={uploadFile.isLoading}
      >
        {uploadFile.isLoading ? 'Uploading...' : 'Add File'}
      </StyledAddFileButton>
      <input
        ref={inputRef}
        type="file"
        accept={ACCEPTED_FILE_TYPES}
        onChange={handleFileSelect}
        style={{display: 'none'}}
        aria-label="file upload input"
      />
      {error && <ErrorText color="error">{error}</ErrorText>}
    </>
  )
}

function Loader() {
  return (
    <LoaderBox>
      <Skeleton
        variant="text"
        height={24}
        style={{
          width: '100%',
          background: '#1a2847',
        }}
      />
      <Skeleton
        variant="text"
        height={24}
        style={{
          width: '100%',
          background: '#1a2847',
        }}
      />
      <Skeleton
        variant="text"
        height={24}
        style={{
          width: '100%',
          background: '#1a2847',
        }}
      />
    </LoaderBox>
  )
}

const LoaderBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const StyledAddFileButton = styled(Button)`
  margin-top: 1rem;
`

const ErrorText = styled(Typography)`
  color: ${(props) => props.theme.colors.error};
  margin-top: ${(props) => props.theme.spacing[2]};
  font-size: 14px;
`

const FileListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const FileRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 4px;
`

const FileName = styled.span`
  font-size: 14px;
`

const DeleteButton = styled(Button)`
  min-width: 32px;
  padding: 4px;
  color: ${(props) => props.theme.colors.error};

  &:hover {
    background: transparent;
    opacity: 0.8;
  }
`
