import {useAttendeeVariables} from 'Event'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'

export default function TitleConfig() {
  const saveTemplate = useSaveTemplate()

  const template = useTemplate()
  const {faq} = template
  const {
    form: {register},
  } = useConfig()

  const addTranslation = useAddTranslation()
  const v = useAttendeeVariables()

  const save = (data: any) => {
    const title = replaceAtPath(data, 'faq.title', '{{faq_title}}')
    const menuTitle = replaceAtPath(data, 'faq.menuTitle', '{{faq_menu_title}}')
    saveTemplate({
      ...data,
      localization: addTranslation({
        faq_title: title ?? '',
        faq_menu_title: menuTitle ?? '',
      }),
    })
  }

  return (
    <Config title="Title" onSave={save}>
      <SettingsPanel>
        <TextField
          name="faq.title"
          defaultValue={v(faq.title)}
          label="Title"
          fullWidth
          inputProps={{
            'aria-label': 'faq page title',
            ref: register,
          }}
        />
        <MenuTitleConfig />
      </SettingsPanel>
    </Config>
  )
}

function MenuTitleConfig() {
  const v = useAttendeeVariables()
  const {
    form: {register},
  } = useConfig()

  const {faq} = useTemplate()

  if (!('menuTitle' in faq)) {
    return null
  }

  return (
    <>
      <TextField
        name="faq.menuTitle"
        defaultValue={v(faq.menuTitle || 'FAQs')}
        label="Menu Title"
        fullWidth
        inputProps={{
          'aria-label': 'menu title',
          ref: register,
        }}
      />
    </>
  )
}
