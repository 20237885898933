import React from 'react'
import {api} from 'lib/url'
import {useEvent} from 'Event/EventProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import {useToggle} from 'lib/toggle'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import DangerButton from 'lib/ui/Button/DangerButton'

export default function DeleteAllImagesButton(props: {
  onSuccess: (message: string | null) => void
  onError: (message: string | null) => void
}) {
  const {onSuccess: setSuccessMessage, onError: setErrorMessage} = props
  const {event} = useEvent()
  const {client} = useOrganization()
  const {flag: processing, toggle: toggleProcessing} = useToggle()

  const deleteImages = (password?: string) => {
    setSuccessMessage(null)
    setErrorMessage(null)

    if (processing) {
      return
    }

    toggleProcessing()

    const url = api(`/events/${event.id}/image_entries/clear`)

    client
      .post<{message: string}>(url, {
        password: password,
      })
      .then(() => {
        setSuccessMessage('All images are being removed.')
      })
      .catch((e) => {
        setErrorMessage(e.message)
      })
      .finally(toggleProcessing)
  }

  return (
    <>
      <ConfirmDialog
        onConfirm={(e, password) => {
          deleteImages(password)
        }}
        title="Delete All Images"
        description="You are about to delete all submitted images. This CANNOT be undone. Are you sure you wish to continue?"
        confirmLabel="Confirm Delete"
        requirePassword
      >
        {(showConfirmation) => (
          <DangerButton
            variant="contained"
            onClick={showConfirmation}
            disabled={processing}
          >
            Delete All Images
          </DangerButton>
        )}
      </ConfirmDialog>
    </>
  )
}
