import React, {useState} from 'react'
import {useLanguage} from 'Event/LanguageProvider'
import styled from 'styled-components'
import {Language} from 'Event/LanguageProvider/language'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Dialog from 'lib/ui/Dialog'
import {useIsAttendee, useSetGroup} from 'Event/auth'
import {useEditMode} from 'Event/EditModeProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {Button} from 'Event/Dashboard/components/NavButton'
import {LanguageSelectorConfig} from './LanguageSelectorConfig'
import Configurable from 'organization/Event/Configurable'

// If an attendee selects a language we want to set a pre-defined group
// value to the selected language. This helps in cases where the user
// may want to target the user based on their selected language.
const LANGUAGE_GROUP_KEY = '__language__'

export default function LanguageSelector() {
  const {current, set} = useLanguage()
  const {languageSelectorButton} = useTemplate()

  const {languages, translationsEnabled} = useLanguage()
  const [dialogVisible, setDialogVisible] = useState(false)
  const setGroup = useSetGroup()

  const toggleDialog = () => setDialogVisible(!dialogVisible)

  const isSelected = (language: Language) => language.name === current
  const isEditMode = useEditMode()
  const isAttendee = useIsAttendee()

  const handleSelect = (language: Language) => {
    set(language)

    // Update attendee group with selected language
    setGroup(LANGUAGE_GROUP_KEY, language.name)
    toggleDialog()
  }

  const handleDialog = () => {
    if (isEditMode) {
      return
    }
    toggleDialog()
  }

  if (!translationsEnabled) {
    return null
  }

  // Can't use this selector on the login/signup page because the useGuestVariables hook depends solely on the English/default language.
  if (!isEditMode && !isAttendee) {
    return null
  }

  return (
    <BottomLeft leftSpacing={isEditMode ? '248px' : '16px'}>
      <Configurable aria-label="edit language selector">
        <LanguageSelectorConfig />
        <>
          <Button {...languageSelectorButton} onClick={handleDialog}></Button>
          <Dialog
            open={dialogVisible}
            onClose={handleDialog}
            fullWidth
            maxWidth="xs"
          >
            <List disablePadding>
              {languages.map((language) => (
                <ListItem
                  key={language.name}
                  button
                  selected={isSelected(language)}
                  onClick={() => handleSelect(language)}
                >
                  {language.name}
                </ListItem>
              ))}
            </List>
          </Dialog>
        </>
      </Configurable>
    </BottomLeft>
  )
}

const BottomLeft = styled.div<{leftSpacing: string}>`
  position: fixed;
  left: ${(props) => props.leftSpacing};
  bottom: ${(props) => props.theme.spacing[4]};
  z-index: 10;
`
