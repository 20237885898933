import styled from 'styled-components'
import Button from 'lib/ui/Button'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import {api} from 'lib/url'
import React, {useState} from 'react'
import Typography from '@material-ui/core/Typography'
import {useOrganization} from 'organization/OrganizationProvider'
import {useForm} from 'organization/Event/Form/FormProvider'
import {useForms} from 'organization/Event/FormsProvider'
import {useHistory} from 'react-router'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {useGet} from 'lib/requests'
import DangerButton from 'lib/ui/Button/DangerButton'
import {useRemoveTranslationKeys} from 'Event/LanguageProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'

export default function FormActions(props: {className?: string}) {
  const {deleteForm, error} = useDelete()

  return (
    <div className={props.className}>
      <div>
        <ExportSubmission />

        <ConfirmDialog
          onConfirm={deleteForm}
          aria-label="delete form confirm description"
          description="Are you sure you wish to delete this form?"
        >
          {(confirm) => (
            <DangerButton
              variant="outlined"
              onClick={confirm}
              aria-label="delete form"
              size="small"
            >
              Delete
            </DangerButton>
          )}
        </ConfirmDialog>
      </div>
      <Error>{error}</Error>
    </div>
  )
}

function ExportSubmission() {
  const {form} = useForm()
  const {
    exportSubmissions,
    errorMessage,
    successMessage,
    processing,
  } = useSubmissionsExport(`/forms/${form.id}/submissions/export`)

  return (
    <ExportBox>
      <Button
        onClick={exportSubmissions}
        variant="outlined"
        color="primary"
        aria-label="export submissions"
        disabled={processing}
      >
        Download Submissions
      </Button>
      <Error>{errorMessage}</Error>
      <Success>{successMessage}</Success>
    </ExportBox>
  )
}

export function useSubmissionsExport(endpoint: string) {
  const url = api(endpoint)
  const {client} = useOrganization()

  const {
    send: exportSubmissions,
    processing,
    errorMessage,
    successMessage,
  } = useGet(client, url)

  return {
    exportSubmissions,
    errorMessage,
    successMessage,
    processing,
  }
}

function useDelete() {
  const [error, setError] = useState<string | null>(null)
  const {form} = useForm()
  const {remove} = useForms()
  const url = api(`/forms/${form.id}`)
  const {client} = useOrganization()
  const history = useHistory()
  const eventRoutes = useEventRoutes()
  const removeTranslationKeys = useRemoveTranslationKeys()
  const updateTemplate = useSaveTemplate()

  const deleteForm = () => {
    client
      .delete(url)
      .then(() => {
        const localizationUpdate = removeTranslationKeys(`form_${form.id}`)
        if (localizationUpdate) {
          updateTemplate({
            localization: localizationUpdate,
          })
        }
        remove(form)
        history.push(eventRoutes.forms.root)
      })
      .catch((e) => setError(e.message))
  }

  return {deleteForm, error}
}

function Error(props: {children: string | null}) {
  if (!props.children) {
    return null
  }

  return <Typography color="error">{props.children}</Typography>
}

function Success(props: {children: string | null}) {
  if (!props.children) {
    return null
  }

  return <Typography color="primary">{props.children}</Typography>
}

const ExportBox = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[8]};
`
