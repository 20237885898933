import {useOrganization} from 'organization/OrganizationProvider'
import {useQuery} from 'react-query'
import {obieApi} from './url'
import {useOrganizationObieClient} from './use-organization-obie-client'

export interface Assistant {
  id: number
  name: string
  threads: AssistantThread[]
}

export interface AssistantThread {
  id: number
  title?: string
}

export function useAssistantList() {
  const {organization} = useOrganization()
  const {client} = useOrganizationObieClient()

  return useQuery({
    queryKey: ['organizations', organization.id, 'assistants'],
    queryFn: () =>
      client?.get<Assistant[]>(
        obieApi(`/organizations/${organization.id}/assistants`),
      ),
  })
}
