import React from 'react'
import Page, {
  DescriptionText,
  StyledFormContainer,
} from 'Event/template/Lefty/Login/Page'
import {useGuestVariables} from 'Event'
import {TitleBase} from 'Event/template/Lefty/Login/Title'
import Configurable from 'organization/Event/Configurable'
import OfflineContentConfig from 'Event/EventOfflinePage/OfflineContentConfig'
import {OfflinePageProps} from 'Event/JoinArea/OfflinePage'
import {useLeftyTemplate} from 'Event/template/Lefty'

export default function EventOfflinePage(props: OfflinePageProps) {
  const v = useGuestVariables()
  const {linkColor, linkUnderline} = useLeftyTemplate()

  return (
    <Page isPreview={props.isPreview || false}>
      <Configurable
        aria-label="event offline content config"
        dataTestId="event offline config"
      >
        <OfflineContentConfig />
        <StyledFormContainer>
          <TitleBase>{v(props.title)}</TitleBase>
          <DescriptionText linkColor={linkColor} linkUnderline={linkUnderline}>
            <div dangerouslySetInnerHTML={{__html: v(props.description)}} />
          </DescriptionText>
        </StyledFormContainer>
      </Configurable>
    </Page>
  )
}
