import React, {useState} from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {useEvent} from 'Event/EventProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import {
  NameAppendage,
  useNameAppendages,
} from 'organization/Event/NameAppendageConfig/NameAppendageProvider'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import Table from '@material-ui/core/Table'
import {api} from 'lib/url'
import 'organization/Event/NameAppendageConfig/sorting.css'
import styled from 'styled-components'
import {GenerateTextForVisibilityRules} from 'organization/Event/NameAppendageConfig/Helpers/GenerateTextForVisibilityRules'
import {LabelPreview} from 'organization/Event/NameAppendageConfig/Helpers/LabelPreview'
import {Tooltip} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import {PencilIcon} from 'lib/ui/Icon'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import {useSaveNameAppendage} from '../AddDialog/NameAppendageAddForm'
import ConfirmDialog from 'lib/ui/ConfirmDialog'

export default function NameAppendageListTable(props: {
  setEditing: (nameAppendage: NameAppendage) => void
}) {
  const [submitting, setSubmitting] = useState(false)
  const {event} = useEvent()
  const {client} = useOrganization()
  const save = useSaveNameAppendage()
  const {nameAppendages, remove, reorder, add} = useNameAppendages()

  const removeNameAppendage = (nameAppendage: NameAppendage) => {
    setSubmitting(true)
    const url = api(`/attendee_labels/${nameAppendage.id}`)

    client
      .delete(url)
      .then(() => {})
      .finally(() => {
        remove(nameAppendage)
        setSubmitting(false)
      })
  }

  const onSortEnd = ({oldIndex, newIndex}: any) => {
    document.body.style.userSelect = 'auto'
    if (oldIndex !== newIndex) {
      const reorderedNameAppendages = reorderNameAppendage(
        nameAppendages,
        oldIndex,
        newIndex,
      )
      reorder(reorderedNameAppendages)

      const url = api(`/events/${event.id}/attendee_labels`)

      client.patch(url, {labels: reorderedNameAppendages})
    }
  }

  const onSortStart = () => {
    document.body.style.userSelect = 'none'
  }

  const copy = (nameAppendage: NameAppendage) => {
    setSubmitting(true)

    const copiedNameAppendage = {
      ...nameAppendage,
      text: `Copy of ${nameAppendage.text}`,
    }

    save(event, copiedNameAppendage)
      .then((newNameAppendage) => {
        add(newNameAppendage)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  if (!nameAppendages) {
    return <>Loading ...</>
  }

  return (
    <SortableList
      nameAppendages={nameAppendages}
      submitting={submitting}
      setEditing={props.setEditing}
      copy={copy}
      removeNameAppendage={removeNameAppendage}
      onSortEnd={onSortEnd}
      onSortStart={onSortStart}
      distance={10}
    />
  )
}

const SortableItem = SortableElement(
  (props: {
    nameAppendage: NameAppendage
    submitting: boolean
    setEditing: (nameAppendage: NameAppendage) => void
    copy: (nameAppendage: NameAppendage) => void
    removeNameAppendage: (nameAppendage: NameAppendage) => void
  }) => {
    const {
      nameAppendage,
      submitting,
      setEditing,
      removeNameAppendage,
      copy,
    } = props

    return (
      <TableRow aria-label="name appendage">
        <TableCell align="center">{nameAppendage.priority}</TableCell>
        <TableCell>
          <LabelPreview
            withoutDash={true}
            text={nameAppendage.text}
            emoji={nameAppendage.emoji}
          />
        </TableCell>
        <TableCell align="center" width={'500px'}>
          {' '}
          <GenerateTextForVisibilityRules
            rules={nameAppendage.rules}
            cropText={true}
            seeMoreCallback={() => setEditing(nameAppendage)}
          />{' '}
        </TableCell>
        <TableCell align="center">
          <Tooltip title="Update Attendee Label">
            <IconButton
              color="primary"
              aria-label="update name appendage"
              disabled={submitting}
              onClick={() => {
                setEditing(nameAppendage)
              }}
            >
              <PencilIcon iconSize={22} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Copy Attendee Label">
            <IconButton
              color="primary"
              aria-label="copy name appendage"
              disabled={submitting}
              onClick={() => {
                copy(nameAppendage)
              }}
            >
              <FileCopyIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Remove Attendee Label">
            <ConfirmDialog
              onConfirm={() => {
                removeNameAppendage(nameAppendage)
              }}
              confirmLabel={'Confirm'}
              description={'Would you like to proceed with removing this?'}
            >
              {(
                confirm: (event: React.MouseEvent<HTMLButtonElement>) => void,
              ) => (
                <IconButton
                  color="primary"
                  aria-label="remove name appendage"
                  disabled={submitting}
                  onClick={confirm}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </ConfirmDialog>
          </Tooltip>
        </TableCell>
      </TableRow>
    )
  },
)

const SortableList = SortableContainer(
  (props: {
    nameAppendages: NameAppendage[]
    submitting: boolean
    setEditing: (nameAppendage: NameAppendage) => void
    copy: (nameAppendage: NameAppendage) => void
    removeNameAppendage: (nameAppendage: NameAppendage) => void
  }) => {
    return (
      <>
        <TableBox>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Priority</TableCell>

                <TableCell align="center">Label</TableCell>
                <TableCell align="center">Visibility Rules</TableCell>
                <TableCell align="center">{/* Actions */}</TableCell>
                <TableCell align="center">{/* Actions */}</TableCell>
              </TableRow>
              {props.nameAppendages.map((value, index) => (
                <SortableItem
                  key={`item-${value.id}`}
                  index={index}
                  {...props}
                  nameAppendage={value}
                />
              ))}
            </TableHead>
          </Table>
        </TableBox>
      </>
    )
  },
)

function reorderNameAppendage(
  nameAppendages: NameAppendage[],
  oldIndex: any,
  newIndex: any,
) {
  const [movedItem] = nameAppendages.splice(oldIndex, 1)
  nameAppendages.splice(newIndex, 0, movedItem)

  return nameAppendages.map((item, index) => ({
    ...item,
    priority: index + 1,
  }))
}

const TableBox = styled.div`
  overflow: auto;
`
