import axios from 'axios'
import {useOrganization} from 'organization/OrganizationProvider'
import {useCallback} from 'react'
import {useQueryClient} from 'react-query'
import {useObieClient} from './use-obie-client'
import {useOrganizationObieToken} from './use-organization-obie-token'

export function useOrganizationObieClient() {
  const {organization} = useOrganization()
  const {id: organizationId} = organization

  const qc = useQueryClient()

  const {data: token, isFetching, isLoading} = useOrganizationObieToken()

  const handleError = useCallback(
    (error: unknown) => {
      if (axios.isAxiosError(error)) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          // Force re-fetch token
          qc.invalidateQueries([
            'organizations',
            organizationId,
            'obie',
            'token',
          ])
        }
      }
    },
    [qc, organizationId],
  )

  const client = useObieClient({
    token,
    handleError,
  })

  if (!client || isLoading || isFetching) {
    return {
      isLoading: true,
      client: null,
    }
  }

  return {
    isLoading: false,
    client,
  }
}
