import React from 'react'
import {useForm} from 'react-hook-form'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {SimpleBlog, useSimpleBlogUpdate} from 'Event/template/SimpleBlog'
import Settings from 'Event/template/SimpleBlog/Leaderboard/LeaderboardConfig/Settings'
import Styling from 'Event/template/SimpleBlog/Leaderboard/LeaderboardConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {useAddTranslation} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'

export default function LeaderboardConfig(props: ComponentConfigProps) {
  const {showing: isVisible, onClose} = props
  const {control, register, handleSubmit, formState} = useForm()
  const updateTemplate = useSimpleBlogUpdate()
  const addTranslation = useAddTranslation()

  const submit = (data: {
    title: string
    description: string
    backToDashboardText: string
    isVisible: boolean
    points_unit: SimpleBlog['points_unit']
    rewardAlert: SimpleBlog['rewardAlert']
  }) => {
    const title = replaceAtPath(data, 'title', '{{leaderboard_title}}')

    const backToDashboardText = replaceAtPath(
      data,
      'backToDashboardText',
      '{{leaderboard_back_to_dashboard_text}}',
    )

    const pointUnitTitle = replaceAtPath(
      data,
      'points_unit',
      '{{points_unit_text}}',
    )

    const description = replaceAtPath(
      data,
      'description',
      '{{leaderboard_description}}',
    )

    const {points_unit, rewardAlert, ...leaderboardData} = data

    updateTemplate({
      leaderboard: leaderboardData,
      points_unit: points_unit,
      rewardAlert: {
        ...rewardAlert,
        text: '{{leaderboard_reward_text}}',
      },
      localization: addTranslation({
        leaderboard_title: title ?? 'Leaderboard',
        leaderboard_back_to_dashboard_text:
          backToDashboardText ?? 'Back To Dashboard',
        points_unit: pointUnitTitle ?? 'Points',
        leaderboard_description: description ?? '',
        leaderboard_reward_text: rewardAlert?.text ?? '',
      }),
    })

    props.onClose()
  }

  return (
    <ComponentConfig
      showing={isVisible}
      onClose={onClose}
      title="Leaderboard"
      onSubmit={handleSubmit(submit)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} register={register} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}
