import React, {useState} from 'react'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  RemoveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import Settings from 'Event/template/NiftyFifty/Dashboard/Resources/ResourceList/ResourceItemConfig/Settings'
import {
  useNiftyFiftyTemplate,
  useNiftyFiftyUpdate,
} from 'Event/template/NiftyFifty'
import {Resource} from 'Event/template/NiftyFifty/Dashboard/Resources/ResourceList'
import {useDeleteFile} from 'Event/template/NiftyFifty/Dashboard/Resources/ResourceList/ResourceUpload'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import {
  useAddTranslation,
  useLanguages,
  useRemoveTranslations,
} from 'Event/LanguageProvider'
import {get as getAtPath} from 'lodash'
import {useUserTranslations} from 'Event/LanguageProvider/translations'

export default function ResourceItemConfig(
  props: ComponentConfigProps & {
    resource: Resource
    targetIndex?: number
  },
) {
  const {resource, targetIndex, showing, onClose} = props
  const template = useNiftyFiftyTemplate()
  const updateTemplate = useNiftyFiftyUpdate()
  const {resourceList: list} = template
  const deleteFile = useDeleteFile()
  const {handleSubmit, control, register, formState, setValue} = useForm()
  const [rules, setRules] = useState(resource.rules)
  const [isUrl, setIsUrl] = useState(resource.isUrl)
  const translate = useUserTranslations({disableDefaultFallback: true})
  const [filePath, setFilePath] = useState(translate(resource.filePath))
  const isEditing = targetIndex !== undefined
  const addTranslation = useAddTranslation()
  const removeTranslations = useRemoveTranslations()
  const languages = useLanguages()

  const insert = (resource: Resource) => {
    const lastId = list.resources.length
    return {
      localization: addTranslation({
        [`resouce_item_${lastId}_name`]: resource.name,
        [`resouce_item_${lastId}_url`]: resource.url || '',
        [`resouce_item_${lastId}_description`]: resource.description || '',
        [`resouce_item_${lastId}_filePath`]: resource.filePath || '',
      }),
      resourceList: {
        resources: [
          ...list.resources,
          {
            ...resource,
            name: `{{resouce_item_${lastId}_name}}`,
            description: `{{resouce_item_${lastId}_description}}`,
            url: `{{resouce_item_${lastId}_url}}`,
            filePath: `{{resouce_item_${lastId}_filePath}}`,
          },
        ],
      },
    }
  }

  const update = (resource: Resource) => {
    return {
      localization: addTranslation({
        [`resouce_item_${targetIndex}_name`]: resource.name,
        [`resouce_item_${targetIndex}_url`]: resource.url || '',
        [`resouce_item_${targetIndex}_description`]: resource.description || '',
        [`resouce_item_${targetIndex}_filePath`]: resource.filePath || '',
      }),
      resourceList: {
        resources: list.resources.map((r, index) => {
          const isTarget = index === targetIndex
          if (isTarget) {
            return {
              ...resource,
              name: `{{resouce_item_${targetIndex}_name}}`,
              description: `{{resouce_item_${targetIndex}_description}}`,
              url: `{{resouce_item_${targetIndex}_url}}`,
              filePath: `{{resouce_item_${targetIndex}_filePath}}`,
            }
          }

          return r
        }),
      },
    }
  }

  const save = (form: any) => {
    const data: Resource = {
      rules,
      isUrl,
      filePath,
      ...form,
    }

    const updated = targetIndex === undefined ? insert(data) : update(data)

    updateTemplate(updated)

    onClose()
  }

  const remove = () => {
    if (targetIndex === undefined) {
      throw new Error('Missing resource item index')
    }

    for (const language of languages) {
      const filePath = getAtPath(
        template,
        `localization.translations.${language.name}.resource_item_${targetIndex}_filePath`,
      )

      if (filePath) {
        deleteFile(filePath).catch((e) => {
          // Log error, but prevent it from crashing
          // app
          console.error(e)
        })
      }
    }

    onClose()

    updateTemplate({
      localization: removeTranslations([
        `resouce_item_${targetIndex}_name`,
        `resouce_item_${targetIndex}_description`,
        `resouce_item_${targetIndex}_filePath`,
      ]),
      resourceList: {
        resources: list.resources.filter(
          (_, index) => index !== props.targetIndex,
        ),
      },
    })
  }

  return (
    <ComponentConfig
      title="Resource"
      onClose={onClose}
      showing={showing}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings
          control={control}
          register={register}
          resource={resource}
          isUrl={isUrl}
          setIsUrl={setIsUrl}
          filePath={translate(filePath)}
          setFilePath={setFilePath}
        />
      </SettingsPanel>
      <RulesPanel rules={rules} setRules={setRules}>
        <ScheduleInput
          setValue={setValue}
          control={control}
          values={resource}
        />
      </RulesPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          aria-label="remove resource"
          onClick={remove}
          showing={isEditing}
        />
      </Footer>
    </ComponentConfig>
  )
}
