import Button from 'lib/ui/Button'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import TextField from 'lib/ui/TextField'
import {buildRoute} from 'lib/url'
import AssistantSettingsDeleteButton from 'organization/Obie/Assistants/AssistantSettingsDeleteButton'
import {AssistantSettingsFiles} from 'organization/Obie/Assistants/AssistantSettingsFiles'
import {useRouteAssistant} from 'lib/obie/use-assistant'
import {useUpdateAssistant} from 'lib/obie/use-update-assistant'
import Logo from 'organization/Obie/Logo'
import {useOrganization} from 'organization/OrganizationProvider'
import React from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'

export default function AssistantSettings() {
  const {data: assistant} = useRouteAssistant()
  const {register, handleSubmit} = useForm()
  const {routes} = useOrganization()

  const updateAssistant = useUpdateAssistant()

  const submit = (data: {name: string}) => {
    if (!assistant) {
      return
    }

    updateAssistant.mutateAsync({
      assistant,
      name: data.name,
    })
  }

  const backRoute =
    buildRoute(
      routes.obie.assistants[':assistant'].threads[':thread'].root,
      assistant?.id ?? '',
      assistant?.threads[0]?.id ?? '',
    ) ?? routes.obie.assistants.root // fallback in case assistant has no threads

  return (
    <PageContainer>
      <BackToChatLink to={backRoute} disabled={!assistant?.threads[0]?.id}>
        Back to chat
      </BackToChatLink>
      <Header>
        <StyledLogo /> Assistant Settings
      </Header>

      <Section>
        <Heading>Details</Heading>
        <p>Name</p>
        <form onSubmit={handleSubmit(submit)}>
          <StyledTextField
            name="name"
            fullWidth
            defaultValue={assistant?.name}
            inputProps={{
              ref: register,
              'aria-label': 'name',
            }}
            required
            disabled={updateAssistant.isLoading}
          />
          <StyledButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={updateAssistant.isLoading}
          >
            Save
          </StyledButton>
        </form>
      </Section>
      <Section>
        <Heading>Files</Heading>
        <AssistantSettingsFiles />
      </Section>
      <Section>
        <AssistantSettingsDeleteButton assistant={assistant} />
      </Section>
    </PageContainer>
  )
}

const PageContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  color: #fff;
`

const Header = styled.h1`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 36px;
  line-height: 64px;
  color: #ffffff;
  gap: 1rem;
`

const Section = styled.div`
  &:not(:last-child) {
    margin-bottom: 4rem;
  }
`

const StyledLogo = styled(Logo)`
  padding: 0;
`

const StyledTextField = styled(TextField)`
  color: #ffffff;

  input {
    background: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
`

const StyledButton = styled(Button)`
  margin-top: 1rem;
`

const Heading = styled.h2`
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
`

const BackToChatLink = styled(RelativeLink)`
  color: #ffffff;
`
