import {useOrganization} from 'organization/OrganizationProvider'
import {useMutation, useQueryClient} from 'react-query'
import {Assistant, AssistantThread} from 'lib/obie/use-assistant'
import {obieApi} from './url'
import {useOrganizationObieClient} from './use-organization-obie-client'

export function useCreateAssistantThread() {
  const {organization} = useOrganization()
  const {client} = useOrganizationObieClient()
  const qc = useQueryClient()

  return useMutation({
    mutationFn: async (assistant: Assistant) => {
      const url = obieApi(
        `/organizations/${organization.id}/assistants/${assistant.id}/threads`,
      )

      return client!.post<AssistantThread>(url)
    },
    onSuccess: (_, assistant) => {
      qc.invalidateQueries({
        queryKey: [
          'organizations',
          organization.id,
          'assistants',
          assistant.id,
        ],
      })
    },
  })
}
