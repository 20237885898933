import {obieApi} from 'lib/obie/url'
import {useOrganizationObieClient} from 'lib/obie/use-organization-obie-client'
import {useOrganization} from 'organization/OrganizationProvider'
import {useMutation, useQueryClient} from 'react-query'

export function useDeleteAssistantThread() {
  const {client} = useOrganizationObieClient()
  const qc = useQueryClient()
  const {organization} = useOrganization()

  return useMutation({
    mutationFn: (data: {id: number; assistant_id: number}) => {
      const {id} = data

      return client!.delete(
        obieApi(`/organizations/${organization.id}/assistant_threads/${id}`),
      )
    },
    onSuccess: (_, data) => {
      qc.invalidateQueries([
        'organizations',
        organization.id,
        'assistant_threads',
      ])

      qc.invalidateQueries([
        'organizations',
        organization.id,
        'assistants',
        data.assistant_id,
      ])
    },
  })
}
