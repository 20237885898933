import React, {useState} from 'react'
import Page from 'organization/Event/Page'
import SuccessAlert from 'lib/ui/alerts/SuccessAlert'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import StatusSelect from 'organization/Event/ImageEntries/StatusSelect'
import {SectionTitle} from 'organization/Event/Page'
import ImageWaterfallConfig from 'organization/Event/ImageEntries/ImageWaterfallConfig'
import Grid from '@material-ui/core/Grid'
import {useImageEntries} from 'organization/Event/ImageEntriesProvider'
import ImageEntryCard from 'organization/Event/ImageEntries/ImageEntryCard'
import PageLimitSelect from 'organization/Event/ImageEntries/PageLimitSelect'
import Pagination from 'organization/Event/ImageEntries/Pagination'
import {useToggle} from 'lib/toggle'
import SelectedImage from 'Event/ImageWaterfall/SelectedImage'
import {ImageEntry} from 'organization/Event/ImageEntriesProvider'
import ExportButton from './ExportButton'
import styled from 'styled-components'
import Button from 'lib/ui/Button'
import {FeaturesBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import DeleteAllImagesButton from 'organization/Event/ImageEntries/DeleteAllImagesButton'

export default function ImageEntries() {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)

  const {
    flag: showingWaterfallConfig,
    toggle: toggleWaterfallConfig,
  } = useToggle()

  return (
    <>
      <ImageWaterfallConfig
        isVisible={showingWaterfallConfig}
        onClose={toggleWaterfallConfig}
      />
      <FeaturesBreadcrumbs page="Image Entries">
        <Page>
          <SectionTitle>Image Entries</SectionTitle>
          <ButtonsContainer>
            <LeftButtonsContainer>
              <SettingsButton
                variant="outlined"
                color="primary"
                onClick={toggleWaterfallConfig}
                aria-label="image waterfall settings"
              >
                Image Waterfall Settings
              </SettingsButton>
              <ExportButton
                onError={setErrorMessage}
                onSuccess={setSuccessMessage}
              />
            </LeftButtonsContainer>
            <RightButtonsContainer>
              <DeleteAllImagesButton
                onError={setErrorMessage}
                onSuccess={setSuccessMessage}
              />
            </RightButtonsContainer>
          </ButtonsContainer>
          <ErrorAlert onClose={() => setErrorMessage(null)}>
            {errorMessage}
          </ErrorAlert>
          <SuccessAlert onClose={() => setSuccessMessage(null)}>
            {successMessage}
          </SuccessAlert>
          <StatusSelect />
          <Entries />
          <Grid container justify="center">
            <PageLimitSelect />
            <Pagination />
          </Grid>
        </Page>
      </FeaturesBreadcrumbs>
    </>
  )
}

function Entries() {
  const {entries, attendeeNames} = useImageEntries()

  const [selected, setSelected] = useState<ImageEntry | null>(null)

  const isEmpty = entries.length === 0
  if (isEmpty) {
    return <div>No Image Entries Available</div>
  }

  return (
    <Grid container wrap="wrap">
      {entries.map((entry) => (
        <ImageEntryCard
          key={entry.id}
          entry={entry}
          attendeeName={attendeeNames[entry.attendee_id]}
          setSelected={setSelected}
        />
      ))}
      <SelectedImage
        appBarPresent
        selected={selected}
        onClose={() => setSelected(null)}
      />
    </Grid>
  )
}

const ButtonsContainer = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[4]};
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const LeftButtonsContainer = styled.div`
  display: flex;
`

const RightButtonsContainer = styled.div`
  display: flex;
`

const SettingsButton = styled(Button)`
  margin-right: ${(props) => props.theme.spacing[2]};
`
