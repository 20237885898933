import React from 'react'
import {useForm} from 'react-hook-form'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {usePanelsUpdate} from 'Event/template/Panels'
import Settings from 'Event/template/Panels/Leaderboard/LeaderboardConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {useAddTranslation} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'

export default function LeaderboardConfig(props: ComponentConfigProps) {
  const {showing: isVisible, onClose} = props
  const {control, register, handleSubmit, formState} = useForm()
  const updateTemplate = usePanelsUpdate()
  const addTranslation = useAddTranslation()

  const submit = (data: {
    title: string
    description: string
    points_unit: string
    menuTitle: string
    isVisible: boolean
  }) => {
    const title = replaceAtPath(data, 'title', '{{leaderboard_title}}')

    const menuTitle = replaceAtPath(
      data,
      'menuTitle',
      '{{leaderboard_menu_title}}',
    )

    const pointUnitTitle = replaceAtPath(
      data,
      'points_unit',
      '{{points_unit_text}',
    )

    const description = replaceAtPath(
      data,
      'description',
      '{{leaderboard_description}}',
    )

    const {points_unit, ...leaderboardData} = data

    updateTemplate({
      leaderboard: leaderboardData,
      points_unit: points_unit,
      localization: addTranslation({
        leaderboard_title: title ?? 'Leaderboard',
        leaderboard_menu_title: menuTitle ?? 'Points',
        points_unit: pointUnitTitle ?? 'Points',
        leaderboard_description: description ?? '',
      }),
    })

    props.onClose()
  }

  return (
    <ComponentConfig
      showing={isVisible}
      onClose={onClose}
      title="Leaderboard"
      onSubmit={handleSubmit(submit)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} register={register} />
      </SettingsPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}
