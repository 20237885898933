import {buildRoute} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import {useMutation, useQueryClient} from 'react-query'
import {Assistant} from 'lib/obie/use-assistant'
import {useHistory} from 'react-router'
import {useCreateAssistantThread} from 'lib/obie/use-create-assistant-thread'
import {obieApi} from './url'
import {useOrganizationObieClient} from './use-organization-obie-client'

export function useCreateAssistant() {
  const {organization, routes} = useOrganization()
  const {client} = useOrganizationObieClient()
  const qc = useQueryClient()
  const history = useHistory()

  const createAssistantThread = useCreateAssistantThread()

  return useMutation({
    mutationFn: async (data: {
      assistantTemplateId: number
      name: string
      instructions: string
    }) => {
      const {assistantTemplateId, name, instructions} = data
      const url = obieApi(`/organizations/${organization.id}/assistants`)

      const assistant = await client!.post<Assistant>(url, {
        assistant_template_id: assistantTemplateId,
        name,
        instructions,
      })

      const thread = await createAssistantThread.mutateAsync(assistant, {
        onSuccess: () => {
          // ignore on success redirect
        },
      })

      return {assistant, thread}
    },
    onSuccess: ({assistant, thread}) => {
      qc.invalidateQueries({
        queryKey: ['organizations', organization.id, 'assistants'],
      })

      history.push(
        buildRoute(
          routes.obie.assistants[':assistant'].threads[':thread'].root,
          assistant.id,
          thread.id,
        ),
      )
    },
  })
}
