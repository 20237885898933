import Button from 'lib/ui/Button'
import React from 'react'
import styled from 'styled-components'
import {JsonSave} from 'lib/JsonUpdateProvider'
import {PageTemplate} from 'Event/Marketplace/page-template'
import ConfirmDialog from 'lib/ui/ConfirmDialog'

export default function ClearTemplateButton(props: {
  updateTemplate: JsonSave<PageTemplate>
}) {
  const clearTemplate = () => {
    props.updateTemplate({
      sections: {},
    })
  }

  return (
    <ConfirmDialog
      onConfirm={clearTemplate}
      confirmLabel={'Confirm'}
      description={'Would you like to clear this page?'}
    >
      {(confirm: (event: React.MouseEvent<HTMLButtonElement>) => void) => (
        <StyledButton
          variant="contained"
          color="danger"
          size="small"
          aria-label="clear template button"
          onClick={confirm}
        >
          Clear template
        </StyledButton>
      )}
    </ConfirmDialog>
  )
}

const StyledButton = styled(Button)`
  margin-right: 15px;
`
