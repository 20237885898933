import React from 'react'
import styled from 'styled-components'
import Markdown from 'react-markdown'
import {ThreadMessage} from 'lib/obie/use-assistant-thread'

interface ThreadMessagesProps {
  messages: ThreadMessage[]
}

export default function ThreadMessages(props: ThreadMessagesProps) {
  const {messages} = props

  return (
    <Box>
      {messages.map((message, index) => (
        <MessageContent key={index} isUser={message.type === 'user'}>
          {message.content}
        </MessageContent>
      ))}
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  overflow-y: auto;
`

const MessageContent = styled(Markdown)<{isUser?: boolean}>`
  padding: 20px;
  margin: 20px;
  max-width: 760px;
  border-radius: 16px;
  background: ${(props) => (props.isUser ? '#1a2847' : '#050f27')};
  color: #fff;
  align-self: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
`
