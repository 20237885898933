import styled from 'styled-components'
import React from 'react'
import grey from '@material-ui/core/colors/grey'
import RightItems from 'organization/Event/TemplateEditor/RightItems'
import LanguageSelector from 'organization/Event/LocalizationConfig/LanguageSelector'

export default function ConfigBar(props: {
  toolbarItems?: JSX.Element | JSX.Element[]
  rightToolbarItems?: JSX.Element
  className?: string
}) {
  const {rightToolbarItems = <></>} = props
  return (
    <Box className={props.className}>
      <LeftItems>
        <LanguageSelector />
        {props.toolbarItems}
      </LeftItems>
      <RightItems>{rightToolbarItems}</RightItems>
    </Box>
  )
}

const Box = styled.div`
  position: sticky;
  top: 0;
  height: 50px;
  border-bottom: 1px solid ${grey[300]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${(props) => props.theme.spacing[4]};
  background: #ffffff;
  z-index: 50;
`

const LeftItems = styled.div`
  display: flex;
  align-items: center;
`
