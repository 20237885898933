import React from 'react'
import styled from 'styled-components'
import Logo from 'organization/Obie/Logo'
import {useAssistantTemplates} from 'lib/obie/use-assistant-templates'
import Skeleton from '@material-ui/lab/Skeleton'
import {useAssistantList} from 'lib/obie/use-assistant-list'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {buildRoute} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'

export default function AssistantsListPage() {
  const {routes} = useOrganization()
  return (
    <PageContainer>
      <BackLink to={routes.obie.root}>Back to Obie</BackLink>
      <Header>
        {' '}
        <StyledLogo /> Assistants
      </Header>
      <Content />
    </PageContainer>
  )
}

function Content() {
  const {data: assistantTemplates} = useAssistantTemplates()
  const {data: assistants} = useAssistantList()
  const {routes} = useOrganization()

  if (!assistantTemplates || !assistants) {
    return <Loader />
  }

  return (
    <>
      <Section>
        <SectionTitle>My Assistants</SectionTitle>
        <Grid>
          {assistants.length > 0 ? (
            assistants.map((assistant) => (
              <RelativeLink
                key={assistant.id}
                to={buildRoute(
                  routes.obie.assistants[':assistant'].threads[':thread'].root,
                  assistant.id,
                  assistant.threads[0]?.id,
                )}
                disableStyles
                disabled={!assistant.threads[0]?.id}
              >
                <Card>{assistant.name}</Card>
              </RelativeLink>
            ))
          ) : (
            <p>No assistants found</p>
          )}
        </Grid>
      </Section>

      <Section>
        <SectionTitle>Create a New Assistant</SectionTitle>
        <Grid>
          {assistantTemplates.map((template) => (
            <RelativeLink
              to={`${routes.obie.assistants.create}?assistant_template_id=${template.id}`}
              disableStyles
              key={template.id}
            >
              <Card>{template.default_name}</Card>
            </RelativeLink>
          ))}
        </Grid>
      </Section>
    </>
  )
}

function Loader() {
  return (
    <>
      <Section>
        <SectionTitle>My Assistants</SectionTitle>
        <Grid>
          {[1].map((i) => (
            <Card key={i}>
              <Skeleton
                height={24}
                style={{backgroundColor: 'rgba(255, 255, 255, 0.1)'}}
              />
            </Card>
          ))}
        </Grid>
      </Section>

      <Section>
        <SectionTitle>Create a New Assistant</SectionTitle>
        <Grid>
          {[1, 2].map((i) => (
            <Card key={i}>
              <Skeleton
                height={24}
                style={{backgroundColor: 'rgba(255, 255, 255, 0.1)'}}
              />
            </Card>
          ))}
        </Grid>
      </Section>
    </>
  )
}

const PageContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  color: #fff;
`

const Header = styled.h1`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 36px;
  line-height: 64px;
  color: #ffffff;
  gap: 1rem;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
`

const Card = styled.div<{disabled?: boolean}>`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 1.5rem;
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
  transition: transform 0.2s;
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};

  &:hover {
    transform: ${({disabled}) => (disabled ? 'none' : 'translateY(-2px)')};
  }
`

const Section = styled.div`
  margin-bottom: 2rem;
`

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`

const StyledLogo = styled(Logo)`
  padding: 0;
`

const BackLink = styled(RelativeLink)`
  color: #fff;
`
