import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import React from 'react'
import {useForm} from 'react-hook-form'
import {createGlobalStyle} from 'styled-components'

export default function AccessTeamPage(props: {
  title: string
  description?: JSX.Element
  error: string
  processing: boolean
  submitLabel: string
  onSubmit: (form: {id: string}) => void
}) {
  const {
    register,
    handleSubmit,
    watch,
    errors: {id: idError},
  } = useForm()
  const {description = ''} = props

  const id = watch('id')
  const canSubmit = Boolean(id) && !props.processing

  return (
    <>
      <RootStyle />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '600px',
          marginTop: '60px',
          marginBottom: '60px',
        }}
      >
        <Box mb={2} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h4" align="center">
            {props.title}
          </Typography>
          <Typography
            variant="body1"
            align="center"
            style={{
              whiteSpace: 'pre-wrap',
              marginTop: '20px',
              marginBottom: '20px',
              maxWidth: '300px',
              textAlign: 'justify',
            }}
          >
            {description}
          </Typography>
        </Box>
        <Container maxWidth="lg">
          <ErrorAlert>{props.error}</ErrorAlert>
          <form onSubmit={handleSubmit(props.onSubmit)}>
            <TextField
              label="Short Code"
              inputProps={{
                ref: register({
                  pattern: {
                    value: /^[a-z0-9-]{1,16}$/,
                    message: 'Lowercase letters, numbers, and hyphens only.',
                  },
                  maxLength: {
                    value: 16,
                    message: 'IDs must be less than 16 characters.',
                  },
                }),
                'aria-label': 'id',
              }}
              name="id"
              fullWidth
              variant="outlined"
              disabled={props.processing}
              error={Boolean(idError)}
              helperText={idError && idError.message}
            />
            <Button
              disabled={!canSubmit}
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              type="submit"
            >
              {props.submitLabel}
            </Button>
          </form>
        </Container>
      </div>
    </>
  )
}

const RootStyle = createGlobalStyle`
  #root {
  display: flex;
      align-items: center;
      justify-content: center;
}

`
