import {CountDownTimerSettings} from 'Event/Dashboard/components/CountDownTimer'
import {onChangeCheckedHandler} from 'lib/dom'
import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {useAttendeeVariables} from 'Event'

export default function Settings(props: {
  control: UseFormMethods['control']
  countDownTimer: CountDownTimerSettings
}) {
  const {control, countDownTimer} = props

  const v = useAttendeeVariables()

  return (
    <>
      <Controller
        name="enabled"
        control={control}
        defaultValue={countDownTimer.enabled}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="toggle countdown timer"
          />
        )}
      />
      <Controller
        name="end"
        control={control}
        defaultValue={countDownTimer.end}
        render={({onChange, value}) => (
          <div>
            <Label>End</Label>
            <LocalizedDateTimePicker
              value={value}
              onChange={(date) => onChange(date?.toISOString())}
              fullWidth
              inputProps={{
                'aria-label': 'start time',
                onChange,
              }}
            />
          </div>
        )}
      />
      <Controller
        name="labels.days"
        control={control}
        defaultValue={v(countDownTimer.labels.days)}
        render={({value, onChange}) => (
          <TextField
            aria-label="days label input"
            fullWidth
            onChange={onChange}
            value={value}
            label="Days Label"
          />
        )}
      />
      <Controller
        name="labels.hours"
        control={control}
        defaultValue={v(countDownTimer.labels.hours)}
        render={({value, onChange}) => (
          <TextField
            aria-label="hours label input"
            fullWidth
            onChange={onChange}
            value={value}
            label="Hours Label"
          />
        )}
      />
      <Controller
        name="labels.minutes"
        control={control}
        defaultValue={v(countDownTimer.labels.minutes)}
        render={({value, onChange}) => (
          <TextField
            aria-label="minutes label input"
            fullWidth
            onChange={onChange}
            value={value}
            label="Minutes Label"
          />
        )}
      />
      <Controller
        name="labels.seconds"
        control={control}
        defaultValue={v(countDownTimer.labels.seconds)}
        render={({value, onChange}) => (
          <TextField
            aria-label="seconds label input"
            fullWidth
            onChange={onChange}
            value={value}
            label="Seconds Label"
          />
        )}
      />

      <Label>Description</Label>
      <Controller
        name="description"
        control={control}
        defaultValue={v(countDownTimer.description)}
        render={({value, onChange}) => (
          <TextField
            aria-label="description input"
            fullWidth
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Label>Separator</Label>
      <Controller
        name="separator"
        control={control}
        defaultValue={v(countDownTimer.separator)}
        render={({value, onChange}) => (
          <TextField
            aria-label="separator input"
            fullWidth
            onChange={onChange}
            value={value}
          />
        )}
      />
    </>
  )
}
