import styled from 'styled-components'
import React from 'react'
import {useLayout} from 'organization/Obie/Layout'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {useOrganization} from 'organization/OrganizationProvider'
import {ArrowRightFromBracket, CategoriesIcon, PeopleIcon} from 'lib/ui/Icon'
import OrganizationFeatureToggle from 'organization/OrganizationFeatureToggle'
import {OBIE_ASSISTANTS} from 'auth/user/flags'

export default function Sidebar(props: {
  className?: string
  hideLinks?: boolean
}) {
  const {sidebarExpanded} = useLayout()
  const {routes} = useOrganization()

  return (
    <Box expanded={sidebarExpanded} className={props.className}>
      <Links>
        <SidebarLinks hideLinks={props.hideLinks} />
      </Links>
      <Bottom expanded={sidebarExpanded}>
        <Links>
          <SidebarLink to={routes.root}>
            <Content highlight={false} showingTitle clickable bold={false}>
              <StyledArrowRightFromBracket />
              <span>Back To Obvio</span>
            </Content>
          </SidebarLink>
        </Links>
      </Bottom>
    </Box>
  )
}

const SidebarLinks = (props: {hideLinks?: boolean}) => {
  const {routes} = useOrganization()

  if (props.hideLinks) {
    return null
  }

  return (
    <SidebarLink to={routes.obie.root}>
      <MenuItem>
        <CategoriesIcon />
        <span>Blocks</span>
      </MenuItem>
      <OrganizationFeatureToggle flags={OBIE_ASSISTANTS}>
        <RelativeLink to={routes.obie.assistants.root} disableStyles>
          <MenuItem>
            <PeopleIcon />
            <span>Assistants</span>
          </MenuItem>
        </RelativeLink>
      </OrganizationFeatureToggle>
    </SidebarLink>
  )
}

const Box = styled.div<{expanded: boolean}>`
  background: ${(props) => props.theme.colors.sidebar.background};
  color: #ffffff;
  flex-direction: column;
  z-index: 2;
  display: none;
  position: relative;

  width: ${(props) =>
    props.expanded ? props.theme.spacing[58] : props.theme.spacing[12]};

  // Drawer has flex column, so we need flex 1 to take full height.
  flex: 1;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    flex: 0 0
      ${(props) =>
        props.expanded ? props.theme.spacing[58] : props.theme.spacing[12]};

    display: flex;
    height: 100vh;
  }
`

const Links = styled.div`
  position: sticky;
  top: 0;
  font-size: 14px;
  line-height: 14px;
  color: #e7e8eb;
  max-height: 100vh;
  overflow-y: auto;

  padding-top: ${(props) => props.theme.spacing[5]};
  padding-bottom: ${(props) => props.theme.spacing[5]};
  padding-left: ${(props) => props.theme.spacing[2]};
  padding-right: ${(props) => props.theme.spacing[2]};
`

const Bottom = styled.div<{expanded: boolean}>`
  position: fixed;
  bottom: 0;

  width: ${(props) =>
    props.expanded ? props.theme.spacing[58] : props.theme.spacing[12]};
  * {
    width: 100%;
  }
`

const SidebarLink = styled(RelativeLink)`
  display: block;
  color: #ffffff;
  padding-top: ${(props) => props.theme.spacing[2]};
  padding-bottom: ${(props) => props.theme.spacing[2]};
  border-radius: 3px;
  margin: ${(props) => props.theme.spacing[2]} 0;

  background-color: transparent;
  &:hover {
    text-decoration: none !important;
  }
`

const Content = styled.div<{
  showingTitle: boolean
  highlight: boolean
  bold: boolean
  clickable: boolean
}>`
  display: flex;
  align-items: center;
  padding-top: ${(props) => props.theme.spacing[2]};
  padding-bottom: ${(props) => props.theme.spacing[2]};
  border-radius: 3px;
  padding-left: ${(props) => (props.showingTitle ? props.theme.spacing[8] : 0)};
  justify-content: ${(props) => (props.showingTitle ? 'start' : 'center')};
  cursor: pointer;
  i {
    font-size: 18px;
    width: 18px;
    text-align: center;
    margin-right: ${(props) =>
      props.showingTitle ? props.theme.spacing[3] : 0};
  }

  span {
    display: ${(props) => (props.showingTitle ? 'inline' : 'none')};
    ${(props) => (props.bold ? 'font-weight: bold;' : '')}
  }
`

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${(props) => props.theme.spacing[2]};
  padding-bottom: ${(props) => props.theme.spacing[2]};
  border-radius: 3px;
  padding-left: ${(props) => props.theme.spacing[8]};
  justify-content: start;
  cursor: pointer;

  &:hover {
    background: transparent;
  }

  background: transparent;

  i {
    font-size: 18px;
    width: 18px;
    text-align: center;
    margin-right: ${(props) => props.theme.spacing[3]};
  }

  span {
    display: inline;
  }
`

// As all.min.css for fontawesome doesn't have the left from bracket icon. Will update the min.css later.
const StyledArrowRightFromBracket = styled(ArrowRightFromBracket)`
  transform: rotate(180deg);
`
