import React from 'react'
import TypographySection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/TypographySection'
import BackgroundSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/BackgroundSection'
import BorderSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/BorderSection'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useAttendeeVariables} from 'Event'
import {useAddTranslation} from 'Event/LanguageProvider'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import ContentInput from 'Event/Dashboard/components/NavButton/NavButtonConfig/ContentInput'
import Config from 'organization/Event/Configurable/Config'
import {useConfig} from 'organization/Event/Configurable'

export function LanguageSelectorConfig() {
  const {
    form: {control, register, watch},
  } = useConfig()

  const {languageSelectorButton: button} = useTemplate()
  const saveTemplate = useSaveTemplate()
  const addTranslation = useAddTranslation()

  const v = useAttendeeVariables()

  const handleSave = (data: any) => {
    saveTemplate({
      localization: addTranslation({
        language_selector_text: data.text,
      }),
      languageSelectorButton: {
        ...data,
        text: `{{language_selector_text}}`,
      },
    })
  }

  return (
    <Config title="Language Selector" onSave={handleSave}>
      <SettingsPanel>
        <ContentInput
          button={{
            ...button,
            text: v(button.text),
          }}
          control={control}
          register={register}
          watch={watch}
        />
      </SettingsPanel>
      <StylingPanel>
        <TypographySection
          button={button}
          control={control}
          storageKey="change.language.selector.config.styling.typography"
        />
        <BackgroundSection
          button={button}
          control={control}
          watch={watch}
          storageKey="change.language.selector.config.styling.background"
        />
        <BorderSection
          button={button}
          control={control}
          storageKey="change.language.selector.config.styling.border"
        />
      </StylingPanel>
    </Config>
  )
}
