import TextField from 'lib/ui/TextField'
import TextArea from 'lib/ui/TextField/TextArea'
import Select from 'lib/ui/Select'
import MenuItem from 'lib/ui/Select/Option'
import {Label} from 'lib/ui/typography'
import {onUnknownChangeHandler} from 'lib/dom'
import {fieldError} from 'lib/form'
import ValidationSelect from 'organization/Event/Form/dialog/Form/ValidationSelect'
import {Question} from 'organization/Event/QuestionsProvider'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {SHORT_ANSWER_TEXT} from 'Event/Question/ShortAnswerText'
import {LONG_ANSWER_TEXT} from 'Event/Question/LongAnswerText'
import {RADIO} from 'Event/Question/Radio'
import {SELECT} from 'Event/Question/Select'
import {CHECKBOX} from 'Event/Question/Checkbox'
import {CURRENCY} from 'Event/Question/Currency'
import {SELECT_PRIORITY} from 'Event/Question/SelectPriority'
import QuestionTypeConfig from 'organization/Event/Form/dialog/Form/QuestionTypeConfig'
import OptionsInput from 'organization/Event/Form/dialog/Form/OptionsInput'
import Switch from 'lib/ui/form/Switch'
import ExportSubmission from 'organization/Event/Form/dialog/Form/ExportSubmission'
import {useAttendeeVariables} from 'Event'

const TYPE_SELECT_ID = 'question-type-select'

export default function Settings(props: {
  question?: Question
  watch: UseFormMethods['watch']
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  errors: UseFormMethods['errors']
}) {
  const {question, watch, control, register, errors} = props
  const v = useAttendeeVariables()
  const selectedType = watch('type') as Question['type']

  const error = (field: keyof Question) => {
    const value = fieldError(field, {
      form: errors,
      response: null,
    })

    return {
      exists: Boolean(value),
      value,
    }
  }

  return (
    <>
      <ExportSubmission question={question} />
      <TextField
        label="Label"
        fullWidth
        variant="outlined"
        required
        name="label"
        defaultValue={v(question?.label || '')}
        inputProps={{
          ref: register({required: 'Label is required'}),
          'aria-label': 'question label',
        }}
        error={error('label').exists}
        helperText={error('label').value}
      />
      <Controller
        name="type"
        defaultValue={question?.type || ''}
        control={control}
        render={({onChange, value}) => (
          <Select
            label="Type"
            id={TYPE_SELECT_ID}
            value={value}
            fullWidth
            onChange={onUnknownChangeHandler(onChange)}
            variant="outlined"
            aria-label="question type"
            error={error('type').exists}
            helperText={error('type').value}
          >
            <MenuItem value={SHORT_ANSWER_TEXT}>Short Answer Text</MenuItem>
            <MenuItem value={LONG_ANSWER_TEXT}>Long Answer Text</MenuItem>
            <MenuItem value={RADIO}>Radio</MenuItem>
            <MenuItem value={SELECT}>Select</MenuItem>
            <MenuItem value={CHECKBOX}>Checkbox</MenuItem>
            <MenuItem value={CURRENCY}>Currency</MenuItem>
            <MenuItem value={SELECT_PRIORITY}>Select Priority</MenuItem>
          </Select>
        )}
      />
      <QuestionTypeConfig
        control={control}
        questionType={selectedType}
        register={register}
        question={question}
        errors={errors}
      />
      <OptionsInput
        control={control}
        questionType={selectedType}
        register={register}
        question={question}
        errors={errors}
      />
      <Label>Helper Text</Label>
      <TextArea
        label="Helper Text"
        fullWidth
        variant="outlined"
        name="helper_text"
        defaultValue={v(question?.helper_text || '')}
        inputProps={{
          ref: register,
          'aria-label': 'helper text',
        }}
        rows={4}
        error={error('helper_text').exists}
        helperText={error('helper_text').value}
      />
      <ValidationSelect
        type={selectedType}
        control={control}
        question={props.question}
      />
      <Controller
        name="is_required"
        defaultValue={
          question?.is_required === undefined ? true : question?.is_required
        }
        control={control}
        render={({onChange, value}) => (
          <Switch
            checked={value}
            onChange={(e) => onChange(e.target.checked)}
            labelPlacement="end"
            aria-label="is optional"
            label={value ? 'Question is Required' : 'Question is Optional'}
          />
        )}
      />
    </>
  )
}
