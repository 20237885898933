import ConfirmUnsavedChangesDialog from 'organization/Event/Configurable/ConfirmUnsavedChangesDialog'
import React from 'react'
import {useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'

interface ConfirmChangesDialogProps {
  hasChanges: boolean
  onSave: () => void
}

export default function ConfirmUnsavedFormChangesDialog(
  props: ConfirmChangesDialogProps,
) {
  const {hasChanges, onSave} = props
  const [nextRoute, setNextRoute] = useState<null | string>(null)
  const [showing, setShowing] = useState(false)
  const history = useHistory()
  const unblockRef = useRef<null | (() => void)>(null)
  // Blocking for unsaved changes
  useEffect(() => {
    // Have to use 'any' type here because history.block's type
    // seems to be incorrect for this version, and is returning
    // locationState directly.
    unblockRef.current = history.block((location: any) => {
      if (!hasChanges || !location.pathname) {
        setNextRoute(null)
        return true
      }

      setNextRoute(location.pathname)
      setShowing(true)
      return false // prevent nav
    })

    return () => {
      // Call unblock on unmount
      if (unblockRef.current) {
        unblockRef.current()
      }
    }
  }, [history, hasChanges])

  const onClose = () => {
    setShowing(false)
    if (unblockRef.current) {
      unblockRef.current()
    }

    if (nextRoute) {
      history.push(nextRoute)
    }
  }

  const handleConfirm = () => {
    if (unblockRef.current) {
      unblockRef.current()
    }

    onSave()

    setShowing(false)
    if (nextRoute) {
      history.push(nextRoute)
    }
  }

  return (
    <ConfirmUnsavedChangesDialog
      open={showing}
      onDiscard={onClose}
      onCancel={onClose}
      onSubmit={handleConfirm}
    />
  )
}
