import React, {useCallback, useEffect, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useHistory} from 'react-router-dom'
import Markdown from 'marked-react'
import styled from 'styled-components'
import MuiTextField from '@material-ui/core/TextField'
import Button from 'lib/ui/Button'
import MarkdownEditor from 'lib/ui/form/TextEditor/MarkdownEditor'
import {PlusIcon} from 'lib/ui/Icon'
import {routesWithValue, useQueryParams} from 'lib/url'
import BlockName, {
  useUncompletedDependencies,
} from 'organization/Obie/Blocks/BlockName'
import CompletionMenu from 'organization/Obie/Blocks/ProcessForm/CompletionMenu'
import {profileRoute} from 'organization/Obie/ObieRoutes'
import {pagRoute} from 'organization/Obie/PagRoutes'
import {
  AnswerSet,
  Block,
  Completion,
  OBIE_NEW_LINE,
  OBIE_OPTIONS_LIST,
  resolveOptions,
  useObieService,
} from 'organization/Obie/ObieServiceProvider'
import {useOrganization} from 'organization/OrganizationProvider'

export default function BlockListItem(props: {block: Block}) {
  const {block} = props
  const {blockId: block_id, sessionId: session_id} = useQueryParams()

  const {
    completions: allCompletions,
    profileId,
    setCompletion,
  } = useObieService()
  const [selectedAnswerSet, setSelectedAnswerSet] = useState<AnswerSet>()
  const {routes} = useOrganization()
  const history = useHistory()
  const [completions, setCompletions] = useState<Completion[]>([])
  const [editing, setEditing] = useState<boolean>(false)
  const answerSets = block.answer_sets

  const startProcess = useCallback(
    (profileId: number | undefined, formData?: any) => {
      if (profileId === undefined) {
        return
      }

      const pRoute = profileRoute(
        routes,
        String(block.category.id),
        String(profileId),
      )

      const processRoute = routesWithValue(
        ':block',
        String(block.id),
        pRoute.blocks[':block'],
      )

      const queryParams = new URLSearchParams()

      if (session_id) {
        queryParams.append('sessionId', session_id)
      }

      if (formData) {
        queryParams.append('dependencies', JSON.stringify(formData))
      }

      setCompletion()

      history.push(`${processRoute.process}?${queryParams.toString()}`)
    },
    [routes, block, session_id, setCompletion, history],
  )

  const handleCreate = useCallback(() => {
    // Short-circuit for now, ultimately there is no check for dependencies at
    // the start, this is moved/ing to the step between form questions and the
    // completion request.
    startProcess(profileId)
  }, [startProcess, profileId])

  const onClickName = () => {
    if (selectedAnswerSet) {
      setSelectedAnswerSet(undefined)
    } else if (completions.length > 0) {
      setSelectedAnswerSet(answerSets[0])
    }
  }

  const onCollapse = () => {
    setSelectedAnswerSet(undefined)
  }

  useEffect(() => {
    const updated = allCompletions.filter(
      (completion) =>
        answerSets.filter(
          (answerSet) =>
            String(answerSet.id) === String(completion.answer_set_id),
        ).length > 0,
    )
    setCompletions(updated)
  }, [allCompletions, answerSets])

  useEffect(() => {
    if (!selectedAnswerSet) {
      return
    }

    const updated = answerSets.filter(
      (answerSet) => answerSet.id === selectedAnswerSet.id,
    )[0]
    setSelectedAnswerSet(updated)
  }, [answerSets, selectedAnswerSet])

  // Checking if we have a block_id AND session_id in the query string parameters.
  // When we do, this means that a Completion is being triggered from a our
  // PAG Builder and we need to just start the process, we don't want to show
  // anything. However, there may be dependencies on the Block/Completion being
  // triggered... so we have to go through this process.
  useEffect(() => {
    if (!block_id && !session_id) {
      return
    }

    // When the blockId from the query string is matching THIS Block's ID, we
    // trigger the create. We're checking a local state to tell us whether we
    // SHOULD auto-trigger it. In the scenario where there is a dependency
    // selection needed, and user has clicked close/cancel on the selector, we
    // don't want them in an endless loop of selecting dependencies.
    if (Number(block_id) === block.id) {
      return handleCreate()
    }
  }, [block.id, block_id, session_id, handleCreate])

  // When the Block has a pag_session_type, we don't want to show the Block in
  // the listing of Blocks a user could start a Completion for. We DO need the
  // block in the result set for the full process to work, so we can't exclude
  // these types in the result set coming back from OBIE.
  const isHidden = Boolean(block.pag_session_type)

  return (
    <Container isHidden={isHidden}>
      <Header>
        <BlockName
          block={block}
          showing={!selectedAnswerSet}
          onClick={onClickName}
        />
        <Completions
          selected={selectedAnswerSet}
          answerSets={answerSets}
          selectAnswerSet={setSelectedAnswerSet}
          setEditing={setEditing}
        />
        <CreateCompletionButton
          block={block}
          showing={!selectedAnswerSet}
          onCreate={handleCreate}
        />
        <CompletionMenu
          answerSet={selectedAnswerSet}
          onCollapse={onCollapse}
          setEditing={setEditing}
          showing={!!selectedAnswerSet}
          block={block}
        />
      </Header>
      <MobileHeader selected={!!selectedAnswerSet}>
        <BlockName
          block={block}
          showing={!selectedAnswerSet}
          onClick={onClickName}
        />
        <CreateCompletionButton
          block={block}
          showing={!selectedAnswerSet}
          onCreate={handleCreate}
        />
        <CompletionMenu
          answerSet={selectedAnswerSet}
          onCollapse={() => setSelectedAnswerSet(undefined)}
          setEditing={setEditing}
          showing={!!selectedAnswerSet}
          block={block}
        />
      </MobileHeader>
      <MobileCompletionsContainer>
        <Completions
          selected={selectedAnswerSet}
          answerSets={answerSets}
          selectAnswerSet={setSelectedAnswerSet}
          setEditing={setEditing}
        />
      </MobileCompletionsContainer>
      <CompletionContent
        answerSet={selectedAnswerSet}
        completions={completions}
        editing={editing}
        setEditing={setEditing}
      />
    </Container>
  )
}

function CompletionContent(props: {
  answerSet?: AnswerSet
  completions: Completion[]
  editing: boolean
  setEditing: (state: boolean) => void
}) {
  const {answerSet, completions, editing, setEditing} = props
  const completion = completions.find(
    (c) => String(c.answer_set_id) === String(answerSet?.id),
  )
  const {
    register,
    control,
    formState: {isDirty},
    handleSubmit,
  } = useForm()
  const {
    categoryId,
    fetchBlocks,
    profileId,
    updateAnswerSet,
    updateCompletion,
  } = useObieService()

  const [submitting, setSubmitting] = useState(false)

  if (!answerSet || !completion) {
    return null
  }

  const onClickCancel = () => {
    setEditing(false)
  }

  const onSubmit = (formData: {name: string; completion: string}) => {
    setSubmitting(true)
    updateAnswerSet(categoryId, answerSet.block_id, answerSet.id, formData.name)
      .then(() => updateCompletion(completion.id, formData.completion))
      .then(() => fetchBlocks(categoryId, profileId))
  }

  const processedText = processText(completion.completion)

  if (editing) {
    return (
      <Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledTextField
            fullWidth
            name="name"
            variant="filled"
            required
            defaultValue={answerSet.name || ''}
            inputProps={{
              ref: register,
            }}
          />
          <Controller
            name="completion"
            defaultValue={processedText || ''}
            control={control}
            render={({onChange, value}) => (
              <MarkdownEditor data={value} onChange={onChange} theme="Light" />
            )}
          />
          <ButtonBox>
            <Button
              type="button"
              variant="contained"
              color="grey"
              onClick={onClickCancel}
              disabled={submitting}
            >
              Cancel
            </Button>
            <SubmitButton isDirty={isDirty} submitting={submitting} />
          </ButtonBox>
        </form>
      </Body>
    )
  }

  return (
    <Body>
      <CompletionTitle>{answerSet.name}</CompletionTitle>
      <CompletionText>
        <Markdown breaks gfm>
          {processedText}
        </Markdown>
      </CompletionText>
    </Body>
  )
}

function SubmitButton(props: {isDirty: boolean; submitting: boolean}) {
  const {isDirty, submitting} = props

  if (isDirty) {
    return (
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={submitting}
      >
        Save
      </Button>
    )
  }

  return (
    <Button type="button" variant="contained" color="grey" disabled={true}>
      Save
    </Button>
  )
}

function Completions(props: {
  answerSets: AnswerSet[]
  selected?: AnswerSet
  selectAnswerSet: (answerSet?: AnswerSet) => void
  setEditing: (state: boolean) => void
}) {
  const {answerSets, selectAnswerSet, selected, setEditing} = props
  const {profileId} = useObieService()
  const history = useHistory()
  const {routes} = useOrganization()

  const handleSelectAnswerSet = (answerSet: AnswerSet) => {
    if (selected && selected.id === answerSet.id) {
      selectAnswerSet()
    } else {
      // If the AnswerPill being clicked happens to be for a PAG Builder, we need
      // to redirect user to the new interface. Otherwise, we open up the editor
      // on the Block listing.
      if (answerSet.program_at_a_glance) {
        const pRoute = pagRoute(
          routes,
          answerSet.program_at_a_glance.id,
          String(profileId),
        )

        return history.push(`${pRoute}`)
      }

      selectAnswerSet(answerSet)
    }
    setEditing(false)
  }

  return (
    <CompletionsList selected={!!selected}>
      {answerSets.map((answerSet, index) => {
        if (answerSet.profile_id !== profileId) {
          return null
        }

        return (
          <AnswerSetPill
            key={index}
            answerSet={answerSet}
            selected={!selected || selected.id === answerSet.id}
            onSelect={() => handleSelectAnswerSet(answerSet)}
          />
        )
      })}
    </CompletionsList>
  )
}

function AnswerSetPill(props: {
  answerSet: AnswerSet
  selected?: boolean
  onSelect: () => void
}) {
  return (
    <PillWrapper active={props.selected} onClick={props.onSelect}>
      <AnswerSetPillLabel answerSet={props.answerSet} />
    </PillWrapper>
  )
}

const AnswerSetPillLabel = (props: {answerSet: AnswerSet}) => {
  if (!props.answerSet.complete) {
    return <>{props.answerSet.name} - INCOMPLETE</>
  }

  return <>{props.answerSet.name}</>
}

function CreateCompletionButton(props: {
  block: Block
  showing?: boolean
  onCreate: () => void
}) {
  const {hasUnCompletedDependency} = useUncompletedDependencies(props.block)

  if (!props.showing) {
    return null
  }

  return (
    <StyledButton
      variant="contained"
      color="primary"
      onClick={props.onCreate}
      disablePadding
      disabled={hasUnCompletedDependency}
    >
      <PlusIcon
        iconSize={13}
        title={`Create new ${props.block.block} Completion`}
      />
    </StyledButton>
  )
}

const processText = (completion: string) => {
  const processedText = completion.replaceAll(OBIE_NEW_LINE, '\n')

  // Try to split on the OBIE_OPTIONS_LIST identifier so we can massage the list.
  const optionsListParts = processedText.split(OBIE_OPTIONS_LIST)

  // If there's only one element in the array after the split, means we didn't
  // find the OBIE_OPTIONS_LIST identifier and we just need to return all the
  // text as it is.
  if (optionsListParts.length === 1) {
    return optionsListParts[0]
  }

  // Process out the options text into an array of values we can manipulate.
  const optionsList = resolveOptions(optionsListParts[1])

  // Since we have an options list in the text, we first need to include any text
  // that may be BEFORE the list.
  let returnText = `${optionsListParts[0]}\n`

  // Iterate all the options so we can prepend with the markdown "-"" to indicate
  // a list.
  optionsList.forEach((option) => {
    returnText += `- ${option}\n`
  })

  return returnText
}

const Container = styled.div<{isHidden: boolean}>`
  display: ${(props) => (props.isHidden ? 'none' : 'block')};
  background: white;
  border-radius: 4px;
  margin-bottom: ${(props) => props.theme.spacing[2]};
`

const Header = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacing[4]};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

const MobileHeader = styled.div<{selected?: boolean}>`
  display: flex;
  width: 100%;
  justify-content: ${(props) =>
    props.selected ? 'flex-end' : 'space-between'};
  padding: ${(props) => props.theme.spacing[4]};
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

const MobileCompletionsContainer = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

const CompletionsList = styled.div<{selected?: boolean}>`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.selected ? 'flex-start' : 'flex-end')};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    justify-content: flex-start;
    padding: ${(props) => props.theme.spacing[2]};
  }
`

const PillWrapper = styled.div<{active?: boolean}>`
  border-radius: 20px;
  padding: ${(props) => `${props.theme.spacing[1]} ${props.theme.spacing[6]}`};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 125%;
  color: white;
  background: ${(props) =>
    props.active ? '#131D34' : 'rgba(19, 29, 52, 0.7)'};
  margin-right: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  cursor: pointer;
`

const StyledButton = styled(Button)`
  width: 28px;
  height: 28px;
  padding: ${(props) => props.theme.spacing[2]};
`

const Body = styled.div`
  padding: ${(props) => props.theme.spacing[6]};
`

const CompletionTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: ${(props) => props.theme.spacing[6]};
`

const CompletionText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
`

const StyledTextField = styled(MuiTextField)`
  & .MuiFilledInput-multiline {
    padding: 0;
  }
  & .MuiFilledInput-input {
    padding: ${(props) => props.theme.spacing[3]};
  }
`

const ButtonBox = styled.div`
  display: flex;
  margin-top: ${(props) => props.theme.spacing[2]};
  gap: ${(props) => props.theme.spacing[2]};
`
