import Input from '@material-ui/core/Input'
import FontStyleInput from 'lib/ui/typography/FontStyleInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import {onChangeCheckedHandler} from 'lib/dom'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useConfig} from 'organization/Event/Configurable'
import InputLabel from 'lib/ui/TextField/InputLabel'
import {AgendaListSettings} from 'Event/template/NiftyFifty/Dashboard/AgendaList'
import {useAttendeeVariables} from 'Event'

export default function Settings(props: {agendaList: AgendaListSettings}) {
  const {agendaList} = props
  const {
    form: {control, register},
  } = useConfig()

  const v = useAttendeeVariables()

  return (
    <>
      <Controller
        name={`agendaList.isVisible`}
        control={control}
        defaultValue={agendaList.isVisible}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="config enabled switch"
          />
        )}
      />

      <InputLabel>Title</InputLabel>
      <TextField
        name={`agendaList.title`}
        defaultValue={v(agendaList.title)}
        aria-label="update agendas title"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
      <InputLabel>Menu Title</InputLabel>
      <TextField
        name={`agendaList.menuTitle`}
        defaultValue={v(agendaList.menuTitle)}
        aria-label="update blogs menu title"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
      <Label>Description</Label>
      <Controller
        name={`agendaList.description`}
        control={control}
        defaultValue={v(agendaList.description || '')}
        render={({value, onChange}) => (
          <Input
            id="agenda-adornment-description"
            type="text"
            value={value}
            onChange={onChange}
            aria-label="update agendas description"
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <Controller
                  name={`agendaList.descriptionFontStyles`}
                  control={control}
                  defaultValue={agendaList.descriptionFontStyles || ''}
                  render={({value, onChange}) => (
                    <FontStyleInput onChange={onChange} value={value} />
                  )}
                />
              </InputAdornment>
            }
          />
        )}
      />
      <Label mt={6}>Footer</Label>
      <Controller
        name={`agendaList.footer`}
        control={control}
        defaultValue={v(agendaList.footer || '')}
        render={({value, onChange}) => (
          <Input
            id="agenda-adornment-footer"
            type="text"
            value={value}
            onChange={onChange}
            aria-label="update agendas footer"
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <Controller
                  name={`agendaList.footerFontStyles`}
                  control={control}
                  defaultValue={agendaList.footerFontStyles || ''}
                  render={({value, onChange}) => (
                    <FontStyleInput onChange={onChange} value={value} />
                  )}
                />
              </InputAdornment>
            }
          />
        )}
      />
    </>
  )
}
