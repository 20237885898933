import {obieApi} from 'lib/obie/url'
import {useOrganization} from 'organization/OrganizationProvider'
import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {useOrganizationObieClient} from './use-organization-obie-client'

interface UseAssistantThreadParams {
  id: number
}

export interface ThreadMessage {
  content: string
  type: 'user' | 'assistant'
}

export interface AssistantThread {
  assistant_id: number
  id: number
  messages: ThreadMessage[]
}

export function useAssistantThread(params: UseAssistantThreadParams) {
  const {id} = params
  const {organization} = useOrganization()

  const {client} = useOrganizationObieClient()

  return useQuery({
    queryKey: ['organizations', organization.id, 'assistant_threads', id],
    queryFn: () =>
      client?.get<AssistantThread>(
        obieApi(`/organizations/${organization.id}/assistant_threads/${id}`),
      ),
  })
}

export function useAssistantThreadMessages(params: UseAssistantThreadParams) {
  const [messages, setMessages] = useState<ThreadMessage[]>([])

  const {data: thread} = useAssistantThread(params)

  useEffect(() => {
    if (!thread) {
      return
    }

    setMessages(thread.messages)
  }, [thread])

  return [messages, setMessages] as const
}
