import ImageConfig from 'organization/Marketplace/config/BlockConfig/ImageConfig'
import TitleConfig from 'organization/Marketplace/config/BlockConfig/TitleConfig'
import TextConfig from 'organization/Marketplace/config/BlockConfig/TextConfig'
import TicketSelectorConfig from 'organization/Marketplace/config/BlockConfig/TicketSelectorConfig'
import React from 'react'
import VideoConfig from 'organization/Marketplace/config/BlockConfig/VideoConfig'
import PurchaseFormConfig from 'organization/Marketplace/config/BlockConfig/PurchaseFormConfig'
import SeparatorConfig from 'organization/Marketplace/config/BlockConfig/SeparatorConfig'
import CountdownTimerConfig from 'organization/Marketplace/config/BlockConfig/CountdownTimerConfig'
import BulletedListConfig from 'organization/Marketplace/config/BlockConfig/BulletedListConfig'
import NumberedListConfig from 'organization/Marketplace/config/BlockConfig/NumberedListConfig'
import IconConfig from 'organization/Marketplace/config/BlockConfig/IconConfig'
import FaqListConfig from 'organization/Marketplace/config/BlockConfig/FaqListConfig'
import SpacerConfig from 'organization/Marketplace/config/BlockConfig/SpacerConfig'
import {PurchasePageBlock} from 'Event/Marketplace/purchase-page'
import GridConfig from 'organization/Marketplace/config/BlockConfig/GridConfig'
import {ThankYouPageBlock} from 'Event/Marketplace/thank-you-page'
import PurchasePageButtonConfig from 'organization/Marketplace/config/BlockConfig/PurchasePageButtonConfig'
import {UpsellPageBlock} from 'Event/Marketplace/upsell-page'
import UpsellPurchaseButtonConfig from 'organization/Marketplace/config/BlockConfig/UpsellPurchaseButtonConfig'
import UpsellDeclineButtonConfig from 'organization/Marketplace/config/BlockConfig/UpsellDeclineButtonConfig'
import UpsellDeclineTextConfig from 'organization/Marketplace/config/BlockConfig/UpsellDeclineTextConfig'

interface BlockConfigProps {
  block: PurchasePageBlock | ThankYouPageBlock | UpsellPageBlock
  id: string
  sectionId: string
  onMoveSectionUp?: () => void
  onMoveSectionDown?: () => void
  ButtonConfig: React.FC<any>
}

export default function BlockConfig(props: BlockConfigProps) {
  const {
    block,
    id,
    sectionId,
    onMoveSectionUp,
    onMoveSectionDown,
    ButtonConfig,
  } = props

  if (block.type === 'Button') {
    return (
      <ButtonConfig
        {...(block as any)}
        id={id}
        sectionId={sectionId}
        onMoveSectionUp={onMoveSectionUp}
        onMoveSectionDown={onMoveSectionDown}
      />
    )
  }

  const ConfigComponent = ComponentConfigs[block.type] ?? null

  if (!ConfigComponent) {
    return null
  }

  return (
    <ConfigComponent
      {...(block as any)}
      id={id}
      sectionId={sectionId}
      onMoveSectionUp={onMoveSectionUp}
      onMoveSectionDown={onMoveSectionDown}
    />
  )
}

export type ConfigDefinitions = {
  [T in PurchasePageBlock as T['type']]: React.FC<
    T & {id: string; sectionId: string}
  >
}

export const ComponentConfigs: ConfigDefinitions = {
  Title: TitleConfig,
  Text: TextConfig,
  Image: ImageConfig,
  TicketSelector: TicketSelectorConfig,
  Button: PurchasePageButtonConfig,
  Video: VideoConfig,
  PurchaseForm: PurchaseFormConfig,
  Separator: SeparatorConfig,
  BulletedList: BulletedListConfig,
  CountdownTimer: CountdownTimerConfig,
  NumberedList: NumberedListConfig,
  Icon: IconConfig,
  FaqList: FaqListConfig,
  Spacer: SpacerConfig,
  Grid: GridConfig,
  UpsellPurchaseButton: UpsellPurchaseButtonConfig,
  UpsellDeclineButton: UpsellDeclineButtonConfig,
  UpsellDeclineText: UpsellDeclineTextConfig,
}
