import {useAttendeeVariables} from 'Event'
import {useAttendee} from 'Event/auth'
import AccessTeamPage from 'Event/business-mastery/AccessTeamPage'
import {
  IN_PERSON_QUERY,
  TEAM_ID_GROUP_KEY,
  useAttendeesWithTeamId,
} from 'Event/business-mastery/TeamCompetition'
import {eventRoutes} from 'Event/Routes'
import {useToggleArray} from 'lib/toggle'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import {useQueryParams} from 'lib/url'
import React, {useCallback, useState} from 'react'
import {useHistory} from 'react-router-dom'

const TEAM_NOT_CREATED_ERROR = 'Team has not been created.'

const SWITCH_TEAM_WARNING =
  'You currently belong to another team. Are you sure you wish to switch teams?'

export default function JoinTeamPage() {
  const history = useHistory()
  const isExistingTeam = useCheckTeamExists()
  const [error, setError] = useState('')
  const v = useAttendeeVariables()
  const [id, setId] = useState('')
  const user = useAttendee()
  const {[IN_PERSON_QUERY]: inPerson = false} = useQueryParams()
  const currentTeamId = user.groups[TEAM_ID_GROUP_KEY]

  const [processing, toggleProcessing] = useToggleArray()

  const goToTeam = (passedId?: string) => {
    const currentId = passedId || id

    history.push(
      `${
        eventRoutes.team_competition.root
      }?team_id=${currentId}&${IN_PERSON_QUERY}=${inPerson ? 'true' : 'false'}`,
    )
  }

  const joinTeam = (confirmChange: () => void) => ({id}: any) => {
    if (processing) {
      return
    }

    setId(id)
    setError('')
    toggleProcessing()

    isExistingTeam(id).then((exists) => {
      if (!exists) {
        setError(v('{{team_not_created_error}}', TEAM_NOT_CREATED_ERROR))
        toggleProcessing()
        return
      }

      const isChangingTeams = currentTeamId && currentTeamId !== id
      if (isChangingTeams) {
        confirmChange()
        return
      }

      goToTeam(id)
    })
  }

  const handleCancelSwitch = (hideConfirmDialog: () => void) => {
    hideConfirmDialog()
    toggleProcessing()
  }

  return (
    <ConfirmDialog
      onConfirm={() => goToTeam()}
      title="Switch Teams?"
      description={v('{{team_switch_warning}}', SWITCH_TEAM_WARNING)}
      confirmLabel="Yes"
      onCancel={handleCancelSwitch}
    >
      {(showConfirmation) => (
        <AccessTeamPage
          title={v('{{team_join_title}}', 'Join Team')}
          description={
            <>
              <p
                style={{
                  whiteSpace: 'pre-line',
                }}
              >
                {`1. Get the short code from your team leader.

2. Enter that short code below and submit.
`}
              </p>
              <em>
                You will have joined that team and unlocked Gladiator Team
                Central! You can change teams by submitting a different short
                code and submitting.
              </em>
            </>
          }
          error={error}
          processing={processing}
          onSubmit={joinTeam(showConfirmation)}
          submitLabel={v('{{team_join_button}}', 'Join')}
        />
      )}
    </ConfirmDialog>
  )
}

function useCheckTeamExists() {
  const findAttendeesWithTeamId = useAttendeesWithTeamId()

  return useCallback(
    (id: string) => {
      return findAttendeesWithTeamId(id).then((paginated) => {
        return paginated.data.length > 0
      })
    },
    [findAttendeesWithTeamId],
  )
}
