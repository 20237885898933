import {Template} from 'Event/template'
import {createBlank, DEFAULTS, NiftyFifty} from 'Event/template/NiftyFifty'
import {CARDS} from 'Event/template/Cards'
import {
  navButtonWithSize,
  emojisFromSidebar,
  navButtonsFromSidebar,
  baseProps,
  localization,
} from 'Event/template/converter'
import {Resource} from 'Event/template/NiftyFifty/Dashboard/Resources/ResourceList'

export function convert(template: Template): NiftyFifty {
  return {
    name: DEFAULTS.name,
    version: DEFAULTS.version,
    ...baseProps(template),
    accentColor: accentColor(template),
    menu: menu(template),
    points_unit: pointsUnit(template),
    backgroundPosition: backgroundPosition(template),
    rowBackgroundPrimary: rowBackgroundPrimary(template),
    rowBackgroundSecondary: rowBackgroundSecondary(template),
    mainNav: mainNav(template),
    welcomeText: welcomeText(template),
    homeMenuTitle: homeMenuTitle(template),
    emojiList: emojiList(template),
    pageTitleColor: pageTitleColor(template),
    textColor: textColor(template),
    linkColor: linkColor(template),
    linkUnderline: linkUnderline(template),
    leftPanel: leftPanel(template),
    dashboardLogo: dashboardLogo(template),
    dashboardBackground: dashboardBackground(template),
    checkInLogo: checkInLogo(template),
    checkInBackground: checkInBackground(template),
    rightPanel: rightPanel(template),
    blogPosts: blogPosts(template),
    resourceList: resourceList(template),
    checkInRightPanel: checkInRightPanel(template),
    checkInLeftPanel: checkInLeftPanel(template),
    bodyHTMLEmbed: bodyHTMLEmbed(template),
    leaderboard: leaderboard(template),
    faq: faq(template),
    techCheck: techCheck(template),
    waiver: waiver(template),
    login: login(template),
    setPasswordForm: setPasswordForm(template),
    sponsors: sponsors(template),
    speakers: speakers(template),
    offlinePage: offlinePage(template),
    countDownTimers: countDownTimers(template),
    imageWaterfall: imageWaterfall(template),
    progressBar: progressBar(template),
    languageSelectorButton: template.languageSelectorButton,
    localization: localization(template, createBlank()),
  }
}

function accentColor(template: Template): NiftyFifty['accentColor'] {
  if ('header' in template) {
    return template.header.backgroundColor
  }

  return template.accentColor
}

function menu(template: Template): NiftyFifty['menu'] {
  return {
    ...DEFAULTS.menu,
    ...template.menu,
  }
}

function pointsUnit(template: Template): NiftyFifty['points_unit'] {
  return template.points_unit
}

function rowBackgroundPrimary(
  template: Template,
): NiftyFifty['rowBackgroundPrimary'] {
  if ('rowBackgroundPrimary' in template) {
    return template.rowBackgroundPrimary
  }
  return DEFAULTS.rowBackgroundPrimary
}

function rowBackgroundSecondary(
  template: Template,
): NiftyFifty['rowBackgroundSecondary'] {
  if ('rowBackgroundSecondary' in template) {
    return template.rowBackgroundSecondary
  }
  return DEFAULTS.rowBackgroundSecondary
}

function backgroundPosition(
  template: Template,
): NiftyFifty['backgroundPosition'] {
  if ('pageBackgroundPosition' in template) {
    return template.pageBackgroundPosition ?? DEFAULTS.backgroundPosition
  }

  return template.backgroundPosition ?? DEFAULTS.backgroundPosition
}

function mainNav(template: Template): NiftyFifty['mainNav'] {
  if (template.name === CARDS) {
    return {
      buttons: {
        ...navButtonWithSize(template.mainNav.buttons),
        ...navButtonsFromSidebar(template),
      },
      spacing: 10,
    }
  }

  return {
    ...template.mainNav,
    ...navButtonsFromSidebar(template),
    spacing: 10,
  }
}

function welcomeText(template: Template): NiftyFifty['welcomeText'] {
  if ('welcomeMessage' in template) {
    return template.welcomeMessage.text ?? DEFAULTS.welcomeText
  }

  if ('hero' in template && 'body' in template.hero) {
    return template.hero.body
  }

  return DEFAULTS.welcomeText
}

function homeMenuTitle(template: Template): NiftyFifty['homeMenuTitle'] {
  if ('homeMenuTitle' in template) {
    return template.homeMenuTitle ?? DEFAULTS.homeMenuTitle
  }

  return DEFAULTS.homeMenuTitle
}

function emojiList(template: Template): NiftyFifty['emojiList'] {
  if ('sidebarItems' in template) {
    return {
      emojis: emojisFromSidebar(template),
      emojiWidth: DEFAULTS.emojiList.emojiWidth,
    }
  }

  return template.emojiList
}

function pageTitleColor(template: Template): NiftyFifty['pageTitleColor'] {
  if ('pageTitleColor' in template) {
    return template.pageTitleColor ?? DEFAULTS.pageTitleColor
  }

  return DEFAULTS.pageTitleColor
}

function textColor(template: Template): NiftyFifty['textColor'] {
  if ('textColor' in template) {
    return template.textColor ?? DEFAULTS.textColor
  }

  return DEFAULTS.textColor
}

function linkColor(template: Template): NiftyFifty['linkColor'] {
  if ('linkColor' in template) {
    return template.linkColor ?? DEFAULTS.linkColor
  }

  return DEFAULTS.linkColor
}

function linkUnderline(template: Template): NiftyFifty['linkUnderline'] {
  if ('linkUnderline' in template) {
    return template.linkUnderline ?? DEFAULTS.linkUnderline
  }

  return DEFAULTS.linkUnderline
}

function leftPanel(template: Template): NiftyFifty['leftPanel'] {
  if ('sidebar' in template) {
    return {
      ...DEFAULTS.leftPanel,
      backgroundColor: template.sidebar.background,
      arrowColor: template.sidebar.textColor,
    }
  }

  if ('leftPanel' in template) {
    return {
      ...DEFAULTS.leftPanel,
      ...template.leftPanel,
    }
  }

  return DEFAULTS.leftPanel
}

function dashboardLogo(template: Template): NiftyFifty['dashboardLogo'] {
  return {
    ...DEFAULTS.dashboardLogo,
    image: template.logo,
  }
}

function dashboardBackground(
  template: Template,
): NiftyFifty['dashboardBackground'] {
  if ('dashboardBackground' in template) {
    return {
      ...DEFAULTS.dashboardBackground,
      ...template.dashboardBackground,
    }
  }

  if ('pageBackground' in template) {
    return {
      ...DEFAULTS.dashboardBackground,
      ...template.pageBackground,
    }
  }

  return DEFAULTS.dashboardBackground
}

function checkInLogo(template: Template): NiftyFifty['checkInLogo'] {
  if ('checkInLogo' in template) {
    return template.checkInLogo
  }

  return DEFAULTS.checkInLogo
}

function checkInBackground(
  template: Template,
): NiftyFifty['checkInBackground'] {
  if ('checkInBackground' in template) {
    return template.checkInBackground
  }

  return DEFAULTS.checkInBackground
}

function rightPanel(template: Template): NiftyFifty['rightPanel'] {
  if ('rightPanel' in template) {
    return {
      ...DEFAULTS.rightPanel,
      ...template.rightPanel,
    }
  }

  return DEFAULTS.rightPanel
}

function blogPosts(template: Template): NiftyFifty['blogPosts'] {
  return template.blogPosts
}

function resourceList(template: Template): NiftyFifty['resourceList'] {
  if ('sidebarItems' in template) {
    return {
      ...DEFAULTS.resourceList,
      resources: resourcesFromSidebar(template),
    }
  }

  if ('resourceList' in template) {
    return template.resourceList
  }

  return DEFAULTS.resourceList
}

function checkInRightPanel(
  template: Template,
): NiftyFifty['checkInRightPanel'] {
  if ('checkInRightPanel' in template) {
    return template.checkInRightPanel
  }

  return DEFAULTS.checkInRightPanel
}

function checkInLeftPanel(template: Template): NiftyFifty['checkInLeftPanel'] {
  if ('sidebar' in template) {
    return {
      ...DEFAULTS.checkInLeftPanel,
      backgroundColor: template.sidebar.background,
    }
  }

  if ('checkInLeftPanel' in template) {
    return {
      ...DEFAULTS.checkInLeftPanel,
      ...template.checkInLeftPanel,
    }
  }

  return DEFAULTS.checkInLeftPanel
}

function bodyHTMLEmbed(template: Template): NiftyFifty['bodyHTMLEmbed'] {
  return template.bodyHTMLEmbed ?? DEFAULTS.bodyHTMLEmbed
}

function leaderboard(template: Template): NiftyFifty['leaderboard'] {
  return {
    ...DEFAULTS.leaderboard,
    ...template.leaderboard,
  }
}

function faq(template: Template): NiftyFifty['faq'] {
  return {
    ...DEFAULTS.faq,
    ...template.faq,
  }
}

function techCheck(template: Template): NiftyFifty['techCheck'] {
  return template.techCheck
}

function waiver(template: Template): NiftyFifty['waiver'] {
  return {
    ...DEFAULTS.waiver,
    ...template.waiver,
  }
}

function login(template: Template): NiftyFifty['login'] {
  return {
    ...DEFAULTS.login,
    ...template.login,
  }
}

function setPasswordForm(template: Template): NiftyFifty['setPasswordForm'] {
  return {
    ...DEFAULTS.setPasswordForm,
    ...template.setPasswordForm,
    button: {
      ...DEFAULTS.setPasswordForm.button,
      ...template.setPasswordForm?.button,
    },
  }
}

function sponsors(template: Template): NiftyFifty['sponsors'] {
  return {
    ...DEFAULTS.sponsors,
    ...template.sponsors,
    spacing: DEFAULTS.sponsors.spacing,
  }
}

function speakers(template: Template): NiftyFifty['speakers'] {
  return {
    ...DEFAULTS.speakers,
    ...template.speakers,
    spacing: DEFAULTS.speakers.spacing,
  }
}

function offlinePage(template: Template): NiftyFifty['offlinePage'] {
  return {
    ...DEFAULTS.offlinePage,
    ...template.offlinePage,
  }
}

function countDownTimers(template: Template): NiftyFifty['countDownTimers'] {
  if ('countDownTimers' in template) {
    return template.countDownTimers ?? DEFAULTS.countDownTimers
  }

  return DEFAULTS.countDownTimers
}

function imageWaterfall(template: Template): NiftyFifty['imageWaterfall'] {
  return {
    ...DEFAULTS.imageWaterfall,
    ...template.imageWaterfall,
  }
}

function progressBar(template: Template): NiftyFifty['progressBar'] {
  if ('progressBar' in template) {
    return {
      ...template.progressBar,
      progressBar: DEFAULTS.progressBar.progressBar,
    }
  }

  if ('checkIn' in template) {
    return {
      ...DEFAULTS.progressBar,
      step1Text: template.checkIn.step1Label,
      step2Text: template.checkIn.step2Label,
      step3Text: template.checkIn.step3Label,
    }
  }

  if ('step1Label' in template) {
    return {
      ...DEFAULTS.progressBar,
      step1Text: template.step1Label,
      step2Text: template.step2Label,
      step3Text: template.step3Label,
      textColor: template.textColor ?? DEFAULTS.progressBar.textColor,
    }
  }

  return DEFAULTS.progressBar
}

function resourcesFromSidebar(template: Template): Resource[] {
  const resources: Resource[] = []

  if ('sidebarItems' in template) {
    for (const key in template.sidebarItems) {
      const item = template.sidebarItems[key]

      if (item.type === 'Resource List') {
        for (const id in item.resources) {
          const resource = item.resources[id]
          resources.push({
            description: null,
            ...resource,
          })
        }
      }
    }
  }

  return []
}
