import Button from 'lib/ui/Button'
import {Assistant} from 'lib/obie/use-assistant'
import {useDeleteAssistant} from 'lib/obie/use-delete-assistant'
import {useOrganization} from 'organization/OrganizationProvider'
import React from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import ConfirmDialog from 'lib/ui/ConfirmDialog'

interface AssistantSettingsDeleteButtonProps {
  assistant?: Assistant
}

export default function AssistantSettingsDeleteButton(
  props: AssistantSettingsDeleteButtonProps,
) {
  const {assistant} = props
  const deleteAssistant = useDeleteAssistant()
  const {routes} = useOrganization()
  const history = useHistory()

  const handleDeleteAssistant = () => {
    if (!assistant) {
      return
    }

    deleteAssistant.mutate(assistant, {
      onSuccess: () => {
        history.push(routes.obie.assistants.root)
      },
    })
  }

  return (
    <Box>
      <p>Danger Zone</p>
      <ConfirmDialog
        title="Delete Assistant"
        description="Deleting an Assistant will also remove all of its chat threads and messages. This cannot be undone."
        confirmLabel="Delete"
        onConfirm={handleDeleteAssistant}
      >
        {(showConfirmation) => (
          <StyledButton
            color="danger"
            variant="outlined"
            onClick={showConfirmation}
            disabled={!assistant || deleteAssistant.isLoading}
          >
            Delete Assistant
          </StyledButton>
        )}
      </ConfirmDialog>
    </Box>
  )
}

const Box = styled.div`
  border: 1px solid ${(props) => props.theme.colors.error};
  padding: 1rem;
  border-radius: 0.5rem;

  p {
    color: ${(props) => props.theme.colors.error};
    margin: 0 0 0.5rem;
  }
`

const StyledButton = styled(Button)`
  background: ${(props) => props.theme.colors.error};
  color: #ffffff;

  &:hover {
    background: ${(props) => props.theme.colors.error};
    opacity: 0.8;
  }
`
