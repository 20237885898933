import {createNavButtonWithSize} from 'Event/Dashboard/components/NavButton/create'
import {BaseTemplate} from 'Event/template'
import {DeepRequired} from 'lib/type-utils'
import {colors} from 'lib/ui/theme'

/**
 * Creates the defaults for a BaseTemplate. This function is defined
 * in a separate file to Template/index.ts to avoid a circular
 * import between the templates.
 *
 * @returns
 */
export const createBaseTemplate = (): DeepRequired<BaseTemplate> => ({
  version: 1,
  localization: null,
  font: null,
  bodyHTMLEmbed: null,
  textColor: '#000000',
  rewardAlert: {
    backgroundColor: '#565656',
    textColor: '#FFFFFF',
    text:
      'Yay! You have received {{action_points}} {{points_unit}} for {{action_description}}.',
  },
  emojiPage: {
    backgroundColor: '#000000',
    backgroundOpacity: 100,
    backgroundEnabled: true,
    backgroundImage: null,
  },
  postStyles: {
    titleTextColor: null,
    titleFontSize: 30,
    dateTextColor: '#adadad',
    dateFontSize: 14,
    contentTextColor: null,
    contentFontSize: 17,
    spacing: 32,
    titleFont: null,
  },
  postFormStyles: {
    width: 100,
    position: 'center',
    buttonSize: 100,
    buttonRadius: 0,
    buttonColor: 'pink',
    buttonBackgroundColor: 'blue',
    buttonHoverBackgroundColor: 'blue',
    buttonPosition: 'center',
    buttonFontSize: 27,
    buttonBorderWidth: 0,
    buttonBorderColor: 'black',
    inputStyles: {
      labelColor: '#7d7a7a',
      borderColor: '#7d7a7a',
      backgroundColor: '#7d7a7a',
      backgroundOpacity: 0,
      textColor: '#7d7a7a',
      helperTextColor: '#7d7a7a',
    },
  },
  login: {
    background: null,
    backgroundColor: '#FFFFFF',
    backgroundOpacity: 0,
    backgroundEnabled: true,
    logo: null,
    logoEnabled: true,
    logoSize: 100,
    input: {
      labelColor: colors.primary,
      textColor: '#000000',
      borderColor: colors.primary,
      borderWidth: 2,
      backgroundColor: '#FFFFFF',
      borderRadius: 56,
    },
    emailLabel: 'Email',
    passwordLabel: 'Password',
    description: {
      text: '',
      color: null,
      fontSize: 18,
    },
    passwordReset: {
      linkLabel: 'Forgot Password?',
      buttonText: 'Send Reset Password Link',
      description: 'Send password reset link.',
      successMessage:
        "Password reset link sent! Check your spam folder if you don't see it after a couple minutes.",
      iconName: 'fas fa-lock',
      iconColor: 'blue',
    },
    submitButton: {
      backgroundColor: colors.primary,
      textColor: '#FFFFFF',
      hoverBackgroundColor: colors.primary,
      label: 'Login',
      borderRadius: 56,
      font: null,
      fontSize: 18,
      disableHover: true,
      hoverTextColor: '#FFFFFF',
      height: 64,
      width: 12,
      borderColor: colors.primary,
      hoverBorderColor: colors.primary,
      borderWidth: 0,
    },
    requiresPassword: true,
    skipSetPasswordRules: [],
  },
  signUp: {
    linkLabel: 'Sign Up',
    buttonText: 'Sign Up',
    description: 'Fill in the form below to access the event.',
    firstNameLabel: 'First Name',
    lastNameLabel: 'Last Name',
    phoneNumberLabel: 'Phone number',
    hasPhoneNumberField: false,
    phoneNumberRequired: false,
    tags: [],
    groups: [],
    error: {
      emailAlreadyTakenMessage: 'The email has already been taken.',
    },
  },
  changePassword: {
    description: {
      text: '',
      color: '#000000',
      fontSize: 18,
    },
    newPasswordLabel: 'New Password',
    confirmNewPasswordLabel: 'Confirm New Password',
    input: {
      labelColor: colors.primary,
      textColor: '#000000',
      borderColor: colors.primary,
      borderWidth: 2,
      backgroundColor: '#FFFFFF',
      borderRadius: 56,
    },
    submitButton: {
      backgroundColor: colors.primary,
      textColor: '#FFFFFF',
      hoverColor: colors.primary,
      label: 'Submit',
      borderRadius: 56,
    },
  },
  waiver: {
    isEnabled: false,
    button: createNavButtonWithSize({text: 'submit'}),
    skipRules: [],
    checkboxColor: colors.primary,
    targets: {},
    input: {
      labelColor: colors.primary,
      textColor: '#000000',
      borderColor: colors.primary,
      borderWidth: 2,
      backgroundColor: '#FFFFFF',
      borderRadius: 56,
    },
    firstNameInputLabel: 'First Name',
    lastNameInputLabel: 'Last Name',
    signature: {
      typeButtonText: 'Click here to type signature instead',
      typeButtonColor: '#000000',
      typeInputLabel: 'Signature',
    },
    signatureRequired: true,
    formEnabled: true,
    submittingText: 'Generating...',
  },
  techCheck: {
    isEnabled: false,
    body:
      'Details about Tech Check go here - Eros donec ac odio tempor orci dapibus. Varius duis at consectetur lorem donec massa sapien. Eget est lorem ipsum dolor sit amet consectetur adipiscing elit. Elit ullamcorper dignissim cras tincidunt lobortis. Aliquet lectus proin nibh nisl condimentum. Bibendum arcu vitae elementum curabitur vitae nunc sed velit dignissim. At quis risus sed vulputate odio.',
    additionalContent:
      'Additional details about Tech Check can also go here. - Id diam vel quam elementum pulvinar etiam. Ut pharetra sit amet aliquam id diam. Congue nisi vitae suscipit tellus mauris. Vitae et leo duis ut. Nam aliquam sem et tortor consequat id. Convallis posuere morbi leo urna. Nullam eget felis eget nunc. Id venenatis a condimentum vitae sapien. Enim sed faucibus turpis in.',
    areaKey: null,
    startButton: createNavButtonWithSize({text: 'Complete My Check In'}),
    customButtons: {},
    hasCustomButtons: false,
    skipRules: [],
  },
  logo: null,
  points_unit: 'Points',
  blogPosts: {},
  imageWaterfall: {
    title: 'Image Waterfall',
    description: 'Image Waterfall',
    backToDashboardText: 'Back to Dashboard',
    backToDashboardTextColor: '#000000',
    uploadFormTitle: 'Upload Image',
    uploadFormDescription: 'Upload Waterfall Image',
    uploadButtonText: 'Upload',
    uploadButtonFontSize: 12,
    uploadButtonBorderRadius: 0,
    uploadButtonTextColor: '#000000',
    uploadButtonBackgroundColor: '#FFFFFF',
    uploadButtonBorderColor: '#000000',
    actionId: null,
    isVisible: true,
    page: {
      backgroundColor: '#000000',
      backgroundOpacity: 100,
      backgroundEnabled: true,
      backgroundImage: null,
      addImageIntervalSecs: 5,
      numColumns: 5,
    },
  },
  menu: {
    backgroundColor: '#000000',
    iconColor: '#0969d6',
    textColor: '#FFFFFF',
  },
  faq: {
    title: 'FAQ',
    description: '',
    isEnabled: true,
    items: {},
  },
  sponsors: {
    title: 'Sponsors',
    description: '',
    isEnabled: true,
    questionIcon: null,
    items: {},
  },
  speakers: {
    title: 'Our Speakers',
    description: '',
    isEnabled: true,
    items: {},
    imageSize: 2,
  },
  zoomBackgrounds: {
    isEnabled: true,
    title: 'Zoom Virtual Backgrounds',
    description: '',
    items: {},
    borderColor: '#000000',
    borderRadius: 0,
    borderThickness: 0,
    imagesPerRow: 2,
  },
  scripts: {},
  offlinePage: {
    shouldRedirect: false,
    redirectUrl: '',
    title: 'Event Offline',
    description: 'Please check back again, or contact support for access.',
  },
  countDownTimers: {},
  formModeration: {
    form: {},
  },

  languageSelectorButton: createNavButtonWithSize({text: 'Language'}),
})
