import React from 'react'
import styled from 'styled-components'
import {ReactComponent as Bot} from 'assets/images/icons/white-bot.svg'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {useOrganization} from 'organization/OrganizationProvider'

export default function Logo(props: {className?: string}) {
  const {routes} = useOrganization()

  return (
    <Box className={props.className}>
      <TitleBox to={routes.obie.root}>
        <IconBox>
          <Bot />
        </IconBox>
        <Title>OBIE</Title>
      </TitleBox>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacing[8]};
`

const IconBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TitleBox = styled(RelativeLink)`
  display: inline-flex;

  &:hover {
    text-decoration: none !important;
  }
`

const Title = styled.h1`
  font-weight: bold;
  font-size: 36px;
  line-height: 64px;
  color: #ffffff;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-left: ${(props) => props.theme.spacing[2]};
`
