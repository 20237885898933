import React from 'react'
import TicketSelector from './TicketSelector'
import Image from './Image'
import Button from './Button'
import Title from './Title'
import Video from './Video'
import Text from './Text'
import PurchaseForm from './PurchaseForm'
import VisibleContent, {VisibleContentProps} from './VisibleContent'
import Separator from './Separator'
import CountdownTimer from './CountdownTimer'
import BulletedList from './BulletedList'
import NumberedList from './NumberedList'
import Icon from './Icon'
import FaqList from './FaqList'
import Spacer from './Spacer'
import UpsellPurchaseButton from './UpsellPurchaseButton'
import UpsellDeclineButton from './UpsellDeclineButton'
import {PurchasePageBlock} from '../purchase-page'
import Grid from './Grid'
import {PageTemplate} from '../page-template'
import UpsellDeclineText from './UpsellDeclineText'

export interface BlockProps extends BlockComponentProps {
  block: PurchasePageBlock
  style?: React.CSSProperties
  className?: string
  children?: React.ReactNode
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>
  onMouseUp?: React.MouseEventHandler<HTMLDivElement>
  onTouchEnd?: React.TouchEventHandler<HTMLDivElement>
  isMobileMode?: boolean
  CustomComponents?: CustomComponents
}

export interface BlockComponentProps {
  id: string
  template: PageTemplate
  CustomComponents?: CustomComponents
  width?: number
  height?: number
  isEditMode?: boolean
}

interface CustomComponents {
  Image?: any
}

const Block = React.forwardRef<HTMLDivElement, BlockProps>((props, ref) => {
  const {
    block,
    id,
    isMobileMode,
    template,
    CustomComponents,
    ...gridLayoutProps
  } = props

  const {
    style,
    className,
    onMouseDown,
    onMouseUp,
    onTouchEnd,
    children,
  } = gridLayoutProps

  const width =
    'width' in block ? block.width : parseInt(style?.width as string)
  const height =
    'height' in block ? block.height : parseInt(style?.height as string)

  const Component = Components[block.type] ?? null

  if (!Component) {
    return null
  }

  return (
    <Box
      className={className}
      ref={ref}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchEnd={onTouchEnd}
      visibility={block.visibility}
      isMobileMode={isMobileMode}
      style={{
        ...style,
        transitionProperty: 'none', // disable drag animations
      }}
    >
      {/*
       * Setting 'any' type for props is ok here, because we're
       * already verifying the correct component receives the
       * correct type in ComponentDefinitions.
       */}
      <Component
        {...(block as any)}
        id={id}
        template={template}
        CustomComponents={CustomComponents}
        width={width}
        height={height}
      />
      {children}
    </Box>
  )
})

export default Block

export type ComponentDefinitions = {
  [T in PurchasePageBlock as T['type']]: React.FC<
    T & {id: string; template: PageTemplate}
  >
}

export const Components: ComponentDefinitions = {
  Title,
  Text,
  Image,
  TicketSelector,
  Button,
  Video,
  PurchaseForm,
  Separator,
  BulletedList,
  CountdownTimer,
  NumberedList,
  Icon,
  FaqList,
  Spacer,
  Grid,
  UpsellPurchaseButton,
  UpsellDeclineButton,
  UpsellDeclineText,
}

export const Box = React.forwardRef<HTMLDivElement, VisibleContentProps>(
  (props, ref) => {
    return (
      <VisibleContent
        {...props}
        ref={ref}
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          ...props.style,
        }}
      />
    )
  },
)
