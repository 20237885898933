import {useOrganization} from 'organization/OrganizationProvider'
import {useMutation, useQueryClient} from 'react-query'
import {Assistant} from 'lib/obie/use-assistant'
import {obieApi} from './url'
import {useOrganizationObieClient} from './use-organization-obie-client'

export function useUpdateAssistant() {
  const {organization} = useOrganization()
  const {client} = useOrganizationObieClient()
  const qc = useQueryClient()

  return useMutation({
    mutationFn: async (data: {
      assistant: {
        id: number
      }
      name: string
    }) => {
      const {assistant, name} = data
      const url = obieApi(
        `/organizations/${organization.id}/assistants/${assistant.id}`,
      )

      return client!.put<Assistant>(url, {
        name,
      })
    },
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: ['organizations', organization.id, 'assistants'],
      })
    },
  })
}
