import React from 'react'
import {useAttendeeVariables} from 'Event'
import FeaturePage from 'Event/template/Townhall/Page'
import {
  FeaturePageTitle,
  PageTexts,
  StyledConfigurable,
} from 'Event/template/Townhall/Page'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {User} from 'auth/user'
import Configurable from 'organization/Event/Configurable'
import BackgroundLayoutConfig from 'Event/Backgrounds/BackgroundLayoutConfig'
import UploadBackgroundButton from 'Event/Backgrounds/UploadBackgroundButton'
import BackgroundList from 'Event/Backgrounds/BackgroundList'
import {TownhallBreadcrumbs} from 'Event/template/Townhall/Page'
import BackgroundsHeaderConfig from 'Event/template/Townhall/Backgrounds/BackgroundsHeaderConfig'

export default function Backgrounds(props: {user: User}) {
  const {zoomBackgrounds: settings} = useTownhallTemplate()
  const v = useAttendeeVariables()

  return (
    <FeaturePage user={props.user}>
      <StyledConfigurable>
        <BackgroundsHeaderConfig />
        <>
          <FeaturePageTitle aria-label="backgrounds title">
            {v(settings.title)}
          </FeaturePageTitle>
          <TownhallBreadcrumbs page={v(settings.title)} />
          <PageTexts
            title={v(settings.secondaryTitle)}
            description={v(settings.description)}
          />
        </>
      </StyledConfigurable>
      <UploadBackgroundButton />
      <Configurable
        dataTestId="background list config"
        aria-label="background list config edit icon"
      >
        <BackgroundLayoutConfig />
        <BackgroundList />
      </Configurable>
    </FeaturePage>
  )
}
