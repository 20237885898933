import {
  NavButtonProps,
  NavButtonWithSize,
} from 'Event/Dashboard/components/NavButton'
import React, {useState, useCallback} from 'react'
import {ComponentConfigProps} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {Panels, usePanelsUpdate} from 'Event/template/Panels'
import {useForm} from 'react-hook-form'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import MainNavButtonConfigBase from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig'
import Settings from 'Event/template/Panels/Dashboard/MainNav/MainNavButton/MainNavButtonConfig/Settings'
import Styling from 'Event/template/Panels/Dashboard/MainNav/MainNavButton/MainNavButtonConfig/Styling'
import {usePruneAssets} from 'lib/asset'
import {useTemplateEditor} from 'organization/Event/TemplateEditor'
import {generateHashId} from 'lib/crypto/hash'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'

export type ButtonConfigProps<K extends NavButtonProps> = {
  button: K
  update: <T extends keyof K>(key: T) => (value: K[T]) => void
}

export default function MainNavButtonConfig(
  props: ComponentConfigProps & {
    button: NavButtonWithSize
    id?: string
  },
) {
  const {button, id, showing: isVisible, onClose} = props
  const updateTemplate = usePanelsUpdate()
  const pruneAssets = usePruneAssets()
  const {saved} = useTemplateEditor<Panels>()
  const removeTranslations = useRemoveTranslations()
  const addTranslation = useAddTranslation()

  const [rules, setRules] = useState(button.rules)
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState,
  } = useForm()

  const update = (id: string, updated: NavButtonWithSize) => {
    const image = updated.image
    if (saved.mainNav.buttons && id in saved.mainNav.buttons) {
      pruneAssets({image}, saved.mainNav.buttons[id])
    }

    const {text, ...others} = updated

    updateTemplate({
      localization: addTranslation({
        [`${id}_text`]: text,
      }),
      mainNav: {
        buttons: {
          [id]: {
            text: `{{${id}_text}}`,
            ...others,
          },
        },
      },
    })
  }

  const removeButton = useCallback(() => {
    if (!id) {
      throw new Error('Missing button id')
    }

    onClose()
    updateTemplate({
      mainNav: {
        buttons: {
          [id]: REMOVE,
        },
      },
      localization: removeTranslations([`${id}_text`]),
    })
  }, [id, onClose, updateTemplate, removeTranslations])

  const save = (formData: NavButtonWithSize) => {
    const data: NavButtonWithSize = {
      ...formData,
      rules,
      // isImageUpload: isImageUploadButton,
    }

    if (id) {
      update(id, data)
    } else {
      generateHashId([
        'panels_',
        'main_nav_buttons',
        Date.now().toString(),
        Math.random().toString(),
      ]).then((id) => {
        update(id, data)
      })
    }

    onClose()
  }

  return (
    <MainNavButtonConfigBase
      button={button}
      id={id}
      control={control}
      register={register}
      watch={watch}
      setValue={setValue}
      rules={rules}
      setRules={setRules}
      removeButton={removeButton}
      onClose={onClose}
      formState={formState}
      handleSubmit={handleSubmit}
      save={save}
      isVisible={isVisible}
      showing={Boolean(id)}
      customSettings={
        <Settings
          button={button}
          control={control}
          watch={watch}
          register={register}
          setValue={setValue}
        />
      }
      customStyling={
        <Styling button={button} control={control} watch={watch} />
      }
    />
  )
}
