import React from 'react'
import {BlockComponentProps} from '..'
import Container from '../Container'
import {BlockBase} from '../base'
import {usePurchase} from '../../PurchaseContext'
import {Font, getFontStyles} from '../../lib/font'

export interface UpsellDeclineTextBlock extends BlockBase {
  type: 'UpsellDeclineText'
  text: string
  font: Font | null
  fontSize: number
  color: string
  opacity: number
  lineHeight: number
  letterSpacing: number
}

export default function UpsellDeclineText(
  props: UpsellDeclineTextBlock & BlockComponentProps,
) {
  const {text} = props
  const {upsellDecline} = usePurchase()

  return (
    <Container {...props}>
      <div
        style={{
          ...getFontStyles(props.font),
          fontSize: props.fontSize,
          color: props.color,
          lineHeight: `${props.lineHeight}%`,
          opacity: props.opacity,
          textAlign: props.alignment.horizontal,
          letterSpacing: props.letterSpacing,
          cursor: 'pointer',
        }}
        onClick={upsellDecline}
      >
        {text}
      </div>
    </Container>
  )
}
