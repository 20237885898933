import {useAttendeeVariables} from 'Event'
import AccessTeamPage from 'Event/business-mastery/AccessTeamPage'
import {
  IN_PERSON_QUERY,
  useAttendeesWithTeamId,
} from 'Event/business-mastery/TeamCompetition'
import {useEvent} from 'Event/EventProvider'
import {eventRoutes} from 'Event/Routes'
import {useToggleArray} from 'lib/toggle'
import {api, useQueryParams} from 'lib/url'
import React, {useCallback, useState} from 'react'
import {useHistory} from 'react-router-dom'

const DUPLICATE_ID_ERROR = 'Short code has already been taken.'

export default function CreateTeamPage() {
  const history = useHistory()
  const checkId = useCheckNewId()
  const [error, setError] = useState('')
  const v = useAttendeeVariables()
  const {[IN_PERSON_QUERY]: inPerson = false} = useQueryParams()
  const createBusinessMasteryMeeting = useCreateBusinessMasteryMeeting()

  const [processing, toggleProcessing] = useToggleArray()

  const goToTeam = async ({id}: any) => {
    if (processing) {
      return
    }

    setError('')
    toggleProcessing()

    const isNewId = await checkId(id)

    if (!isNewId) {
      setError(v('{{team_code_taken_error}}', DUPLICATE_ID_ERROR))
      toggleProcessing()
      return
    }

    if (inPerson) {
      history.push(
        `${eventRoutes.team_competition.root}?team_id=${id}&${IN_PERSON_QUERY}=true`,
      )
      return
    }

    const {meeting_id: meetingId} = await createBusinessMasteryMeeting(id)
    history.push(
      `${eventRoutes.team_competition.root}?team_id=${id}&meeting_id=${meetingId}`,
    )
  }

  return (
    <AccessTeamPage
      title={v('{{team_create_tite}}', 'Create Your Team')}
      error={error}
      description={
        <p style={{whiteSpace: 'pre-line'}}>
          {`1. Decide on a team name.

2. Create a short code. The short code is a one-word (no spaces) shortening of your team name. If you are Gladiator Gals, your short code might be ggals.

3. Enter your short code below and submit.

4. Once you have created your team, be sure to give your short code to your teammates so they can go to the Join a Team button and join`}
        </p>
      }
      processing={processing}
      onSubmit={goToTeam}
      submitLabel={v('{{team_create_button}}', 'Create')}
    />
  )
}

function useCheckNewId() {
  const findAttendeesWithTeamId = useAttendeesWithTeamId()

  return useCallback(
    (id: string) => {
      return findAttendeesWithTeamId(id).then((paginated) => {
        return paginated.data.length === 0
      })
    },
    [findAttendeesWithTeamId],
  )
}

function useCreateBusinessMasteryMeeting() {
  const {client} = useEvent()

  return (teamId: string) => {
    const url = api(`/business_mastery/team_competition/meeting`)
    return client.post<{meeting_id: string}>(url, {
      team_id: teamId,
    })
  }
}
