import {ThankYouPage} from 'Event/Marketplace/thank-you-page'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {Client} from 'lib/ui/api-client'
import {useMutation, useQueryClient} from 'react-query'

interface UseUpdateThankYouPageParams {
  client: Client
  thankYouPage: ThankYouPage
}

export function useUpdateThankYouPagePage(params: UseUpdateThankYouPageParams) {
  const {client, thankYouPage} = params

  const qc = useQueryClient()

  return useMutation({
    mutationFn: (data: Partial<ThankYouPage>) => {
      const url = marketplaceApi(`/thank_you_pages/${thankYouPage.id}`)
      return client.put<ThankYouPage>(url, data)
    },
    onSuccess: (updated) => {
      qc.setQueryData<ThankYouPage>(
        ['events', thankYouPage.event_id, 'thank_you_page'],
        () => updated,
      )
    },
  })
}
